import { useTranslation } from 'react-i18next'
import React from 'react'
import Grid from '@material-ui/core/Grid'

import IconServicesMeetingRoom from 'images/quotations/icon/services/icon-services-salle-de-reunion.jpg'
import IconServicesMail from 'images/quotations/icon/services/icon-services-courrier.jpg'
import IconServicesPrint from 'images/quotations/icon/services/icon-services-impressions.jpg'
import IconServicesCoffeeBreak from 'images/quotations/icon/services/icon-services-pause-cafe.jpg'
import IconServicesResto from 'images/quotations/icon/services/icon-services-restauration.jpg'
import IconServicesAssistante from 'images/quotations/icon/services/icon-services-assistante.jpg'
import IconServicesOtherService from 'images/quotations/icon/services/icon-services-autre-service.jpg'

export interface IProps {
  hidden: boolean
  index: number
}

const QuotationServicesComponent = ({ hidden, index }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div role="tabpanel" hidden={hidden} id={`quotations-tabpanel-${index}`}>
      <Grid alignItems="center" container spacing={4} className="gridQuotationsAddServices">
        <Grid item md={2} xs={12}>
          <img
            src={IconServicesMeetingRoom}
            className="IconAdditional"
            alt={t('common.additional_services')}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <p>
            <strong>{t('common.meeting_room_offices_day')}</strong>
          </p>
          <p>{t('common.reservation_meeting_room_offices_day')}</p>
        </Grid>
        <Grid item md={5} xs={12} className="bgGrey">
          <p>{t('common.reservation_meeting_room_offices_day_notice')}</p>
        </Grid>
        <Grid item md={12} className="border-bottom-bgGrey" />
        <Grid item md={2} xs={12}>
          <img
            src={IconServicesMail}
            className="IconAdditional"
            alt={t('common.additional_services')}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <p>
            <strong>{t('common.mail_forwarding')}</strong>
          </p>
          <p>{t('common.subscribe_mail_forwarding')}</p>
        </Grid>
        <Grid item md={5} xs={12} className="bgGrey">
          <p>
            {t('common.subscribe_mail_forwarding_daily')}{' '}
            <span className="textColorPrimary">
              <strong
                dangerouslySetInnerHTML={{
                  __html: t('common.subscribe_mail_forwarding_daily_price')
                }}
              />
            </span>
          </p>
          <p>
            {t('common.subscribe_mail_forwarding_weekly')}{' '}
            <span className="textColorPrimary">
              <strong
                dangerouslySetInnerHTML={{
                  __html: t('common.subscribe_mail_forwarding_weekly_price')
                }}
              />
            </span>
          </p>
          <p>
            {t('common.subscribe_mail_forwarding_monthly')}{' '}
            <span className="textColorPrimary">
              <strong
                dangerouslySetInnerHTML={{
                  __html: t('common.subscribe_mail_forwarding_monthly_price')
                }}
              />
            </span>
          </p>
        </Grid>
        <Grid item md={12} className="border-bottom-bgGrey" />
        <Grid item md={2} xs={12}>
          <img
            src={IconServicesPrint}
            className="IconAdditional"
            alt={t('common.additional_services')}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <p>
            <strong>{t('common.prints_photocopies')}</strong>
          </p>
          <p>{t('common.use_prints_photocopies')}</p>
        </Grid>
        <Grid item md={5} xs={12} className="bgGrey">
          <p>
            {t('common.black_white')}{' '}
            <span className="textColorPrimary">
              <strong dangerouslySetInnerHTML={{ __html: t('common.black_white_price') }} />
            </span>
          </p>
          <p>
            {t('common.color')}{' '}
            <span className="textColorPrimary">
              <strong dangerouslySetInnerHTML={{ __html: t('common.color_price') }} />
            </span>
          </p>
        </Grid>
        <Grid item md={12} className="border-bottom-bgGrey" />
        <Grid item md={2} xs={12}>
          <img
            src={IconServicesCoffeeBreak}
            className="IconAdditional"
            alt={t('common.additional_services')}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <p>
            <strong>{t('common.coffee_break')}</strong>
          </p>
          <p>{t('common.restoration_chill_out')}</p>
        </Grid>
        <Grid item md={5} xs={12} className="bgGrey">
          <p>
            {t('common.package_unlimited_coffee')}{' '}
            <span className="textColorPrimary">
              <strong
                dangerouslySetInnerHTML={{
                  __html: t('common.package_unlimited_coffee_price')
                }}
              />
            </span>
          </p>
          <p>
            {t('common.package_unlimited_tea')}{' '}
            <span className="textColorPrimary">
              <strong
                dangerouslySetInnerHTML={{
                  __html: t('common.package_unlimited_tea_price')
                }}
              />
            </span>
          </p>
          <p>
            {t('common.package_unlimited_coffee_tea')}{' '}
            <span className="textColorPrimary">
              <strong
                dangerouslySetInnerHTML={{
                  __html: t('common.package_unlimited_coffee_tea_price')
                }}
              />
            </span>
          </p>
        </Grid>
        <Grid item md={12} className="border-bottom-bgGrey" />
        <Grid item md={2} xs={12}>
          <img
            src={IconServicesResto}
            className="IconAdditional"
            alt={t('common.additional_services')}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <p>
            <strong>{t('common.restoration')}</strong>
          </p>
          <p>{t('common.eat_during_day')}</p>
        </Grid>
        <Grid item md={5} xs={12} className="bgGrey">
          <p>
            {t('common.coffee_break')}{' '}
            <span className="textColorPrimary">
              <strong dangerouslySetInnerHTML={{ __html: t('common.coffee_break_price') }} />
            </span>
          </p>
          <p>
            {t('common.sandwich_lunch')}{' '}
            <span className="textColorPrimary">
              <strong dangerouslySetInnerHTML={{ __html: t('common.sandwich_lunch_price') }} />
            </span>
          </p>
          <p>
            {t('common.lunch_meal_trays')}{' '}
            <span className="textColorPrimary">
              <strong dangerouslySetInnerHTML={{ __html: t('common.lunch_meal_trays_price') }} />
            </span>
          </p>
          <p>
            {t('common.breakfast')}{' '}
            <span className="textColorPrimary">
              <strong dangerouslySetInnerHTML={{ __html: t('common.breakfast_price') }} />
            </span>
          </p>
        </Grid>
        <Grid item md={12} className="border-bottom-bgGrey" />
        <Grid item md={2} xs={12}>
          <img
            src={IconServicesAssistante}
            className="IconAdditional"
            alt={t('common.additional_services')}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <p>
            <strong>{t('common.administrative_assistance')}</strong>
          </p>
          <p>{t('common.ask_administrative_assistance')}</p>
        </Grid>
        <Grid item md={5} xs={12} className="bgGrey">
          <p>
            <span className="textColorPrimary">
              <strong
                dangerouslySetInnerHTML={{
                  __html: t('common.time_need_administrative_assistance_15_price')
                }}
              />
            </span>{' '}
            {t('common.time_need_administrative_assistance_15')}
          </p>
          <p>
            <span className="textColorPrimary">
              <strong
                dangerouslySetInnerHTML={{
                  __html: t('common.time_need_administrative_assistance_60_price')
                }}
              />
            </span>{' '}
            {t('common.time_need_administrative_assistance_60')}
          </p>
        </Grid>
        <Grid item md={12} className="border-bottom-bgGrey" />
        <Grid item md={2} xs={12}>
          <img
            src={IconServicesOtherService}
            className="IconAdditional"
            alt={t('common.additional_services')}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <p>
            <strong>{t('common.need_another_service')}</strong>
          </p>
          <p>{t('common.list_services_concierge')}</p>
        </Grid>
        <Grid item md={5} xs={12} className="bgGrey">
          <p>{t('common.list_services_concierge_notice')}</p>
        </Grid>
      </Grid>
    </div>
  )
}

export default QuotationServicesComponent
