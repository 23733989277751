import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

import { ISolution } from 'app/models/quotations.models'

export interface IProps {
  solution: ISolution
}

const QuotationDesktopDetailsComponent = ({
  solution,
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={1} className="myGridQuotations">
      <Grid item md={12} xs={12}>
        {solution.offices.replaceAll('<br>', '')}
      </Grid>
      <Grid item md={12} xs={12} className="bgGrey">
        <Typography variant="caption" component="p" color="textSecondary">
          <small>{t('common.typology')}*</small>
        </Typography>
        {solution.typology.replace('<br>', ' ')}
      </Grid>
      <Grid item md={6} xs={6}>
        <Typography variant="caption" component="p" color="textSecondary">
          <small>
            {t('common.average_capacity')} ({t('common.quotationView.desktop.positions')})
          </small>
        </Typography>
        {solution.workforce}
      </Grid>
      <Grid item md={6} xs={6}>
        <Typography variant="caption" component="p" color="textSecondary">
          <small>{t('common.commitment_length')}</small>
        </Typography>
        {solution.commitment}
      </Grid>
    </Grid>
  )
}

export default QuotationDesktopDetailsComponent
