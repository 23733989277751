import {
  checkResponse,
  getRequestInit,
  getUrl,
  patchRequestInit,
  postRequestInit
} from 'app/api/api'
import { IContract, IContractDetail, IContractListItem, IPdf } from 'app/models/contracts.model'
import {IPaymentMethod} from "../models/payments.models";

export const ContractListQuery = async (enterprise: number): Promise<IContractListItem[]> => {
  return checkResponse(
    await fetch(getUrl(`v3/enterprises/${enterprise}/contracts`), getRequestInit(true))
  )
}

export const ContractQuery = async (enterprise: number, id: number): Promise<IContractDetail> => {
  return checkResponse(
    await fetch(getUrl(`v3/enterprises/${enterprise}/contracts/${id}`), getRequestInit(true))
  )
}

export const RequestSmsQuery = async (enterprise: number, id: number): Promise<boolean> => {
  return checkResponse(
    await fetch(getUrl(`enterprises/${enterprise}/contracts/${id}/sms`), postRequestInit(true, ''))
  )
}

export const SendSmsQuery = async (
  enterprise: number,
  id: number,
  code: string
): Promise<IContract> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/contracts/${id}`),
      patchRequestInit(true, JSON.stringify({ code: code }))
    )
  )
}

export const ContractPdfQuery = async (enterprise: number, id: number): Promise<IPdf> => {
  return checkResponse(
    await fetch(getUrl(`v3/enterprises/${enterprise}/contracts/${id}/pdf`), getRequestInit(true))
  )
}

export const PaymentMethodListConnectedQuery = async (enterprise: number, center: number): Promise<IPaymentMethod[]> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/centers/${center}/listpaymentmethods`),
      getRequestInit(true)
    )
  )
}