// @ts-ignore
import crypto from 'crypto-browserify';

export const encrypt = (text:string, key: string): string => {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
  let encrypted = cipher.update(text, 'utf8', 'base64');
  encrypted += cipher.final('base64');
  const ivHex = iv.toString('base64');
  return `${ivHex}:${encrypted}`;
}
