import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@material-ui/core'

import IconServicesInclusOtherService from 'images/quotations/icon/services/icon-services-autre-service.jpg'
import IconServicesInclusMeetingRoom from 'images/quotations/icon/services/icon-services-salle-de-reunion.jpg'
import IconServicesInclusSpaceShare from 'images/quotations/icon/services/icon-services-inclus-espace-partage.jpg'
import IconServicesInclusConnexion from 'images/quotations/icon/services/icon-services-inclus-connection.jpg'
import IconServicesInclusAssistance from 'images/quotations/icon/services/icon-services-assistante.jpg'
import IconServicesInclusDiscount from 'images/quotations/icon/services/icon-services-discount.jpg'
import IconServicesInclusTime from 'images/quotations/icon/services/icon-services-horaires.jpg'
import IconServicesInclusCoffee from 'images/quotations/icon/services/icon-services-pause-cafe.jpg'

const QuotationNomadeIncludedComponent = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={4}>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusSpaceShare}
          className="IconInclusives"
          alt={t('common.quotationView.included.coffee')}
        />
        <Box mt={1} fontSize={14} component="p" color="text.primary" className="text-center">
          {t('common.quotationView.included.coffee')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusConnexion}
          className="IconInclusives"
          alt={t('common.quotationView.included.wifi')}
        />
        <Box mt={1} fontSize={14} component="p" color="text.primary" className="text-center">
          {t('common.quotationView.included.wifi')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusCoffee}
          className="IconInclusives"
          alt={t('common.quotationView.included.drink')}
        />
        <Box mt={1} fontSize={14} component="p" color="text.primary" className="text-center">
          {t('common.quotationView.included.drink')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusTime}
          className="IconInclusives"
          alt={t('common.quotationView.included.schedule')}
        />
        <Box mt={1} fontSize={14} component="p" color="text.primary" className="text-center">
          {t('common.quotationView.included.schedule')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusSpaceShare}
          className="IconInclusives"
          alt={t('common.quotationView.included.nomadeAll')}
        />
        <Box mt={1} fontSize={14} component="p" color="text.primary" className="text-center">
          {t('common.quotationView.included.nomadeAll')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusOtherService}
          className="IconInclusives"
          alt={t('common.quotationView.included.conciergeAccess')}
        />
        <Box mt={1} fontSize={14} component="p" color="text.primary" className="text-center">
          {t('common.quotationView.included.conciergeAccess')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusDiscount}
          className="IconInclusives"
          alt={t('common.quotationView.included.discountRent')}
        />
        <Box mt={1} fontSize={14} component="p" color="text.primary" className="text-center">
          {t('common.quotationView.included.discountRent')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusMeetingRoom}
          className="IconInclusives"
          alt={t('common.quotationView.included.events')}
        />
        <Box mt={1} fontSize={14} component="p" color="text.primary" className="text-center">
          {t('common.quotationView.included.events')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusAssistance}
          className="IconInclusives"
          alt={t('common.quotationView.included.servicesMore')}
        />
        <Box mt={1} fontSize={14} component="p" color="text.primary" className="text-center">
          {t('common.quotationView.included.servicesMore')}
        </Box>
      </Grid>
    </Grid>
  )
}

export default QuotationNomadeIncludedComponent
