import React, { useMemo } from 'react'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import { CreditCard, EditRounded, HighlightOff } from '@material-ui/icons'
import CheckIcon from '@material-ui/icons/Check'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { IContractListItem } from 'app/models/contracts.model'

export interface IProps {
  contract: IContractListItem
}

const ContractListItemComponent = ({ contract }: IProps): JSX.Element => {
  const icon = useMemo(() => {
    switch (contract.status) {
      case 'Pending agreement':
        return { icon: <EditRounded />, color: 'secondary' }
      case 'Pending payment':
        return { icon: <CreditCard />, color: 'info' }
      case 'Terminated':
        return { icon: <HighlightOff />, color: 'gray' }
      default:
        return { icon: <CheckIcon />, color: 'primary' }
    }
  }, [contract.status])
  return (
    <ListItem className='pointer' onClick={() => window.location.href = `/enterprise/contracts/${contract.id}/details`}>
      <ListItemAvatar>
        <Avatar variant='rounded' className={`avatarColor ${icon.color}`}>
          {icon.icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={contract.reference}
        secondary={contract.center}
      />
      <ArrowForwardIosIcon color={'disabled'} fontSize={'small'} />
    </ListItem>
  )
}


export default ContractListItemComponent
