import React, { useMemo } from 'react'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/Check'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useTranslation } from 'react-i18next'

import { IDocument } from 'app/models/documents.model'

export interface IProps {
  document: IDocument
}

const DocumentListItemComponent = ({ document }: IProps): JSX.Element => {
  const { t } = useTranslation()

  const documentTypeLabel = useMemo(() => {
    switch (document.type) {
      case 'kbis':
        return t('common.documentListView.type.kbis')
      case 'bank account details':
        return t('common.documentListView.type.bankAccountDetails')
      case 'identity card':
        return t('common.documentListView.type.identityCard')
      default:
        return t('common.documentListView.type.other')
    }
  }, [document.type])

  return (
    <ListItem
      className="pointer"
      onClick={() => (window.location.href = `/enterprise/documents/${document.id}/details`)}>
      <ListItemAvatar>
        <Avatar variant="rounded" className="avatarColor primary">
          <CheckIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={document.name} secondary={documentTypeLabel} />
      <ArrowForwardIosIcon color={'disabled'} fontSize={'small'} />
    </ListItem>
  )
}

export default DocumentListItemComponent
