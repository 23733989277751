import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Select from '@material-ui/core/Select'
import DocumentHelper from 'app/helpers/document.helper'
import { IDocumentListFilter } from 'app/types/documents.type'

export interface IProps {
  filters?: IDocumentListFilter
  onChangeFilters: (filters: IDocumentListFilter) => void
}

const DocumentListFiltersComponent = ({ filters, onChangeFilters }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className='documents-list-filters pt-30'>
      <Select className='filter mr-xs mb-xs'
              native
              variant='standard'
              defaultValue={filters?.type}
              onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => onChangeFilters({
                ...filters,
                type: event.target.value === '' ? undefined : String(event.target.value)
              })}>
        <option value=''>{t('common.documentListView.filters.allTypes')}</option>
        {filters?.types?.map((type: string, index: number) =>
          <option key={index} value={type}>
            {DocumentHelper.getLabel(type)}
          </option>
        )}
      </Select>
    </div>
  )
}

export default DocumentListFiltersComponent
