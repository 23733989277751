import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { IAccountingDocument } from 'app/models/accountingDocuments.model'
import DateFormatter from 'app/formatters/date.formatter'
import AccountingDocumentHelper from 'app/helpers/accountingDocument.helper'
import { useOnView } from 'app/providers/onview.provider'
import { useOnLogin } from 'app/providers/onlogin.provider'
import {
  IAccountingDocumentListFilter,
  IStateFilter,
  ITypeFilter
} from 'app/types/accountingDocuments.type'

import AccountingDocumentListItemComponent from 'modules/accountingDocuments/components/accountingDocumentListItem.component'
import AccountingDocumentListFilterComponent from 'modules/accountingDocuments/components/accountingDocumentListFilter.component'
import 'modules/accountingDocuments/css/accountingDocument_list.scss'

const sortByDeadline = (a: IAccountingDocument, b: IAccountingDocument): number => {
  if (a.deadline === b.deadline) {
    return 0
  }
  return new Date(a.deadline) > new Date(b.deadline) ? -1 : 1
}

export interface IProps {
  individualOnly: boolean
}

const AccountingDocumentsList = ({ individualOnly }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { currentRole, currentEnterprise } = useOnLogin()
  const { listAccountingDocumentUseMutation, listAccountingDocumentIndividualUseMutation } =
    useOnView()

  const [accountingDocuments, setAccountingDocuments] = useState<
    Map<string, IAccountingDocument[]> | undefined
  >()
  const [currentAccountingDocuments, setCurrentAccountingDocuments] = useState<
    IAccountingDocument[] | undefined
  >()
  const [filteredAccountingDocuments, setFilteredAccountingDocuments] = useState<
    IAccountingDocument[]
  >([])
  const [filters, setFilters] = useState<IAccountingDocumentListFilter>({
    type: '',
    types: [],
    state: '',
    states: [],
    deadline: '',
    center: '',
    centers: [],
    individual: individualOnly
  })

  const isLoadedDocuments = useMemo(() => {
    if (individualOnly) {
      return !listAccountingDocumentIndividualUseMutation?.isLoading
    }
    return (
      !listAccountingDocumentUseMutation?.isLoading &&
      !listAccountingDocumentIndividualUseMutation?.isLoading
    )
  }, [
    individualOnly,
    listAccountingDocumentUseMutation,
    listAccountingDocumentIndividualUseMutation
  ])

  useEffect(() => {
    loadAccountingDocuments().then()
  }, [currentEnterprise])

  useEffect(() => {
    setList()
  }, [filters.individual, individualOnly, accountingDocuments])

  useEffect(() => {
    let _filterTypes: ITypeFilter[] = []
    let _filterStates: IStateFilter[] = []
    let _filterCenters: string[] = []
    let _filterCenter = ''
    let _filterDeadlines: string[] = []

    currentAccountingDocuments?.map((accountingDocument: IAccountingDocument) => {
      if (
        !_filterTypes.some((typeFilter: ITypeFilter) => typeFilter.key === accountingDocument.type)
      ) {
        _filterTypes.push({
          key: accountingDocument.type,
          value: AccountingDocumentHelper.getLabelType(accountingDocument.type)
        })
      }
      if (
        !_filterStates.some(
          (stateFilter: IStateFilter) => stateFilter.key === accountingDocument.status
        )
      ) {
        _filterStates.push({
          key: accountingDocument.status,
          value: AccountingDocumentHelper.getLabel(accountingDocument.status)
        })
      }

      if (!_filterDeadlines.includes(DateFormatter.formatFrPeriod(accountingDocument.deadline))) {
        _filterDeadlines.push(DateFormatter.formatFrPeriod(accountingDocument.deadline))
      }

      if (!_filterCenters.includes(accountingDocument.center)) {
        _filterCenters.push(accountingDocument.center)
      }
    })

    _filterTypes.sort()
    _filterTypes.reverse()
    _filterStates.sort()
    _filterStates.reverse()
    _filterCenters.sort()

    if (_filterCenters.length > 0) {
      _filterCenter = _filterCenters[0]
    }

    setFilters({
      ...filters,
      types: _filterTypes,
      states: _filterStates,
      centers: _filterCenters,
      center: _filterCenter,
    })
  }, [currentAccountingDocuments])

  useEffect(() => {
    refreshList()
  }, [currentAccountingDocuments, filters.type, filters.state, filters.deadline, filters.center])

  const loadAccountingDocuments = useCallback(async (): Promise<void> => {
    let _list: Map<string, IAccountingDocument[]> | undefined = new Map(accountingDocuments!)
    if (currentRole === 'ROLE_ENTERPRISE_ADMIN') {
      const _documents = await listAccountingDocumentUseMutation?.mutateAsync(currentEnterprise)
      _list.set('enterprise', undefined !== _documents ? _documents : [])
      setAccountingDocuments(_list)
    }
    const _individualDocuments = await listAccountingDocumentIndividualUseMutation?.mutateAsync()

    _list.set('individual', undefined !== _individualDocuments ? _individualDocuments : [])
    setAccountingDocuments(_list)
  }, [currentEnterprise])

  const setList = useCallback((): void => {
    let _documents: IAccountingDocument[] = []

    if (
      individualOnly &&
      accountingDocuments !== undefined &&
      accountingDocuments.has('individual')
    ) {
      _documents = accountingDocuments.get('individual')!
    }

    if (!individualOnly && accountingDocuments !== undefined) {
      if (filters.individual && accountingDocuments.has('individual')) {
        _documents = accountingDocuments.get('individual')!
      }

      if (!filters.individual && accountingDocuments.has('enterprise')) {
        _documents = accountingDocuments.get('enterprise')!
      }
    }
    setCurrentAccountingDocuments(_documents)
  }, [accountingDocuments, individualOnly, filters.individual])


  const refreshList = useCallback((): void => {
    let list = currentAccountingDocuments?.filter(
      (accountingDocument: IAccountingDocument) =>
        (accountingDocument.type.valueOf() === filters.type || filters.type === '') &&
        (accountingDocument.status.valueOf() === filters.state || filters.state === '') &&
        (accountingDocument.center.valueOf() === filters.center || filters.center === '') &&
        (DateFormatter.formatUSYearMonth(new Date(accountingDocument.deadline)) ===
          filters.deadline ||
          filters.deadline === '')
    )

    if (list !== undefined) {
      list.sort(sortByDeadline)
    } else {
      list = []
    }

    setFilteredAccountingDocuments(list)
  }, [currentAccountingDocuments, filters.type, filters.state, filters.deadline, filters.center])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <Box p={2}>
            <div className="myCard">
              <div className="card-header list-header">
                <Link to="/home" color="primary" className="icon-button">
                  <ArrowBackIcon fontSize="small" />
                </Link>
                <Typography variant="h2" color="primary">
                  {t('common.accountingDocumentListView.title')}
                </Typography>
              </div>
              <AccountingDocumentListFilterComponent
                individualOnly={individualOnly}
                filters={filters!}
                setFilters={(_filters: IAccountingDocumentListFilter) => setFilters(_filters)}
              />
              <div className="card-content">
                <div>
                  {!isLoadedDocuments && (
                    <div className="myCardLoader">
                      <CircularProgress color="secondary" />
                    </div>
                  )}

                  <div className="accounting_content">
                    {isLoadedDocuments &&
                      filteredAccountingDocuments?.map(
                        (accountingDocument: IAccountingDocument, index: number) => (
                          <AccountingDocumentListItemComponent
                            accountingDocument={accountingDocument}
                            showReference={true}
                            key={index}
                            index={index}
                          />
                        )
                      )}
                  </div>
                  {filteredAccountingDocuments.length === 0 && (
                    <Typography variant="body2" color="textSecondary">
                      {t('common.accountingDocumentListView.empty')}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AccountingDocumentsList
