import React from 'react'

import { ISolution } from 'app/models/quotations.models'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

export interface IProps {
  solution: ISolution
}

const QuotationDomiciliationDetailsComponent = ({ solution }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={1} className="myGridQuotations">
      <Grid item md={6} xs={6}>
        <Typography variant="caption" component="p" color="textSecondary">
          <small>{t('common.commitment_length')}</small>
        </Typography>
        {solution.commitment}
      </Grid>
    </Grid>
  )
}

export default QuotationDomiciliationDetailsComponent
