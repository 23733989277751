import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { FormControl, FormControlLabel, Select, Switch, TextField } from '@material-ui/core'

import CenterFilter from 'app/views/CenterFilter'
import {
  IAccountingDocumentListFilter,
  IStateFilter,
  ITypeFilter
} from 'app/types/accountingDocuments.type'

export interface IProps {
  individualOnly: boolean
  filters: IAccountingDocumentListFilter
  setFilters: (filters: IAccountingDocumentListFilter) => void
}

const AccountingDocumentListFilterComponent = ({
  individualOnly,
  filters,
  setFilters
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="documents-list-filters pt-30">
      <Grid container spacing={2} className="formListBooking">
        <Grid item xs={12} md={3}>
          <FormControl variant="standard" className="labelFormControl" fullWidth>
            <Select
              className="filter mr-xs mb-xs"
              native
              variant="standard"
              defaultValue={filters.type}
              onChange={(e) => setFilters({ ...filters, type: String(e.target.value) })}>
              <option value="">{t('common.accountingDocumentListView.filters.types')}</option>
              {filters.types?.map((typeFilter: ITypeFilter, index: number) => {
                return (
                  <option key={index} value={typeFilter.key}>
                    {typeFilter.value}
                  </option>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl variant="standard" className="labelFormControl" fullWidth>
            <Select
              className="filter mr-xs mb-xs"
              native
              variant="standard"
              defaultValue={filters.state}
              onChange={(e) => setFilters({ ...filters, state: String(e.target.value) })}>
              <option value="">{t('common.accountingDocumentListView.filters.states')}</option>
              {filters.states?.map((stateFilter: IStateFilter, index: number) => {
                return (
                  <option key={index} value={stateFilter.key}>
                    {stateFilter.value}
                  </option>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl variant="standard" className="labelFormControl" fullWidth>
            <CenterFilter
              allCentersOption={false}
              centers={filters.centers}
              defaultCenter={filters.center}
              onCenterChange={(center: string) => setFilters({ ...filters, center: center })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl variant="standard" className="labelFormControl" fullWidth>
            <TextField
              className="filter mr-xs mb-xs"
              id="datetime-local"
              label={t('common.global.deadline')}
              type="month"
              defaultValue={filters.deadline}
              onChange={(e) => setFilters({ ...filters, deadline: String(e.target.value) })}
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
        </Grid>
        {!individualOnly && (
          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={
                <Switch
                  className="filter ml-xs"
                  checked={!filters.individual}
                  onChange={() => setFilters({ ...filters, individual: !filters.individual })}
                  color="primary"
                />
              }
              label={
                filters.individual
                  ? t('common.accountingDocumentListView.filters.individual')
                  : t('common.accountingDocumentListView.filters.enterprise')
              }
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default AccountingDocumentListFilterComponent
