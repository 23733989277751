import {StatusEnum} from '../enums/booking.enum'
import i18next from 'i18next'

export default class BookingHelper {
  static getStatusLabel(status: StatusEnum): string {
    switch (status) {
      case StatusEnum.CREATED:
        return i18next.t('common.customer_reservation.status.created')
      case StatusEnum.CONFIRMED:
        return i18next.t('common.customer_reservation.status.confirmed')
      case StatusEnum.CANCELLED:
        return i18next.t('common.customer_reservation.status.cancelled')
    }
  }
}