export default class DateFormatter {
  static formatFrDate(date: Date): string {
    const dateFormatted: Date = new Date(date)
    return dateFormatted.toLocaleDateString('fr')
  }

  static formatFrHour(date: Date): string {
    return date.toLocaleTimeString('fr-FR')
  }

  static formatFrDateFromString(date: string): string {
    const dateFormatted: Date = new Date(date)
    return dateFormatted.toLocaleDateString('fr')
  }

  static formatFrRoundHourFromString(date: string): string {
    let dateFormatted = new Date(date)
    dateFormatted = this.formatRoundDate(dateFormatted)
    return dateFormatted.getHours() + 'h'
  }

  static formatFrPeriod(period: string): string {
    const dateFormatted: Date = new Date(period.length > 7 ? period : `${period}-01`)
    return `${dateFormatted.toLocaleString('default', {
      month: 'long',
    })} ${dateFormatted.getFullYear()}`
  }

  // yyyy-MM-dd
  static formatUSDate(date: Date): string {
    return date.getFullYear() + '-' + String(Number(date.getMonth() + 1)).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
  }

  // yyyy-MM
  static formatUSYearMonth(date: Date): string {
    return date.getFullYear() + '-' + String(Number(date.getMonth() + 1)).padStart(2, '0')
  }

  static formatUSDateTimeJson(date: Date): string {
    return this.formatUSDate(date) + 'T' + this.formatFrHour(date)
  }

  static formatRoundDate(date: Date): Date {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60))
    date.setMinutes(0, 0, 0)

    return date
  }

  static formatYearFromString(date: string): string {
    let dateYear = new Date(date)
    return String(dateYear.getFullYear())
  }
}
