import React, { ChangeEvent, Fragment, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import { Link, NavLink } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import DescriptionIcon from '@material-ui/icons/Description'
import BusinessIcon from '@material-ui/icons/Business'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import { Divider, FormControl, Menu, MenuItem, Select } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import EventIcon from '@material-ui/icons/Event'
import { useApp } from 'app/providers/app.provider'
import { IRole } from 'app/models/role.model'
import { useOnLogin } from 'app/providers/onlogin.provider'
import 'app/css/navbar.scss'
import {useAppTheme} from "app/providers/apptheme.provider";
import {Brightness2, WbSunny} from "@material-ui/icons";
import {IMAGES} from "../constants/images";

const Navbar = (): JSX.Element => {
  const { t } = useTranslation()
  const { setOnLogout, isConnected } = useApp()
  const {isDarkMode, switchTheme} = useAppTheme()
  const { currentEnterprise, currentRole, roles, setCurrentEnterprise } = useOnLogin()
  const { account } = useOnLogin()
  const [anchor, setAnchor] = useState<HTMLElement | undefined>(undefined)

  const setProfileSection = (): JSX.Element => {
    return (
      <Fragment>
        <div className='contentRight' style={{ display: 'flex' }}>
          <IconButton style={{ marginRight: 14 }} onClick={switchTheme}>
            {isDarkMode ? <Brightness2 fontSize='small' /> : <WbSunny fontSize='small' />}
          </IconButton>
          {roles.length > 1 && (
            <FormControl variant='outlined' className='selectMulticenter'>
              <Select
                native
                value={currentEnterprise}
                onChange={(event: ChangeEvent<{ name?: string; value: unknown }>) =>
                  setCurrentEnterprise(Number(event.target.value))
                }
              >
                {roles.map((r: IRole, index: number) => (
                  <option key={index} value={r.entreprise_id}>
                    {r.enterprise_name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <Typography variant='caption'>
            <IconButton
              color='inherit'
              aria-controls={isDarkMode ? 'main-menu dark' : 'main-menu'}
              aria-haspopup='true'
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id={isDarkMode ? 'main-menu-dark' : 'main-menu'}
              anchorEl={anchor}
              keepMounted
              open={Boolean(anchor)}
              onClose={() => setAnchor(undefined)}
            >
              <MenuItem onClick={() => setAnchor(undefined)} className='main-menu-item'>
                <NavLink
                  to='/home'
                  className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}
                >
                  <HomeIcon fontSize='small' />
                  {t('common.home')}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={() => setAnchor(undefined)} className='main-menu-item'>
                <NavLink
                  to='/profile'
                  className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}
                >
                  <PersonIcon fontSize='small' />
                  {t('common.my_profile')}
                </NavLink>
              </MenuItem>
              {currentRole !== 'ROLE_ENTERPRISE_ADMIN' && (
                <MenuItem onClick={() => setAnchor(undefined)} className='main-menu-item'>
                  <NavLink
                    to='/accountingdocuments/list'
                    className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}
                  >
                    <DescriptionIcon fontSize='small' />
                    {t('common.accounting_documents.personal')}
                  </NavLink>
                </MenuItem>
              )}
              <div>
                {currentRole === 'ROLE_ENTERPRISE_ADMIN' && (
                  <Fragment>
                    <Divider />
                    <div className='title-nav-subsection'>
                      <BusinessIcon fontSize='small' /> {t('common.my_enterprise')}
                    </div>
                  </Fragment>
                )}
                {currentRole === 'ROLE_ENTERPRISE_ADMIN' && (
                  <Fragment>
                    <MenuItem onClick={() => setAnchor(undefined)} className='nav-subsection'>
                      <NavLink
                        to='/enterprise/quotations/list'
                        className={({ isActive }) => (isActive ? 'nav-subitem active' : 'nav-subitem')}
                      >
                        {t('common.my_quotations')}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={() => setAnchor(undefined)} className='nav-subsection'>
                      <NavLink
                        to='/enterprise/contracts/list'
                        className={({ isActive }) => (isActive ? 'nav-subitem active' : 'nav-subitem')}
                      >
                        {t('common.my_contracts')}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={() => setAnchor(undefined)} className='nav-subsection'>
                      <NavLink
                        to='/enterprise/accountingdocuments/list'
                        className={({ isActive }) => (isActive ? 'nav-subitem active' : 'nav-subitem')}
                      >
                        {t('common.accounting_documents.label')}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={() => setAnchor(undefined)} className='nav-subsection'>
                      <NavLink
                        to='/enterprise/documents/list'
                        className={({ isActive }) => (isActive ? 'nav-subitem active' : 'nav-subitem')}
                      >
                        {t('common.navbar.documents')}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={() => setAnchor(undefined)} className='nav-subsection'>
                      <NavLink
                        to='/enterprise/members/list'
                        className={({ isActive }) => (isActive ? 'nav-subitem active' : 'nav-subitem')}
                      >
                        {t('common.collaborator')}
                      </NavLink>
                    </MenuItem>
                  </Fragment>
                )}
                <Fragment>
                  <MenuItem onClick={() => setAnchor(undefined)} className='main-menu-item'>
                    <NavLink
                      to='/booking_list'
                      className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}
                    >
                      <EventIcon fontSize='small' />
                      {t('common.my_booking')}
                    </NavLink>
                  </MenuItem>
                </Fragment>
              </div>
              <Divider />
              <MenuItem onClick={() => setAnchor(undefined)} className='main-menu-item'>
                <NavLink
                  to='/helpdesk'
                  className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}
                >
                  <LiveHelpIcon fontSize='small' />
                  {t('common.helpdesk')}
                </NavLink>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => setOnLogout(true)} className='main-menu-item'>
                <div className="nav-item">
                  <ExitToAppIcon fontSize='small' />
                  {t('common.logout')}
                </div>
              </MenuItem>
            </Menu>
          </Typography>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {isConnected && (
        <Box>
          <AppBar position='static' color='default' elevation={0}>
            <Toolbar>
              <Fragment>
                <Link to='/home'>
                  <img src={isDarkMode ? IMAGES.LOGO_WHITE : IMAGES.LOGO} className='logo' alt='' />
                </Link>
                {isConnected && setProfileSection()}
              </Fragment>
            </Toolbar>
          </AppBar>
          <div className='myNavBar'>
            {account !== undefined && (
              <h3>
                {t('common.welcome')} {account?.firstname}
              </h3>
            )}
          </div>
        </Box>
      )}
    </Fragment>
  )
}

export default Navbar
