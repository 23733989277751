import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItem, ListItemText } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { CreditCard, EditRounded, HighlightOff } from '@material-ui/icons'
import CheckIcon from '@material-ui/icons/Check'

import { IContractDetail } from 'app/models/contracts.model'

export interface IProps {
  contract?: IContractDetail
}

const ContractDetailsHeaderComponent = ({ contract }: IProps): JSX.Element => {
  const { t } = useTranslation()

  const icon = useMemo(() => {
    switch (contract?.status) {
      case 'Pending agreement':
        return {
          icon: <EditRounded />,
          color: 'secondary',
          url: `/enterprise/contracts/${contract?.id}/pending`
        }
      case 'Pending payment':
        return { icon: <CreditCard />, color: 'info', url: '/enterprise/accountingdocuments/list' }
      case 'Terminated':
        return { icon: <HighlightOff />, color: 'default', url: undefined }
      default:
        return { icon: <CheckIcon />, color: 'primary', url: undefined }
    }
  }, [contract?.status])

  return (
    <ListItem
      className={`${icon.url !== undefined ? 'pointer' : ''} ${icon.color}`}
      onClick={() => (icon.url !== undefined ? (window.location.href = icon.url) : undefined)}>
      <ListItemText
        primary={contract?.center}
        secondary={
          <>
            {t(`common.contractDetails.paymentStatus.${contract?.status}`)}
            {icon.url !== undefined && (
              <ArrowForwardIosIcon color={'disabled'} fontSize={'small'} className="iconLink" />
            )}
          </>
        }
      />
    </ListItem>
  )
}

export default ContractDetailsHeaderComponent
