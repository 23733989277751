import React, { Fragment } from 'react'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import 'modules/payment/css/payment.scss'
import { useOnLogin } from 'app/providers/onlogin.provider'

const PaymentResultMessageSuccess = (): JSX.Element => {
  const { t } = useTranslation()
  const { currentRole } = useOnLogin()

  return (
    <>
      <Box className="text-center border-y-1" my={4} py={4}>
        {t('common.message_result_success_list_document')}
        {(currentRole === 'ROLE_ENTERPRISE_ADMIN' && (
          <Fragment>
            <a className="textColorPrimary" href="/enterprise/accountingdocuments/list">
              {t('common.click_here')}
            </a>
            .
          </Fragment>
        )) || (
          <Fragment>
            <a className="textColorPrimary" href="/accountingdocuments/list">
              {t('common.click_here')}
            </a>
            .
          </Fragment>
        )}
      </Box>
      <a href="/home" className="button">
        {t('common.back_to_home')}
      </a>
      <br />
      <br />
    </>
  )
}

export default PaymentResultMessageSuccess
