import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ListItem, ListItemAvatar, ListItemText} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Typography from '@material-ui/core/Typography'
import {RadioButtonUncheckedOutlined, Timer} from '@material-ui/icons'

import DateFormatter from 'app/formatters/date.formatter'
import CurrencyFormatter from 'app/formatters/currency.formatter'
import {IAccountingDocument} from 'app/models/accountingDocuments.model'

export interface IProps {
  index: number
  accountingDocument: IAccountingDocument
  showReference: boolean
}

const AccountingDocumentListItemComponent = ({
                                               index,
                                               accountingDocument,
                                               showReference
                                             }: IProps): JSX.Element => {
  const {t} = useTranslation()
  const [url, setUrl] = useState<string>('')

  const handleClick = useCallback(() => {
    if (url !== '') {
      window.location.href = url
    }
  }, [url])

  useEffect(() => {
    switch (accountingDocument.type) {
      case 'invoice':
        if (accountingDocument.paymentLink !== undefined) {
          setUrl(accountingDocument.paymentLink)
        }
        break
      case 'credit':
        setUrl(`/credit/` + accountingDocument.id)
        break
    }
  }, [accountingDocument])

  const icon = useMemo(() => {
    switch (accountingDocument.status) {
      case 'spent':
      case 'paid':
        return {icon: <CheckCircleIcon/>, color: 'paid'}
      case 'late':
        return {icon: <Timer/>, color: 'late'}
      default:
        return {icon: <RadioButtonUncheckedOutlined/>, color: 'default'}
    }
  }, [accountingDocument.status])

  return (
    <div className="myCardList accounting" key={index}>
      {showReference && (
        <div
          className={`${url ? 'pointer' : ''} myCardList-header ${icon.color}`}
          onClick={handleClick}>
          <ListItem>
            <ListItemAvatar>{icon.icon}</ListItemAvatar>
            <ListItemText
              primary={accountingDocument.reference}
              secondary={accountingDocument.center}
            />
            {url && (<ArrowForwardIosIcon color={'disabled'} fontSize={'small'}/>)}
          </ListItem>
        </div>
      )}
      <div className="myCardList-content">
        <div className="item">
          <Typography variant="overline" className="textColorMuted">
            {t('common.global.deadline')}
          </Typography>
          <Typography paragraph={true} className="mb-0">
            {DateFormatter.formatFrDateFromString(accountingDocument.deadline)}
          </Typography>
        </div>
        <div className="item text-right">
          <Typography variant="overline" className="textColorMuted">
            {t('common.global.amount')}
          </Typography>
          <Typography paragraph={true} className="mb-0">
            {CurrencyFormatter.formatFr(accountingDocument.dueAmount)}
          </Typography>
        </div>
        <div className="fix-float"/>
      </div>
    </div>
  )
}

export default AccountingDocumentListItemComponent
