import React, {ChangeEvent, useCallback, useEffect, useState} from 'react'
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Logo from "../../../images/common/logo.png";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useApp} from "../../../app/providers/app.provider";
import {Check} from "@material-ui/icons";
import {useOnView} from "../../../app/providers/onview.provider";
import Logo_white from '../../../images/common/logo_white.png'
import { IMAGES } from '../../../app/constants/images'

const regexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,}$/
const PasswordView = (props: { isDarkMode: boolean }): JSX.Element => {
  const {t} = useTranslation()
  const {changePasswordUseMutation} = useApp()
  const {tokenCheckUseMutation} = useOnView()
  const [password, setPassword] = useState<string>('')
  const [jwt, setJwt] = useState<string | null>(null)
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [passwordColor, setPasswordColor] = useState<'primary' | 'error' | 'disabled'>('disabled')
  const [passwordConfirmationColor, setPasswordConfirmationColor] = useState<'primary' | 'error' | 'disabled'>('disabled')
  const [message, setMessage] = useState<string | undefined>(undefined)

  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    let token = params.get('token')
    if (token !== null) {
      setJwt(token)
    } else {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    if (jwt !== null) {
      tokenCheckUseMutation?.mutateAsync(jwt)
        .then(() => {
          setMessage('')
        })
        .catch((err) => {
          if (err === 401) {
            setMessage(t('common.passwordView.password.expired'))
          } else {
            setMessage(t('common.passwordView.password.error'))
          }
        })
    }
  }, [jwt])

  useEffect((): void => {
    setPasswordColor(password === '' ? 'disabled' : (regexPattern.test(password) ? 'primary' : 'error'))
    setPasswordConfirmationColor(passwordConfirmation === '' ? 'disabled' : ((regexPattern.test(passwordConfirmation) && password === passwordConfirmation) ? 'primary' : 'error'))
  }, [password, passwordConfirmation]);

  // Test that the password matches with the password confirmation and the following pattern :
  // - At least one uppercase letter
  // - At least one lowercase letter
  // - At least one digit
  // - At least one special character
  // - Minimum 12 characters
  const passwordValidate = useCallback((): boolean => {
    return regexPattern.test(password) &&
      password === passwordConfirmation &&
      password !== '' &&
      passwordConfirmation !== ''
  }, [password, passwordConfirmation])

  return (
    <>
      <CssBaseline/>
      <Container maxWidth='xs' className={"bkContainerLogin"}>
        <img src={props.isDarkMode ? IMAGES.LOGO_LOGIN_WHITE : IMAGES.LOGO_LOGIN} className="logo" alt="" />
        <form className='login-form'>
          <Typography component="h1" variant="h5" style={{ fontWeight: '600' }} align="center">{t('common.my_client_space')}</Typography>
          {message !== undefined && message === '' && (
            <>
              <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                <TextField
                  variant='standard'
                  margin='normal'
                  type='password'
                  required
                  fullWidth
                  id='password'
                  label={t('common.passwordView.password.label')}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                />
                <Check className="ml-xs" color={passwordColor}/>
              </Box>
              <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                <TextField
                  variant='standard'
                  margin='normal'
                  type='password'
                  required
                  fullWidth
                  id='password-confirmation'
                  label={t('common.passwordView.password.confirmation')}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setPasswordConfirmation(event.target.value)}
                />
                <Check className="ml-xs" color={passwordConfirmationColor}/>
              </Box>
              <Typography
                component='p'
                variant='subtitle2'
                align='center'
                className='mt-20'
              >
                {changePasswordUseMutation?.isSuccess && t('common.passwordView.password.success')}
                {changePasswordUseMutation?.isError &&
                  t('common.passwordView.password.error')
                }
                {
                  !changePasswordUseMutation?.isSuccess &&
                  !changePasswordUseMutation?.isError &&
                  !changePasswordUseMutation?.isLoading &&
                  t('common.passwordView.password.description')
                }
              </Typography>
              <Box mt={4} mb={2}>
                {!changePasswordUseMutation?.isSuccess &&
                    <Button
                        fullWidth
                        className='btnLogin'
                        disableElevation
                        variant='contained'
                        type='button'
                        color='primary'
                        disabled={!passwordValidate()}
                        onClick={() => changePasswordUseMutation?.mutateAsync({jwt: jwt!, password: password})}>
                      {changePasswordUseMutation?.isLoading && <CircularProgress color='primary' size={25}/>}
                      {!changePasswordUseMutation?.isLoading && t('common.passwordView.password.validate')}
                    </Button>
                }
              </Box>
            </>
          )}
          {message !== undefined && message !== '' && (
            <>
              <Box className='text-center' mt={4} mb={2}>
                <div>{message}</div>
              </Box>
              <Box className='text-center' mb={1}>
                <Link to="/forgotten_password" className="textColorPrimary">
                  {t('common.passwordView.password.renew')}
                </Link>
              </Box>
            </>
          )}
          {message === undefined && (
            <div className="myCardLoader">
              <CircularProgress color="secondary"/>
            </div>
          )}
          {message !== undefined && (
            <Box className='text-center'>
              <Link to='/login' className='textColorPrimary'>{t('common.credentials.back')}</Link>
            </Box>
          )}
        </form>
      </Container>
    </>
  )
}

export default PasswordView
