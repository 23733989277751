import React, { useCallback, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from 'react-router-dom'
import { List } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { IMember } from 'app/models/members.model'
import { useOnView } from 'app/providers/onview.provider'
import { IMemberListFilter } from 'app/types/members.type'

import MemberListFiltersComponent from 'modules/members/components/memberListFilters.component'
import MemberListItemComponent from 'modules/members/components/memberListItem.component'
import { useOnLogin } from 'app/providers/onlogin.provider'

const MembersList = (): JSX.Element => {
  const { t } = useTranslation()
  const { currentEnterprise } = useOnLogin()
  const { listMemberUseMutation } = useOnView()

  const [members, setMembers] = useState<IMember[] | undefined>()
  const [filteredMembers, setFilteredMembers] = useState<IMember[] | undefined>()
  const [filters, setFilters] = useState<IMemberListFilter>()

  useEffect(() => {
    if (currentEnterprise !== undefined) {
      getMembers().then()
    }
  }, [currentEnterprise])

  useEffect(() => {
    initFilters()
    setFilteredMembers(members)
  }, [members])

  useEffect(() => {
    refreshList()
  }, [filters, members])

  const getMembers = useCallback(async () => {
    const _members = await listMemberUseMutation?.mutateAsync(currentEnterprise)
    setMembers(_members)
  }, [currentEnterprise])

  const initFilters = useCallback((): void => {
    let _filterJobs: string[] = []
    members?.map((member: IMember) => {
      if (member.job !== undefined && !_filterJobs.includes(member.job)) {
        _filterJobs.push(member.job)
      }
    })
    _filterJobs.sort()
    setFilters({ ...filters, jobs: _filterJobs })
  }, [members])

  const refreshList = useCallback((): void => {
    let list = members?.filter(
      (member: IMember) => member.job === filters?.job || filters?.job === undefined
    )
    setFilteredMembers(list)
  }, [filters, members])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <Box p={2}>
            <div className="myCard">
              <div className="card-header list-header">
                <Link to="/home" color="primary" className="icon-button">
                  <ArrowBackIcon fontSize="small" />
                </Link>
                <Typography variant="h2" color="primary">
                  {t('common.collaborator')}
                </Typography>
              </div>
              <MemberListFiltersComponent
                onChangeFilters={(_filters: IMemberListFilter) => setFilters(_filters)}
                filters={filters}
              />
              <div className="card-content">
                {filteredMembers !== undefined &&
                  !listMemberUseMutation?.isLoading &&
                  filteredMembers.length > 0 && (
                    <List className="myListItem">
                      {filteredMembers.map((member: IMember, index: number) => (
                        <MemberListItemComponent key={index} member={member} />
                      ))}
                    </List>
                  )}
                {(filteredMembers === undefined || listMemberUseMutation?.isLoading) && (
                  <div className="myCardLoader">
                    <CircularProgress color="secondary" />
                  </div>
                )}
                {!listMemberUseMutation?.isLoading &&
                  (filteredMembers === undefined || filteredMembers.length === 0) && (
                    <Typography variant="body2" color="textSecondary">
                      {t('common.members.list.noMembers')}
                    </Typography>
                  )}
              </div>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default MembersList
