import { useTranslation } from 'react-i18next'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Box, Divider } from '@material-ui/core'

import ImageNotreAdn from 'images/quotations/image-quotation.jpg'
import IconServicesConnexion from 'images/quotations/icon/services/icon-services-inclus-securite.jpg'
import IconServicesFurnitureIncluded from 'images/quotations/icon/services/icon-services-inclus-mobiliers.jpg'
import IconServicesPremisesSelectionned from 'images/quotations/icon/services/icon-services-emplacements-selectionnes.jpg'
import IconServicesTeam from 'images/quotations/icon/services/icon-services-equipe.jpg'
import IconServicesDigital from 'images/quotations/icon/services/icon-services-services-numeriques.jpg'
import IconServicesOther from 'images/quotations/icon/services/icon-services-autre-service.jpg'
import IconServicesExperience from 'images/quotations/icon/services/icon-services-experience-numerique.png'
import IconServicesEvents from 'images/quotations/icon/services/icon-services-evenements.jpg'
import IconServicesEcoresponsable from 'images/quotations/icon/services/icon-services-certification-environementale.jpg'

export interface IProps {
  hidden: boolean
  index: number
}

const QuotationGoalComponent = ({ hidden, index }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div role="tabpanel" hidden={hidden} id={`quotations-tabpanel-${index}`}>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <img src={ImageNotreAdn} alt={t('common.goals.adn')} className="imgGoal" />
          <Box color={'primary'} className="TitleContentTab" mb={2}>
            {t('common.goals.title')}
          </Box>
          <span className="SubTitleContentTab">{t('common.goals.adn')}</span>
          <div dangerouslySetInnerHTML={{ __html: t('common.goals.introText') }} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <p className="mt-0">
            <span className={'SubTitleContentTab'}>{t('common.goals.avantages')}</span>
          </p>
          <Grid alignItems="center" container spacing={2} className="gridQuotationsGoal">
            <Grid item sm={1} xs={12}>
              <img
                src={IconServicesFurnitureIncluded}
                className="IconGaol"
                alt={t('common.goals.avantages')}
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: t('common.goals.furniture_included') }} />
            </Grid>
            <Grid item sm={1} xs={12}>
              <img
                src={IconServicesConnexion}
                className="IconGaol"
                alt={t('common.goals.avantages')}
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: t('common.goals.Connexion') }} />
            </Grid>
          </Grid>
          <Grid
            alignItems="center"
            container
            spacing={2}
            className="gridQuotationsGoal mb-20 mt-20">
            <Grid item sm={12} xs={12}>
              <Divider className={'DividerYellow'} />
            </Grid>
          </Grid>
          <Grid alignItems="center" container spacing={2} className="gridQuotationsGoal">
            <Grid item sm={1} xs={12}>
              <img
                src={IconServicesPremisesSelectionned}
                className="IconGaol"
                alt={t('common.goals.avantages')}
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: t('common.goals.Premises_Selectionned') }} />
            </Grid>
            <Grid item sm={1} xs={12}>
              <img src={IconServicesTeam} className="IconGaol" alt={t('common.goals.avantages')} />
            </Grid>
            <Grid item sm={5} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: t('common.goals.Team') }} />
            </Grid>
          </Grid>
          <Grid
            alignItems="center"
            container
            spacing={2}
            className="gridQuotationsGoal mb-20 mt-20">
            <Grid item md={12} xs={12}>
              <Divider className={'DividerYellow'} />
            </Grid>
          </Grid>
          <Grid alignItems="center" container spacing={2} className="gridQuotationsGoal mb-20">
            <Grid item sm={1} xs={12}>
              <img
                src={IconServicesDigital}
                className="IconGaol"
                alt={t('common.goals.avantages')}
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: t('common.goals.Services_Digital') }} />
            </Grid>
            <Grid item sm={1} xs={12}>
              <img src={IconServicesOther} className="IconGaol" alt={t('common.goals.avantages')} />
            </Grid>
            <Grid item sm={5} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: t('common.goals.Services_Other') }} />
            </Grid>
          </Grid>
          <Grid
            alignItems="center"
            container
            spacing={2}
            className="gridQuotationsGoal mb-20 mt-20">
            <Grid item sm={12} xs={12}>
              <Divider className={'DividerYellow'} />
            </Grid>
          </Grid>
          <Grid alignItems="center" container spacing={2} className="gridQuotationsGoal mb-20">
            <Grid item sm={1} xs={12}>
              <img
                src={IconServicesEvents}
                className="IconGaol"
                alt={t('common.goals.avantages')}
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: t('common.goals.Events') }} />
            </Grid>
            <Grid item sm={1} xs={12}>
              <img
                src={IconServicesEcoresponsable}
                className="IconGaol"
                alt={t('common.goals.avantages')}
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: t('common.goals.Ecoresponsable') }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid alignItems="center" container spacing={4}>
            <Grid item md={6}>
              <p className="mt-0">
                <span className={'SubTitleContentTab'}>{t('common.goals.our_goal')}</span>
              </p>
              <div dangerouslySetInnerHTML={{ __html: t('common.goals.our_goal_text') }} />
            </Grid>
            <Grid item md={6}>
              <Grid
                alignItems="center"
                container
                spacing={4}
                className="bkGreen gridQuotationsGoal">
                <Grid item sm={2} xs={12}>
                  <img
                    src={IconServicesExperience}
                    className="IconGaol"
                    alt={t('common.goals.our_goal')}
                  />
                </Grid>
                <Grid item sm={10}>
                  <div dangerouslySetInnerHTML={{ __html: t('common.goals.Experience') }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default QuotationGoalComponent
