import React, { Fragment, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import { Link } from 'react-router-dom'

import { useOnLogin } from 'app/providers/onlogin.provider'
import { CivilityEnum } from 'app/enums/app.enum'
import 'modules/profile/css/profile.scss'

const Profile = (): JSX.Element => {
  const { account, accountUseQuery } = useOnLogin()

  const civility = useMemo(() => {
    let civility = ''
    if (account !== undefined && account?.civility) {
      civility =
        account?.civility === CivilityEnum.Man
          ? 'Monsieur'
          : 'Madame'
    }
    return civility
  }, [account])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <Box p={2}>
            <div className='myCard'>
              <div className='card-header'>
                <Typography variant='h2' color='primary'>Mes informations personnelles</Typography>
              </div>
              <div className='card-content'>
                {accountUseQuery?.isLoading &&
                  <div className='myCardLoader'><CircularProgress color='secondary' /></div>}
                {!accountUseQuery?.isLoading && (
                  <Fragment>
                    <List className=''>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className='avatarColor'>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary='Coordonnées' secondary=
                          {`${civility} ${
                            account !== undefined && account?.firstname !== undefined
                              ? account?.firstname
                              : ''
                          } ${
                            account !== undefined && account?.lastname !== undefined
                              ? account?.lastname
                              : ''
                          }`}
                        />
                      </ListItem>
                      <Divider variant='inset' component='li' />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className='avatarColor'>
                            <MailIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary='E-mail' secondary=
                          {account !== undefined && account?.email !== undefined
                            ? account?.email
                            : ''}
                        />
                      </ListItem>
                      <Divider variant='inset' component='li' />
                      <ListItem alignItems='flex-start'>
                        <ListItemAvatar>
                          <Avatar className='avatarColor'>
                            <PhoneIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary='Téléphone' secondary={
                          <React.Fragment>
                            Fixe : {account !== undefined && account?.phone !== undefined
                            ? account?.phone
                            : ''}
                            <br />
                            Mobile : {account !== undefined && account?.mobile !== undefined
                            ? account?.mobile
                            : ''}
                          </React.Fragment>
                        }
                        />
                      </ListItem>
                    </List>
                  </Fragment>
                )}
              </div>
              <div className='card-actions'>
                {!accountUseQuery?.isLoading && (
                  <Link to='/profile/edit' className='button'>Modifier</Link>
                )}
              </div>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Profile
