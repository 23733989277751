import {Theme} from "@material-ui/core";
import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {ThemeProvider} from "@material-ui/styles";
import {dark_theme, light_theme} from "../../theme";
import Session from "../libraries/session.lib";

export type AppThemeContextType = {
    switchTheme: () => void,
    theme: Theme | null,
    isDarkMode: boolean
}

const AppContext = createContext<AppThemeContextType>({
    switchTheme: () => undefined,
    theme: null,
    isDarkMode: false
})

interface IProps {
    children: ReactNode
}

const AppThemeProvider = ({children}: IProps): JSX.Element => {

    const [isDarkMode, setIsDark] = useState(Session.getIsDarkMode());
    const [theme, setTheme] = useState<Theme>(light_theme)
    const switchTheme = useCallback(() => {
        setIsDark(!isDarkMode);
    }, [isDarkMode])


    const values = useMemo(() => ({
        theme: theme,
        isDarkMode,
        switchTheme
    }), [theme, switchTheme, isDarkMode])

    useEffect(() => {
        const theme = isDarkMode ? dark_theme : light_theme;
        Session.setIsDarkMode(isDarkMode);
        setTheme(theme);
    }, [isDarkMode]);

    return <AppContext.Provider value={values}>
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    </AppContext.Provider>
}

export default AppThemeProvider

export const useAppTheme = (): AppThemeContextType => useContext<AppThemeContextType>(AppContext)