import React, { useCallback, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { List } from '@material-ui/core'
import { useOnView } from 'app/providers/onview.provider'
import { IDocument } from 'app/models/documents.model'
import DocumentListItemComponent from 'modules/documents/components/documentListItem.component'
import DocumentListFiltersComponent from 'modules/documents/components/documentListFilters.component'
import { IDocumentListFilter } from 'app/types/documents.type'
import 'css/list.scss'
import 'modules/documents/css/documents_list.scss'
import { useOnLogin } from 'app/providers/onlogin.provider'

const DocumentsList = (): JSX.Element => {
  const { t } = useTranslation()
  const { currentEnterprise } = useOnLogin()
  const { listDocumentUseMutation } = useOnView()

  const [documents, setDocuments] = useState<IDocument[] | undefined>()
  const [filteredDocuments, setFilteredDocuments] = useState<IDocument[] | undefined>(documents)
  const [filters, setFilters] = useState<IDocumentListFilter>()

  useEffect(() => {
    if (currentEnterprise !== undefined) {
      getDocuments()
    }
  }, [currentEnterprise])

  useEffect(() => {
    initFilters()
    setFilteredDocuments(documents)
  }, [documents])

  useEffect(() => {
    refreshList()
  }, [filters, documents])

  const getDocuments = useCallback(async () => {
    const _documents = await listDocumentUseMutation?.mutateAsync(currentEnterprise)
    setDocuments(_documents)
  }, [currentEnterprise])

  const initFilters = useCallback((): void => {
    let _filterTypes: string[] = []
    documents?.map((document: IDocument) => {
      if (!_filterTypes.includes(document.type)) {
        _filterTypes.push(document.type)
      }
    })
    _filterTypes.sort()
    setFilters({ ...filters, types: _filterTypes })
  }, [documents])

  const refreshList = useCallback((): void => {
    let _list = documents

    if (filters?.type !== undefined) {
      _list = _list?.filter((d: IDocument) => d.type === filters.type)
    }

    setFilteredDocuments(_list)
  }, [filters, documents])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <Box p={2}>
            <div className="myCard">
              <div className="card-header list-header">
                <Link to="/home" color="primary" className="icon-button">
                  <ArrowBackIcon fontSize="small" />
                </Link>
                <Typography variant="h2" color="primary">
                  {t('common.my_documents')}
                </Typography>
              </div>
              <DocumentListFiltersComponent
                onChangeFilters={(_filters: IDocumentListFilter) => setFilters(_filters)}
                filters={filters}
              />
              <div className="card-content">
                {filteredDocuments !== undefined &&
                  filteredDocuments.length > 0 &&
                  !listDocumentUseMutation?.isLoading && (
                    <List className="myListItem">
                      {filteredDocuments.map((document: IDocument, key: number) => (
                        <DocumentListItemComponent document={document} key={key} />
                      ))}
                    </List>
                  )}
                {(filteredDocuments === undefined || filteredDocuments.length === 0) && (
                  <Typography variant="body2" color="textSecondary">
                    {t('common.documentListView.noDocument')}
                  </Typography>
                )}
                {(filteredDocuments === undefined || listDocumentUseMutation?.isLoading) && (
                  <div className="myCardLoader">
                    <CircularProgress color="secondary" />
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DocumentsList
