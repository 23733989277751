import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Divider } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { DocumentParams } from 'app/types/documents.type'
import { IDocumentDetail } from 'app/models/documents.model'
import { useOnView } from 'app/providers/onview.provider'
import { useOnLogin } from 'app/providers/onlogin.provider'
import Session from 'app/libraries/session.lib'

import DocumentDetailsPdfComponent from 'modules/documents/components/documentDetailsPdf.component'
import DocumentDetailsHeaderComponent from 'modules/documents/components/documentDetailsHeader.component'
import DocumentDetailsInfoComponent from 'modules/documents/components/documentDetailsInfo.component'
import DocumentDetailsActionComponent from 'modules/documents/components/documentDetailsAction.component'

import 'css/list.scss'
import 'modules/documents/css/documentsDetails.scss'

const DocumentsDetails = (): JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams<DocumentParams>()
  const { currentEnterprise } = useOnLogin()
  const { documentDetailsUseMutation } = useOnView()

  const [document, setDocument] = useState<IDocumentDetail | undefined>()

  useEffect(() => {
    getDocument().then()
  }, [id])

  useEffect(() => {
    if (currentEnterprise !== Session.getCurrentEnterprise()) {
      window.location.href = '/enterprise/documents/list'
    }
  }, [currentEnterprise, Session.getCurrentEnterprise()])

  const getDocument = useCallback(async () => {
    if (undefined !== id && Number(id) > 0) {
      const _document = await documentDetailsUseMutation?.mutateAsync({
        enterpriseId: currentEnterprise,
        id: Number(id)
      })
      setDocument(_document)
    }
  }, [id, currentEnterprise])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <Box p={2}>
            <div className="myCard documents_details">
              <div className="card-header list-header">
                <Link to="/enterprise/documents/list" color="primary" className="icon-button">
                  <ArrowBackIcon fontSize="small" />
                </Link>
                <Typography variant="h2" color="primary">
                  {t('common.documentView.title')}
                </Typography>
              </div>
              <div className="card-content">
                <Grid container spacing={3}>
                  <Grid item xl={3} md={4} xs={12}>
                    <DocumentDetailsHeaderComponent document={document} />
                    <Grid container spacing={3}>
                      <DocumentDetailsInfoComponent document={document} />
                      <Grid item xs={12} className="pt-0">
                        <Divider></Divider>
                        <DocumentDetailsActionComponent id={Number(id)} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={9} md={8} xs={12}>
                    <DocumentDetailsPdfComponent id={Number(id)} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DocumentsDetails
