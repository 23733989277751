import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'

import { ContractValidationStepEnum } from 'app/enums/contract.enum'
import { useOnView } from 'app/providers/onview.provider'
import { IContract } from 'app/models/contracts.model'
import { useOnLogin } from 'app/providers/onlogin.provider'

export interface IProps {
  id?: number
  setStep: (step: ContractValidationStepEnum) => void
  setIsDisplayContract: (data: boolean) => void
}

const ContractPendingStep3Component = ({
  id,
  setStep,
  setIsDisplayContract
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { currentEnterprise } = useOnLogin()
  const { sendSmsPendingContractUseMutation } = useOnView()

  const [code, setCode] = useState<string | undefined>(undefined)
  const [updatedContract, setUpdatedContract] = useState<IContract | undefined>(undefined)

  useEffect(() => {
    if (updatedContract !== undefined) {
      //contracts.find((c: IContract) => c.id === updatedContract.id)!.agreement = updatedContract.agreement
    }
  }, [updatedContract])

  const onSendSms = useCallback(async (): Promise<void> => {
    try {
      let _contract = await sendSmsPendingContractUseMutation?.mutateAsync({
        enterpriseId: currentEnterprise,
        id: id!,
        code: code!
      })
      if (undefined !== _contract) {
        setUpdatedContract(_contract)
      }
      setStep(ContractValidationStepEnum.CONTRACT_VALIDATED)
      setIsDisplayContract(false)
    } catch (error) {
      setIsDisplayContract(true)
      setStep(ContractValidationStepEnum.CODE_TO_REQUEST)
      //setOnCodeRequestMessage('Le code saisi est erroné. Renvoyer un autre code par sms.')
    }
  }, [currentEnterprise, id, code])

  return (
    <div className="card">
      <div className="card-content">
        <div className="pending-content-info">{t('common.pendingContract.sentSms')}</div>
        <input
          type="number"
          className="input mb-xs w-50"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setCode(event.target.value)}
        />
      </div>
      <div className="card-actions">
        {sendSmsPendingContractUseMutation?.isLoading && (
          <div className="myCardLoader">
            <CircularProgress color="secondary" />
          </div>
        )}
        {!sendSmsPendingContractUseMutation?.isLoading && (
          <button
            type="button"
            className="button"
            onClick={async () => {
              await onSendSms()
            }}
            disabled={code === undefined}>
            {t('common.pendingContract.send')}
          </button>
        )}
      </div>
    </div>
  )
}

export default ContractPendingStep3Component
