import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, List, ListItem, ListItemText } from '@material-ui/core'
import Box from '@material-ui/core/Box'

import { IContractOption } from 'app/models/contracts.model'

export interface IProps {
  options: IContractOption[]
}

const ContractDetailsOptionComponent = ({ options }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Divider></Divider>
      <Box component="div" p={2} mt={2} mb={2}>
        <strong>{t('common.contractDetails.service.option')}</strong>
        <List dense={true} className="pt-0">
          {options.map((option: IContractOption, key: number) => (
            <ListItem key={key} className="pl-0">
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  )
}

export default ContractDetailsOptionComponent
