import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import { useTranslation } from 'react-i18next'
import Fab from '@material-ui/core/Fab'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import { Tab } from '@material-ui/core'

import Logo from 'images/common/logo.png'
import { useApp } from 'app/providers/app.provider'
import { useOnView } from 'app/providers/onview.provider'
import { SolutionTypeEnum } from 'app/enums/quotation.enum'
import { IQuotation, ISolution } from 'app/models/quotations.models'
import QuotationContactComponent from 'modules/quotations/components/header/quotationContact.component'
import QuotationOptionsComponent from 'modules/quotations/components/options/quotationOptions.component'
import QuotationServicesComponent from 'modules/quotations/components/services/quotationServices.component'
import QuotationGoalComponent from 'modules/quotations/components/goals/quotationGoal.component'
import QuotationAccordionComponent from 'modules/quotations/components/header/quotationAccordion.component'
import QuotationDetailsCardComponent from 'modules/quotations/components/header/quotationDetailsCard.component'
import QuotationModalContactComponent from 'modules/quotations/components/header/quotationModalContact.component'
import QuotationSolutionsComponent from 'modules/quotations/components/solutions/quotationSolutions.component'
import QuotationDesktopComponent from 'modules/quotations/components/desktop/quotationDesktop.component'
import QuotationCoworkingComponent from 'modules/quotations/components/coworking/quotationCoworking.component'
import QuotationNomadeComponent from 'modules/quotations/components/nomade/quotationNomade.component'
import QuotationDomiciliationComponent from 'modules/quotations/components/domiciliation/quotationDomiciliation.component'
import QuotationCenterComponent from 'modules/quotations/components/center/quotationCenter.component'
import QuotationMeetingRoomComponent from 'modules/quotations/components/meetingRoom/quotationMeetingRoom.component'
import 'modules/quotations/css/quotations_card.scss'

const QuotationsCard = (): JSX.Element => {
  const { t } = useTranslation()
  const { isConnected } = useApp()
  const { quotationGetUseMutation } = useOnView()

  const [tabValue, setTabValue] = useState<number>(0)
  const [quotation, setQuotation] = useState<IQuotation | undefined>()
  const [modalContactDisplay, setModalContactDisplay] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    getQuotation().then()
  }, [])

  const getQuotation = useCallback(async () => {
    let params = new URLSearchParams(window.location.search)
    let token = params.get('token')

    if (null !== token) {
      let _quotation = await quotationGetUseMutation?.mutateAsync(token)
      if (undefined !== _quotation) {
        let _solutions: Map<SolutionTypeEnum, ISolution[]> = new Map<
          SolutionTypeEnum,
          ISolution[]
        >()
        for (let k of Object.entries(_quotation.solutions)) {
          _solutions.set(Number(k[0]), k[1])
        }
        _quotation.solutions = _solutions
        setQuotation(_quotation)
        setIsMobile(window.matchMedia('(max-width: 598px)').matches)
      }
    }
  }, [])

  const tabIndex = useMemo(() => {
    let solutions: number | undefined = undefined
    let desktop: number | undefined = undefined
    let coworking: number | undefined = undefined
    let nomade: number | undefined = undefined
    let domiciliation: number | undefined = undefined
    let index = 0

    if (undefined !== quotation && quotation?.solutions.size > 1) {
      solutions = index
      ++index
    }

    if (quotation?.solutions.has(SolutionTypeEnum.DESKTOP)) {
      desktop = index
      ++index
    }

    if (quotation?.solutions.has(SolutionTypeEnum.COWORKING)) {
      coworking = index
      ++index
    }

    if (quotation?.solutions.has(SolutionTypeEnum.NOMADE)) {
      nomade = index
      ++index
    }

    if (quotation?.solutions.has(SolutionTypeEnum.DOMICILIATION)) {
      domiciliation = index
      ++index
    }

    return {
      solutions: solutions,
      desktop: desktop,
      coworking: coworking,
      nomade: nomade,
      domiciliation: domiciliation,
      meeting: index,
      center: index + 1,
      options: index + 2,
      services: index + 3,
      goals: index + 4
    }
  }, [quotation?.solutions])

  return (
    <>
      {quotation !== undefined && (
        <Grid container spacing={3} className="quotationscard-container">
          {!isConnected && (
            <Grid item xs={12} className="logoDevis">
              <img src={Logo} className="logo" alt="Flex-O" />
            </Grid>
          )}
          {isMobile && (
            <Grid item xs={12}>
              <QuotationAccordionComponent quotation={quotation} />
            </Grid>
          )}
          {!isMobile && (
            <Grid item md={8} xs={12}>
              <Paper elevation={1}>
                <Box p={2}>
                  <QuotationDetailsCardComponent quotation={quotation} />
                </Box>
              </Paper>
            </Grid>
          )}
          {quotation.contact !== undefined && !isMobile && (
            <Grid item md={4} xs={12}>
              <Paper elevation={1}>
                <Box p={2} className="contact-card-background">
                  <QuotationContactComponent quotation={quotation} />
                </Box>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper elevation={1}>
              <Box p={2}>
                <div className="myCard">
                  <div>
                    <Tabs
                      className="quotations-tab"
                      variant="scrollable"
                      scrollButtons="auto"
                      value={tabValue}
                      onChange={(event: any, value: number) => setTabValue(value)}
                      aria-label="simple tabs"
                      indicatorColor="primary"
                      textColor="primary">
                      {undefined !== tabIndex.solutions && (
                        <Tab
                          label={t('common.quotationView.tabs.solutions')}
                          id={`simple-tab-${tabIndex.solutions}`}
                          aria-controls={`quotations-tabpanel-${tabIndex.solutions}`}
                        />
                      )}
                      {undefined !== tabIndex.desktop && (
                        <Tab
                          label={t('common.quotationView.tabs.desktop')}
                          id={`simple-tab-${tabIndex.desktop}`}
                          aria-controls={`quotations-tabpanel-${tabIndex.desktop}`}
                        />
                      )}
                      {undefined !== tabIndex.coworking && (
                        <Tab
                          label={t('common.quotationView.tabs.coworking')}
                          id={`simple-tab-${tabIndex.coworking}`}
                          aria-controls={`quotations-tabpanel-${tabIndex.coworking}`}
                        />
                      )}
                      {undefined !== tabIndex.nomade && (
                        <Tab
                          label={t('common.quotationView.tabs.nomade')}
                          id={`simple-tab-${tabIndex.nomade}`}
                          aria-controls={`quotations-tabpanel-${tabIndex.nomade}`}
                        />
                      )}
                      {undefined !== tabIndex.domiciliation && (
                        <Tab
                          label={t('common.quotationView.tabs.domiciliation')}
                          id={`simple-tab-${tabIndex.domiciliation}`}
                          aria-controls={`quotations-tabpanel-${tabIndex.domiciliation}`}
                        />
                      )}
                      <Tab
                        label={t('common.quotationView.tabs.meetingRoom')}
                        id={`simple-tab-${tabIndex.meeting}`}
                        aria-controls={`quotations-tabpanel-${tabIndex.meeting}`}
                      />
                      <Tab
                        label={t('common.quotationView.tabs.center')}
                        id={`simple-tab-${tabIndex.center}`}
                        aria-controls={`quotations-tabpanel-${tabIndex.center}`}
                      />
                      <Tab
                        label={t('common.quotationView.tabs.options')}
                        id={`simple-tab-${tabIndex.options}`}
                        aria-controls={`quotations-tabpanel-${tabIndex.options}`}
                      />
                      <Tab
                        label={t('common.quotationView.tabs.services')}
                        id={`simple-tab-${tabIndex.services}`}
                        aria-controls={`quotations-tabpanel-${tabIndex.services}`}
                      />
                      <Tab
                        label={t('common.quotationView.tabs.goals')}
                        id={`simple-tab-${tabIndex.goals}`}
                        aria-controls={`quotations-tabpanel-${tabIndex.goals}`}
                      />
                    </Tabs>
                  </div>
                  <div className="card-content">
                    {undefined !== tabIndex.solutions && (
                      <QuotationSolutionsComponent
                        isMobile={isMobile}
                        hidden={tabValue !== tabIndex.solutions}
                        quotation={quotation}
                        index={tabIndex.solutions}
                      />
                    )}
                    {undefined !== tabIndex.desktop && (
                      <QuotationDesktopComponent
                        isMobile={isMobile}
                        hidden={tabValue !== tabIndex.desktop}
                        quotation={quotation}
                        index={tabIndex.desktop}
                      />
                    )}
                    {undefined !== tabIndex.coworking && (
                      <QuotationCoworkingComponent
                        isMobile={isMobile}
                        hidden={tabValue !== tabIndex.coworking}
                        quotation={quotation}
                        index={tabIndex.coworking}
                      />
                    )}
                    {undefined !== tabIndex.nomade && (
                      <QuotationNomadeComponent
                        isMobile={isMobile}
                        hidden={tabValue !== tabIndex.nomade}
                        quotation={quotation}
                        index={tabIndex.nomade}
                      />
                    )}
                    {undefined !== tabIndex.domiciliation && (
                      <QuotationDomiciliationComponent
                        isMobile={isMobile}
                        hidden={tabValue !== tabIndex.domiciliation}
                        quotation={quotation}
                        index={tabIndex.domiciliation}
                      />
                    )}
                    <QuotationMeetingRoomComponent
                      isMobile={isMobile}
                      hidden={tabValue !== tabIndex.meeting}
                      quotation={quotation}
                      index={tabIndex.meeting}
                    />
                    <QuotationCenterComponent
                      hidden={tabValue !== tabIndex.center}
                      quotation={quotation}
                      index={tabIndex.center}
                    />
                    <QuotationOptionsComponent
                      hidden={tabValue !== tabIndex.options}
                      index={tabIndex.options}
                    />
                    <QuotationServicesComponent
                      hidden={tabValue !== tabIndex.services}
                      index={tabIndex.services}
                    />
                    <QuotationGoalComponent
                      hidden={tabValue !== tabIndex.goals}
                      index={tabIndex.goals}
                    />
                  </div>
                </div>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <>
          <div className="floatingButtonContact">
            <Fab
              size="small"
              color="secondary"
              onClick={() => setModalContactDisplay(!modalContactDisplay)}>
              <PersonPinIcon />
            </Fab>
          </div>
          <QuotationModalContactComponent
            open={modalContactDisplay}
            onClose={() => setModalContactDisplay(!modalContactDisplay)}
            quotation={quotation}
          />
        </>
      )}
    </>
  )
}

export default QuotationsCard
