import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '@react-pdf-viewer/core/lib/styles/index.css'

import { IContractDetail, IContractListItem } from 'app/models/contracts.model'
import { useOnLogin } from 'app/providers/onlogin.provider'
import { useOnView } from 'app/providers/onview.provider'
import { ContractParams } from 'app/types/contracts.type'
import { ContractValidationStepEnum } from 'app/enums/contract.enum'
import ContractPendingStep1Component from 'modules/contracts/components/contractPendingStep1.component'
import ContractPendingStep2Component from 'modules/contracts/components/contractPendingStep2.component'
import ContractPendingStep3Component from 'modules/contracts/components/contractPendingStep3.component'
import ContractPendingStep4Component from 'modules/contracts/components/contractPendingStep4.component'
import ContractPendingPdfComponent from 'modules/contracts/components/contractPendingPdf.component'
import 'modules/contracts/css/pending_contract.scss'
import {IPaymentMethod} from "../../../app/models/payments.models";

const PendingContract = (): JSX.Element => {
  const { pendingContracts, currentEnterprise } = useOnLogin()
  const { contractDetailsUseMutation, contractPdfUseMutation, paymentMethodListConnectedUseMutation, gcMandateConnectedLinkGetUseMutation } = useOnView()
  const { id } = useParams<ContractParams>()

  const [step, setStep] = useState<ContractValidationStepEnum>(ContractValidationStepEnum.BEGIN)
  const [isDisplayContract, setIsDisplayContract] = useState<boolean>(true)
  const [contract, setContract] = useState<IContractDetail | undefined>()
  const [nbPaymentMethodActives, setNbPaymentMethodActives] = useState<number | undefined>()
  const [redirectUrlGc, setRedirectUrlGc] = useState<string>('')

  const getContractDetails = useCallback(async () => {
    if (undefined !== id) {
      const _contract = await contractDetailsUseMutation?.mutateAsync({
        enterpriseId: currentEnterprise,
        id: Number(id)
      })
      if (undefined !== _contract) {
        const _pdf = await contractPdfUseMutation?.mutateAsync({
          enterpriseId: currentEnterprise,
          id: Number(id)
        })
        _contract.file = _pdf?.file
      }
      setContract(_contract)
    }
  }, [currentEnterprise, id])

  const initNbPaymentMethodActives = useCallback(async (enterpriseId: number, centerId: number) => {
    let _paymentMethods = await paymentMethodListConnectedUseMutation?.mutateAsync({ enterpriseId, centerId})
    if (undefined !== _paymentMethods) {
      let _paymentMethodActives = _paymentMethods.filter((paymentMethod: IPaymentMethod) => paymentMethod.active)
      setNbPaymentMethodActives(_paymentMethodActives.length)
    }
  }, [])

  const initLinkMandateGc = useCallback(async (enterpriseId: number, centerId: number) => {
    let _redirectFlowGc = await gcMandateConnectedLinkGetUseMutation?.mutateAsync({enterpriseId, centerId})
    if (undefined !== _redirectFlowGc) {
      setRedirectUrlGc(_redirectFlowGc.redirectUrl)
    }
  }, [nbPaymentMethodActives])

  useEffect(() => {
    if (currentEnterprise !== undefined && contract !== undefined) {
      initNbPaymentMethodActives(currentEnterprise, contract.centerId).then()
    }
  }, [currentEnterprise, contract])

  useEffect(() => {
    if (
      currentEnterprise !== undefined &&
      contract !== undefined &&
      nbPaymentMethodActives !== undefined &&
      nbPaymentMethodActives < 1
    ) {
      initLinkMandateGc(currentEnterprise, contract.centerId).then()
    }
  }, [currentEnterprise, contract, nbPaymentMethodActives])

  useEffect(() => {
    if (undefined !== id && undefined !== pendingContracts) {
      const _contracts = pendingContracts?.filter((c: IContractListItem) => c.id === Number(id))
      if (_contracts.length === 1) {
        getContractDetails().then()
      }
    }
  }, [id, pendingContracts])

  const setValidationStep = (): JSX.Element => {
    switch (step) {
      case ContractValidationStepEnum.BEGIN:
        return (
          <ContractPendingStep1Component
            setStep={(step: ContractValidationStepEnum) => setStep(step)}
          />
        )
      case ContractValidationStepEnum.CODE_TO_REQUEST:
        return (
          <ContractPendingStep2Component
            id={contract?.id}
            setStep={(step: ContractValidationStepEnum) => setStep(step)}
          />
        )
      case ContractValidationStepEnum.CODE_REQUESTED:
        return (
          <ContractPendingStep3Component
            id={contract?.id}
            setStep={(step: ContractValidationStepEnum) => setStep(step)}
            setIsDisplayContract={(data: boolean) => setIsDisplayContract(data)}
          />
        )
      case ContractValidationStepEnum.CONTRACT_VALIDATED:
        return <ContractPendingStep4Component redirectUrlGc={redirectUrlGc}/>
      default:
        return <div />
    }
  }

  return (
    <>
      {(contract !== undefined || step === ContractValidationStepEnum.CONTRACT_VALIDATED) && (
        <>
          <div className="pending-contract-step">{setValidationStep()}</div>
          {isDisplayContract && <ContractPendingPdfComponent contract={contract} />}
        </>
      )}
    </>
  )
}

export default PendingContract
