import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'

import { IQuotation } from 'app/models/quotations.models'

import QuotationContactComponent from 'modules/quotations/components/header/quotationContact.component'

export interface IProps {
  open: boolean
  quotation?: IQuotation
  onClose: () => void
}

const QuotationModalContactComponent = ({ open, quotation, onClose }: IProps): JSX.Element => (
  <Dialog fullWidth={true} onClose={onClose} open={open} className="ModalContact">
    <IconButton className="close-modal" size="small" onClick={onClose}>
      <CloseIcon />
    </IconButton>
    <QuotationContactComponent quotation={quotation!} />
  </Dialog>
)

export default QuotationModalContactComponent
