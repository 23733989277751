import {useTranslation} from "react-i18next";
import {useOnLogin} from "app/providers/onlogin.provider";
import React, {useCallback, useEffect, useState} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import {CircularProgress} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import HelpdeskListCenterComponent from "./helpdeskListCenter.component";
import TextField from "@material-ui/core/TextField";
import {HELPDESK_COLORS, HELPDESK_ICONS} from "modules/helpdesk/views/helpdesk.view";
import {HelpdeskCategory, HelpdeskSubcategory} from "app/models/helpdesk.models";

interface FormProps {
    validateForm: (subcategory?: string, center?: string, comment?: string) => void
    category?: HelpdeskCategory,
    subcategories: HelpdeskSubcategory[]
}

export const HelpdeskForm = ({validateForm, category, subcategories}: FormProps): JSX.Element => {
    const {t} = useTranslation()
    const {listCenterUseQuery} = useOnLogin()
    const [label, setLabel] = useState<string>()
    const [option, setOption] = useState<string | undefined>(undefined)
    const [center, setCenter] = useState<string | undefined>(undefined)
    const [comment, setComment] = useState<string | undefined>()
    const [submit, setSubmit] = useState<boolean>(true)

    useEffect(() => {
        if (!category) return;
        setLabel(t('common.customer_services.' + category.label))
        if(category.label === 'others'){
            setOption(category.reference)
        }
    }, [category])

    const changeSubcategory = (event: any): void => {
        setOption(event.target.value)
    }

    const currentCenter = useCallback((centerReference: string): void => {
        setCenter(centerReference)
    }, [])

    const changeComment = (event: any): void => {
        setComment(event.target.value)
    }

    useEffect(() => {
        if (!category) return;
        if (category.label !== 'others') {
            setSubmit(!(center !== undefined && center !== '' && option !== undefined && option !== ''))
        } else {
            setSubmit(!(center !== undefined && center !== '' && comment !== undefined && comment !== ''))
        }
    }, [category, comment, option, center])

    const onSubmit = useCallback((): void => {
        if (!category) return;
        if (
            (option !== undefined && center !== undefined) ||
            (category.label !== 'others' && center !== undefined && comment !== undefined)
        ) {
            validateForm(option, center, comment)
        }
    }, [category, option, center, comment])

    return (
        <>
            <List className="formIconHelpdesk">
                <ListItem alignItems="center" className={HELPDESK_COLORS[category?.label as keyof typeof HELPDESK_COLORS] ?? HELPDESK_COLORS['default']}>
                    <ListItemAvatar>
                        <Avatar className="avatarColorHelpdesk">
                            {HELPDESK_ICONS[category?.label as keyof typeof HELPDESK_ICONS] ??
                             HELPDESK_ICONS['default']}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={label}/>
                </ListItem>
            </List>
            <Box component="div" p={3} width="60%" className="w-100">
                {listCenterUseQuery?.isLoading && <CircularProgress color="secondary"/>}
                {!listCenterUseQuery?.isLoading && (
                    <>
                        {category?.label !== 'others' && (
                            <FormControl variant="standard" className="labelFormControl" fullWidth>
                                <InputLabel htmlFor="filled-civility-native-simple">
                                    {t('common.helpdesk_select')}
                                </InputLabel>
                                <Select
                                    native
                                    value={option}
                                    inputProps={{
                                        name: 'under-category',
                                        id: 'under-category-native-simple'
                                    }}
                                    onChange={changeSubcategory}>
                                    <option/>
                                    {subcategories?.map((data: any, key: number) => (
                                        <option key={key} value={data.reference}>
                                            {t('common.customer_services.' + data.label)}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        <HelpdeskListCenterComponent setCurrentCenter={currentCenter}/>
                    </>
                )}
            </Box>
            <Box p={3} width="100%">
                <TextField
                    id="message-multiline-flexible"
                    label={t('common.helpdesk_message')}
                    multiline
                    fullWidth
                    minRows={4}
                    variant="outlined"
                    value={comment}
                    onChange={changeComment}
                />
            </Box>
            <Box p={3} width="100%" className="text-center">
                <button type="button" className="button" onClick={onSubmit} disabled={submit}>
                    {t('common.send')}
                </button>
            </Box>
        </>
    )
}