import React, {ChangeEvent} from 'react'
import Select from '@material-ui/core/Select'
import {withTranslation, WithTranslation} from 'react-i18next'

interface Props extends WithTranslation {
  allCentersOption: boolean
  centers: string[]
  defaultCenter: string
  onCenterChange: (selected: string) => void
}

interface State {}

class CenterFilter extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props)
  }

  public render(): JSX.Element {
    return (
      <Select className='filter mr-xs mb-xs'
              native
              variant="standard"
              defaultValue={this.props.defaultCenter}
              onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => this.props.onCenterChange(String(event.target.value))}>
        { this.props.allCentersOption && (
          <option value=''>{this.props.t('common.all_my_centers')}</option>
        )}

        {this.props.centers.map((center: string, index: number) => {
            return (
              <option key={index} value={center}>
                {center}
              </option>
            )
          }
        )}
      </Select>
    )
  }
}

export default withTranslation()(CenterFilter)
