import { checkResponse, getRequestInit, getUrl } from 'app/api/api'
import { IQuotation, IQuotationListItem } from 'app/models/quotations.models'
import { IFilePdf } from 'app/models/files.model'

export const QuotationGetQuery = async (token: string): Promise<IQuotation> => {
  return checkResponse(
    await fetch(
      getUrl(`quotations?token=${encodeURIComponent(token)}`, true),
      getRequestInit(false)
    )
  )
}


export const QuotationListQuery = async (enterprise: number): Promise<IQuotationListItem[]> => {
  return checkResponse(
  await fetch(getUrl(`clientspace/enterprises/${enterprise}/quotations`), getRequestInit(true))
  )
}

export const QuotationDownloadQuery = async (token: string): Promise<IFilePdf> => {
  const response = await fetch(
    getUrl(`quotations/pdf?token=${encodeURIComponent(token)}`, true),
    getRequestInit(false)
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const contentDisposition = response.headers.get('content-disposition');
  const filename = contentDisposition
    ? contentDisposition.split('filename=')[1].split(';')[0]
    : 'quotation.pdf';

  const arrayBuffer = await response.arrayBuffer();

  return {
    content: arrayBuffer,
    mimetype: response.headers.get('content-type') || 'application/pdf',
    filename,
  };
};
