import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import fileDownload from 'js-file-download'

import {IContractDetail} from 'app/models/contracts.model'
import DocumentHelper from 'app/helpers/document.helper'
import {useOnView} from 'app/providers/onview.provider'

export interface IProps {
  contract?: IContractDetail
  redirectUrlGc: string
}

const ContractDetailsActionComponent = ({contract, redirectUrlGc}: IProps): JSX.Element => {
  const {t} = useTranslation()
  const {termsOfSalesDownloadUseMutation} = useOnView()

  const downloadTermsOfSales = useCallback(async (): Promise<void> => {
    if (undefined !== contract) {
      let file = await termsOfSalesDownloadUseMutation?.mutateAsync(Number(contract.termsOfSales))
      if (undefined !== file) {
        let blob = DocumentHelper.base64toBlob(file.content, file.mimetype)
        fileDownload(blob, file.filename, file.mimetype)
      }
    }
  }, [contract])

  const downloadContractPdf = useCallback(async (): Promise<void> => {
    if (undefined !== contract && undefined !== contract.file) {
      let blob = DocumentHelper.base64toBlob(contract.file, 'application/pdf')
      fileDownload(blob, contract.reference, 'application/pdf')
    }
  }, [contract])

  return (
    <>
      <Box textAlign="center" component="div" p={2} mt={2} mb={1}>
        <button type="button" className="button w-174" onClick={() => downloadTermsOfSales()}>
          {t('common.cg')}
        </button>
      </Box>
      <Box textAlign="center" component="div" p={2} mb={1}>
        <button type="button" className="button w-174" onClick={() => downloadContractPdf()}>
          {t('common.download_contract')}
        </button>
      </Box>
      {redirectUrlGc !== '' && (
        <Box textAlign="center" component="div" p={2} mb={2}>
          <button className="button bg-secondary w-174" onClick={() => window.location.href = redirectUrlGc}>
            {t('common.activate_direct_debit')}
          </button>
        </Box>
      )}
    </>
  )
}

export default ContractDetailsActionComponent
