import { useTranslation } from 'react-i18next'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'

import { IQuotation, ISolution } from 'app/models/quotations.models'
import { SolutionTypeEnum } from 'app/enums/quotation.enum'
import QuotationCoworkingAccordionComponent from 'modules/quotations/components/coworking/quotationCoworkingAccordion.component'
import QuotationNomadeAccordionComponent from 'modules/quotations/components/nomade/quotationNomadeAccordion.component'
import QuotationDomiciliationAccordionComponent from 'modules/quotations/components/domiciliation/quotationDomiciliationAccordion.component'
import QuotationDesktopAccordionComponent from 'modules/quotations/components/desktop/quotationDesktopAccordion.component'

export interface IProps {
  hidden: boolean
  quotation: IQuotation
  isMobile: boolean
  index: number
}

const QuotationSolutionsComponent = ({
  hidden,
  quotation,
  isMobile,
  index
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div role="tabpanel" hidden={hidden} id={`quotations-tabpanel-${index}`}>
      <Grid container spacing={3} className="mb-20">
        <Grid item xs={12} sm={9} md={10} className="display-proposed-solutions">
          <Box fontWeight="fontWeightBold" mt={0} mb={2} component="p" className="textColorInfo">
            {t('common.quotationView.solutions.title')}
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          {quotation.visitLink !== '' && quotation.visitLink !== null && (
            <Box textAlign="center">
              <a
                className="cursor-pointer button visitLink"
                onClick={() => window.open(quotation.visitLink, '_blank')}>
                {t('common.quotationView.center.visit')}
              </a>
            </Box>
          )}
        </Grid>
        <div className="clear" />
      </Grid>
      <div className="accordion-solution">
        {quotation.solutions.has(SolutionTypeEnum.DESKTOP) && (
          <Accordion className="accordion-solution-block" elevation={0} defaultExpanded={!isMobile}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="produit-content"
              id="produit-header"
              className="accordion-solution-header primary">
              <Typography className="text-uppercase">
                <strong>{t('common.quotationView.tabs.desktop')}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-solution-body-level-0 pt-20">
              <Grid container spacing={3} className="mb-20">
                {quotation.solutions
                  .get(SolutionTypeEnum.DESKTOP)
                  ?.map((solution: ISolution, key: number) => {
                    return (
                      <QuotationDesktopAccordionComponent
                        solution={solution}
                        solutionKey={key}
                        key={key}
                        isMobile={isMobile}
                        isRecap={true}
                      />
                    )
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {quotation.solutions.has(SolutionTypeEnum.COWORKING) && (
          <Accordion className="accordion-solution-block" elevation={0} defaultExpanded={!isMobile}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="produit-content"
              id="produit-header"
              className="accordion-solution-header primary">
              <Typography className="text-uppercase">
                <strong>{t('common.quotationView.tabs.coworking')}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-solution-body-level-0 pt-20">
              <Grid container spacing={3} className="mb-20">
                {quotation.solutions
                  .get(SolutionTypeEnum.COWORKING)
                  ?.map((solution: ISolution, key: number) => {
                    return (
                      <QuotationCoworkingAccordionComponent
                        solution={solution}
                        solutionKey={key}
                        key={key}
                        isMobile={isMobile}
                        isRecap={true}
                      />
                    )
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {quotation.solutions.has(SolutionTypeEnum.NOMADE) && (
          <Accordion className="accordion-solution-block" elevation={0} defaultExpanded={!isMobile}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="produit-content"
              id="produit-header"
              className="accordion-solution-header primary">
              <Typography className="text-uppercase">
                <strong>{t('common.quotationView.tabs.nomade')}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-solution-body-level-0 pt-20">
              <Grid container spacing={3} className="mb-20">
                {quotation.solutions
                  .get(SolutionTypeEnum.NOMADE)
                  ?.map((solution: ISolution, key: number) => {
                    return (
                      <QuotationNomadeAccordionComponent
                        solution={solution}
                        solutionKey={key}
                        key={key}
                        isMobile={isMobile}
                        isRecap={true}
                      />
                    )
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {quotation.solutions.has(SolutionTypeEnum.DOMICILIATION) && (
          <Accordion className="accordion-solution-block" elevation={0} defaultExpanded={!isMobile}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="produit-content"
              id="produit-header"
              className="accordion-solution-header primary">
              <Typography className="text-uppercase">
                <strong>{t('common.quotationView.tabs.domiciliation')}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-solution-body-level-0 pt-20">
              <Grid container spacing={3} className="mb-20">
                {quotation.solutions
                  .get(SolutionTypeEnum.DOMICILIATION)
                  ?.map((solution: ISolution, key: number) => {
                    return (
                      <QuotationDomiciliationAccordionComponent
                        solution={solution}
                        solutionKey={key}
                        key={key}
                        isMobile={isMobile}
                        isRecap={true}
                      />
                    )
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <Box mb={2} fontSize={12} component="p" color="text.primary">
        *{t('common.quotationView.typologyDescription')}
      </Box>
      <Box mb={2} fontSize={12} fontWeight="fontWeightBold" component="p" color="text.primary">
        {t('common.quotationView.offerDescription')}
      </Box>
    </div>
  )
}

export default QuotationSolutionsComponent
