import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@material-ui/core'

import IconServicesCatering from 'images/quotations/icon/services/icon-services-restauration.jpg'
import IconServicesInclusOtherService from 'images/quotations/icon/services/icon-services-autre-service.jpg'
import IconServicesInclusConnexion from 'images/quotations/icon/services/icon-services-inclus-connection.jpg'
import IconServicesInclusSpaceShare from 'images/quotations/icon/services/icon-services-inclus-espace-partage.jpg'
import IconServicesInclusMeetingRoom from 'images/quotations/icon/services/icon-services-salle-de-reunion.jpg'

const QuotationMeetingRoomIncludedComponent = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={4}>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusConnexion}
          className="IconInclusives"
          alt={t('common.quotationView.included.fiber')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.fiber')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusSpaceShare}
          className="IconInclusives"
          alt={t('common.quotationView.included.nomadeAccess')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.nomadeAccess')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusSpaceShare}
          className="IconInclusives"
          alt={t('common.quotationView.included.coffee')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.coffee')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusMeetingRoom}
          className="IconInclusives"
          alt={t('common.quotationView.included.equipment')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.equipment')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusOtherService}
          className="IconInclusives"
          alt={t('common.quotationView.included.receptionOnly')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.receptionOnly')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesCatering}
          className="IconInclusives"
          alt={t('common.quotationView.included.catering')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.catering')}
        </Box>
      </Grid>
    </Grid>
  )
}

export default QuotationMeetingRoomIncludedComponent
