import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import EventIcon from '@material-ui/icons/Event'
import DescriptionIcon from '@material-ui/icons/Description'

import { IDocumentDetail } from 'app/models/documents.model'
import DateFormatter from 'app/formatters/date.formatter'

export interface IProps {
  document?: IDocumentDetail
}

const DocumentDetailsInfoComponent = ({ document }: IProps): JSX.Element => {
  const { t } = useTranslation()

  const getDocumentStatusLabel = useMemo(() => {
    switch (document?.status) {
      case 'inline':
        return t('common.documentView.status.inline')
      case 'validated':
        return t('common.documentView.status.validated')
      case 'invalidated':
        return t('common.documentView.status.invalidated')
      case 'required':
        return t('common.documentView.status.required')
      default:
        return t('common.documentView.status.created')
    }
  }, [document?.status])

  return (
    <>
      <Grid item xs={6} className="pb-0">
        <ListItem>
          <ListItemAvatar>
            <Avatar variant="rounded" className="avatarColor primary outline">
              <DescriptionIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <span className="textColorMuted text-small">
                {t('common.documentView.status.label')}
              </span>
            }
            secondary={
              <span className="textColorDark text-normal">
                {document?.status !== undefined && <strong>{getDocumentStatusLabel}</strong>}
              </span>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <ListItem>
          <ListItemAvatar>
            <Avatar variant="rounded" className="avatarColor default outline">
              <EventIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <span className="textColorMuted text-small">
                {t('common.documentView.createdAt')}
              </span>
            }
            secondary={
              <span className="textColorDark text-normal">
                <strong>
                  {undefined !== document?.createdAt
                    ? DateFormatter.formatFrDate(new Date(document?.createdAt))
                    : ''}
                </strong>
              </span>
            }
          />
        </ListItem>
      </Grid>
    </>
  )
}

export default DocumentDetailsInfoComponent
