import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Viewer } from '@react-pdf-viewer/core'

import DocumentHelper from 'app/helpers/document.helper'
import PDFWorker from '../../pdf/pdfWorker.view'

export interface IProps {
  file?: string
}

const ContractDetailsPdfComponent = ({ file }: IProps): JSX.Element => {
  const [url, setUrl] = useState<string | undefined>()

  const getPdf = useCallback(() => {
    let _url = undefined
    if (file !== undefined) {
      let blob = DocumentHelper.base64toBlob(file)
      _url = URL.createObjectURL(blob)
    }
    setUrl(_url)
  }, [file])

  useEffect(() => {
    getPdf()
  }, [file])

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={8}>
        {url !== undefined && (
          <PDFWorker>
            <div
              style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: 'auto'
              }}>
              <Viewer fileUrl={url} />
            </div>
          </PDFWorker>
        )}
      </Grid>
    </Grid>
  )
}

export default ContractDetailsPdfComponent
