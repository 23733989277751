import { useTranslation } from 'react-i18next'
import React from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

import { IQuotation } from 'app/models/quotations.models'

export interface IProps {
  quotation: IQuotation
}

const QuotationDetailsComponent = ({ quotation }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Box my={0} component="p">
        <strong>{t('common.address_to')}</strong> : {quotation!.owner}
      </Box>
      <Box mb={2} mt={0} component="p">
        {quotation!.enterprise}
      </Box>
      <Divider />
      <Box mt={2} mb={0} component="p" color="text.primary">
        <strong>{t('common.your_need')}</strong> :
        {`
            ${t('common.position', { count: quotation!.capacity })}
          `}{' '}
        {t('common.at')} {quotation!.center}
      </Box>
      <Box mt={0} mb={0} component="p" color="text.primary">
        <strong>{t('common.begin_date')}</strong> : {quotation!.start}.
      </Box>
      <Box mt={0} mb={2} component="p" color="text.primary">
        <strong>{t('common.quotation_valid_until')}</strong> : {quotation!.dueDate}.
      </Box>
    </>
  )
}

export default QuotationDetailsComponent
