import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import fileDownload from 'js-file-download'

import DocumentHelper from 'app/helpers/document.helper'
import { useOnView } from 'app/providers/onview.provider'
import { useOnLogin } from 'app/providers/onlogin.provider'

export interface IProps {
  id: number
}

const DocumentDetailsActionComponent = ({ id }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { currentEnterprise } = useOnLogin()
  const { documentDownloadUseMutation } = useOnView()

  const download = useCallback(async (): Promise<void> => {
    let _file = await documentDownloadUseMutation?.mutateAsync({
      enterpriseId: currentEnterprise,
      id: id
    })
    if (undefined !== _file) {
      let blob = DocumentHelper.base64toBlob(_file.content, _file.mimetype)
      fileDownload(blob, _file.filename, _file.mimetype)
    }
  }, [currentEnterprise, id])

  return (
    <Box textAlign="center" component="div" p={2} mt={2} mb={2}>
      <button type="button" className="button w-174" onClick={() => download()}>
        {t('common.global.download')}
      </button>
    </Box>
  )
}

export default DocumentDetailsActionComponent
