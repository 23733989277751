import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, CardMedia } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import Conciergerie from 'images/home/image-2.jpg'

import Session from 'app/libraries/session.lib'
import { IRole } from 'app/models/role.model'
import { useOnLogin } from 'app/providers/onlogin.provider'

const ConciergeCardComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { roles, currentEnterprise } = useOnLogin()

  const [role, setRole] = useState<IRole | undefined>()

  const isConciergeDown = useMemo(() => {
    return process.env.REACT_APP_CONCIERGE_DOWN === '1'
  }, [])

  useEffect(() => {
    if (roles !== undefined) {
      let [role] = roles.filter((role: IRole) => role.entreprise_id === currentEnterprise)
      setRole(role)
    } else {
      setRole(undefined)
    }
  }, [roles])

  return (
    <Card className="MyCardHomeroot">
      <CardMedia
        component="img"
        alt={t('common.homeView.concierge.imageAlt')}
        height="150"
        image={Conciergerie}
      />
      <CardContent>
        <div className="card-header">
          <Typography variant="h2" color="primary">
            {t('common.homeView.concierge.title')}
          </Typography>
        </div>
        <div className="pt-15">
          {role !== undefined && role.conciergerie == 1 && isConciergeDown && (
            <p>{t('common.homeView.concierge.status.maintenance')}</p>
          )}
          {role !== undefined && role.conciergerie == 1 && !isConciergeDown && (
            <a
              href={`${process.env.REACT_APP_API_URL}/sso/${
                role.entreprise_id
              }/concierges?accesstoken=${Session.getToken()}`}
              className="button"
              rel="noreferrer"
              target="_blank">
              {t('common.homeView.concierge.status.access')}
            </a>
          )}
          {(role === undefined || role.conciergerie == 0) && (
            <>
              <p>{t('common.homeView.concierge.status.noAccess')}</p>
              <p className="textColorInfo">
                <strong>{t('common.homeView.concierge.ask')}</strong>
              </p>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default ConciergeCardComponent
