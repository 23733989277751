import { checkResponse, getRequestInit, getUrl, postRequestInit } from 'app/api/api'
import {
  IPayment,
  IPaymentIntent,
  IPaymentMethod,
  IRedirectFlowGc
} from 'app/models/payments.models'

export const PaymentMethodListQuery = async (token: string): Promise<IPaymentMethod[]> => {
  return checkResponse(
    await fetch(
      getUrl(`v2/invoices/listpaymentmethods?token=${encodeURIComponent(token)}`, true),
      getRequestInit(false)
    )
  )
}

export const PaymentGetQuery = async (token: string): Promise<IPayment> => {
  return checkResponse(
    await fetch(
      getUrl(`v2/invoices/payment?token=${encodeURIComponent(token)}`, true),
      getRequestInit(false)
    )
  )
}

export const GcMandateLinkGetQuery = async (token: string): Promise<IRedirectFlowGc> => {
  return checkResponse(
    await fetch(
      getUrl(`v2/invoices/linkmandategc?token=${encodeURIComponent(token)}`, true),
      getRequestInit(false)
    )
  )
}

export const GcMandateConnectedLinkGetQuery = async (enterpriseId: number, centerId: number): Promise<IRedirectFlowGc> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterpriseId}/centers/${centerId}/linkmandategc`),
      getRequestInit(true)
    )
  )
}

export const CreatePaymentIntentQuery = async (
  token: string,
  paymentMethod: string,
  email: string
): Promise<any> => {
  return checkResponse(
    await fetch(
      getUrl(`v2/invoices/payment_intent`),
      postRequestInit(
        false,
        JSON.stringify({
          token: token,
          paymentMethod: paymentMethod,
          email: email
        })
      )
    )
  )
}

export const CompletePaymentCbQuery = async (
  token: string,
  paymentIntent: IPaymentIntent
): Promise<any> => {
  return checkResponse(
    await fetch(
      getUrl(`v2/invoices/complete_payment_cb`),
      postRequestInit(
        false,
        JSON.stringify({
          token: token,
          amount: paymentIntent.amount,
          partner_payment_id: paymentIntent.id,
          partner_payment_method_id: paymentIntent.payment_method
        })
      )
    )
  )
}
