import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

const data = [
  {level:100, price:149, paris_price:219, text:'common.quotationView.nomade.catalog.text100' },
  {level:105, price:189, paris_price:279, text:'common.quotationView.nomade.catalog.text105' },
  {level:110, price:299, paris_price:439, text:'common.quotationView.nomade.catalog.text110' },
  {level:200, price:479, paris_price:699, text:'common.quotationView.nomade.catalog.text200' },
]

const QuotationNomadeCatalogComponent = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="mb-20 mt-20">
      <Grid container spacing={2} className="mb-20 mt-20">
        {data.map((d: { price: number,  text: string, level: number, paris_price: number }, key: number) => {
          return (
            <Grid item md={3} xs={12} key={key}>
              <div className="List-nomade">
                <div className="Header-list-nomade">
                  <p>{t('common.quotationView.nomade.catalog.title', {level: d.level})}</p>
                </div>
                <div className="Price-list-nomade green">
                  <p className="Price-list-nomade-where mb-0">{t('common.quotationView.nomade.catalog.in_region')}</p>
                   <p
                  dangerouslySetInnerHTML={{
                    __html: t('common.quotationView.nomade.catalog.price', {price: d.price})
                  }}
                   ></p>
                </div>
                <div className="Price-list-nomade dark">
                  <p className="Price-list-nomade-where">{t('common.quotationView.nomade.catalog.in_france')}</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('common.quotationView.nomade.catalog.price', {price: d.paris_price})
                    }}
                  ></p>
                </div>
                <div className="Text-list-nomade">
                  <p>{t(d.text)}</p>
                </div>
              </div>
            </Grid>
          )
        })}
        </Grid>
    </div>
  )
}

export default QuotationNomadeCatalogComponent
