import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'

import Typography from '@material-ui/core/Typography'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined'

import Center from 'images/home/image-1.jpg'

import { useOnLogin } from 'app/providers/onlogin.provider'
import { ICenter } from 'app/models/centers.model'

const CenterCardComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { centers, listCenterUseQuery, userCenterId } = useOnLogin()

  const [center, setCenter] = useState<ICenter | undefined>()

  useEffect(() => {
    if (undefined !== centers && 0 !== centers.length) {
      setCenter(centers.filter((c: ICenter) => c.id === userCenterId)[0])
    }
  }, [centers, userCenterId])

  return (
    <Card className="MyCardHomeroot">
      <CardMedia
        component="img"
        alt={t('common.homeView.center.imageAlt')}
        height="150"
        image={Center}
      />
      <Typography component="div" className="MyCardHomeroot-inner-text">
        {t('common.homeView.center.title')}
      </Typography>
      <CardContent className="centerCard">
        {!listCenterUseQuery?.isLoading && (
          <>
            <div className="card-header">
              <Typography variant="h2" color="primary">
                {center?.name}
              </Typography>
            </div>
            <div className="pt-15">
              <List className="pt-15 px-0" dense={true}>
                <ListItem className="px-0 pt-0">
                  <ListItemIcon>
                    <RoomOutlinedIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={center?.address} />
                </ListItem>
                <ListItem className="px-0 pt-0">
                  <ListItemIcon>
                    <MailOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={center?.email} />
                </ListItem>
                <ListItem className="px-0 pt-0">
                  <ListItemIcon>
                    <PhoneOutlinedIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={center?.phone} />
                </ListItem>
              </List>
            </div>
          </>
        )}
        {listCenterUseQuery?.isLoading && (
          <div className="myCardLoader">
            <CircularProgress color="secondary" />
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default CenterCardComponent
