import {DocumentTypeEnum} from "../enums/document.enum";

export default class DocumentHelper {
  static getLabel(type: string): string {
    switch (type) {
      case DocumentTypeEnum.CONTRACT:
        return 'Contrat'
      case DocumentTypeEnum.QUOTATION:
        return 'Devis'
      case DocumentTypeEnum.KBIS:
        return 'KBIS'
      case DocumentTypeEnum.BANKACCOUNTDETAILS:
        return 'BIC/IBAN'
      case DocumentTypeEnum.IDENTITYCARD:
        return 'Carte d\'identité'
      case DocumentTypeEnum.OTHER:
        return 'Autres'
      default:
        return type
    }
  }

  static base64toBlob(data: string, mimetype = 'application/pdf'): Blob {
    const bytes = atob(data)
    let length = bytes.length
    let out = new Uint8Array(length)

    while (length--) {
      out[length] = bytes.charCodeAt(length)
    }

    return new Blob([out], { type: mimetype })
  }
}
