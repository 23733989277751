import React, { ReactNode, useEffect } from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { GlobalWorkerOptions, version } from 'pdfjs-dist/build/pdf';

interface PDFWorkerProps {
  children: ReactNode;
}

const PDFWorker: React.FC<PDFWorkerProps> = ({ children }) => {
  useEffect(() => {
    GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;
  }, []);
  return <Worker workerUrl={GlobalWorkerOptions.workerSrc}>{children}</Worker>;
};
export default PDFWorker;