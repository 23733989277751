import React, { useMemo } from 'react'

import { IDocumentDetail } from 'app/models/documents.model'
import { useTranslation } from 'react-i18next'
import { ListItem, ListItemText } from '@material-ui/core'

export interface IProps {
  document?: IDocumentDetail
}

const DocumentDetailsHeaderComponent = ({ document }: IProps): JSX.Element => {
  const { t } = useTranslation()

  const documentTypeLabel = useMemo(() => {
    switch (document?.type) {
      case 'kbis':
        return t('common.documentListView.type.kbis')
      case 'bank account details':
        return t('common.documentListView.type.bankAccountDetails')
      case 'identity card':
        return t('common.documentListView.type.identityCard')
      default:
        return t('common.documentListView.type.other')
    }
  }, [document?.type])

  return (
    <ListItem className="primary">
      <ListItemText primary={document?.name} secondary={documentTypeLabel} />
    </ListItem>
  )
}

export default DocumentDetailsHeaderComponent
