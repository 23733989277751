import { useTranslation } from 'react-i18next'
import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import MailIcon from '@material-ui/icons/Mail'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PhoneIcon from '@material-ui/icons/Phone'

import { IQuotation } from 'app/models/quotations.models'

export interface IProps {
  quotation: IQuotation
}

const QuotationContactComponent = ({ quotation }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="myCard">
      <div className="card-header">
        <Typography variant="h2" color="primary">
          {t('common.your_contact_flexo')}
        </Typography>
      </div>
      <div className="card-content">
        <Box my={0} component="p" color="text.primary">
          <strong>{quotation.contact!.name}</strong>
        </Box>
        <Box mb={0} component="p" color="text.primary">
          {quotation.contact!.phone !== undefined && quotation.contact!.phone !== null && (
            <Box my={0} component="p" className="line-contact">
              <Avatar className="avatarColor">
                <PhoneIcon />
              </Avatar>
              <span>{quotation.contact!.phone}</span>
            </Box>
          )}
          <Box component="p" className="line-contact">
            <Avatar className="avatarColor">
              <MailIcon />
            </Avatar>
            <span>{quotation.contact!.email}</span>
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default QuotationContactComponent
