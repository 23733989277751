import React from 'react'
import { useTranslation } from 'react-i18next'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Accordion from '@material-ui/core/Accordion'

import { IQuotation } from 'app/models/quotations.models'

import QuotationDetailsComponent from 'modules/quotations/components/header/quotationDetails.component'

export interface IProps {
  quotation?: IQuotation
}

const QuotationAccordionComponent = ({ quotation }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Accordion className="accordion-quotations-details-block">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className="accordion-quotations-details-header">
        <Typography variant="h2" color="primary">
          {t('common.your_quotation_flexo')} {quotation!.center}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="accordion-quotations-details-body">
        <QuotationDetailsComponent quotation={quotation!} />
      </AccordionDetails>
    </Accordion>
  )
}

export default QuotationAccordionComponent
