import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Avatar from '@material-ui/core/Avatar'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Collapse from '@material-ui/core/Collapse'
import {List, ListItem, ListItemText} from '@material-ui/core'
import Box from '@material-ui/core/Box'

import DateFormatter from 'app/formatters/date.formatter'
import {IRequest} from 'app/models/requests.model'

export interface IProps {
    index: number
    openCollapseStatus: number[]
    request: IRequest
    setOpen: (index: number) => void
}

const ICONS = {
    'internet': "wifi",
    'heating_air_conditioning': "wb_sunny",
    'cleaning': "face",
    'building': "apartment",
    'sanitary': "wc",
    'supplies': "print",
    'access_control': "meeting_room",
    'client_space': "client_space",
    'default': "dynamic_feed",
}

const HelpdeskListItemComponent = ({index, openCollapseStatus, request, setOpen}: IProps): JSX.Element => {
    const {t} = useTranslation()
    const [Status, setStatus] = useState<string>()

    useEffect(() => {
        const status = request.status.split('_')[2];
        switch (status) {
            case 'inprogress':
            case 'created':
            case 'treated':
            case 'cancelled':
                setStatus(status)
                break
            default:
                setStatus('default')
                break
        }
    }, [request.status])

    return (
        <div className='card-list mb-20' key={index}>
            <div
                onClick={() => setOpen(index)}
                aria-expanded={openCollapseStatus.includes(index)}
                className={`${Status} ${openCollapseStatus.includes(index) ? 'card-list-header open' : 'card-list-header'}`}
            >
                <ExpandMoreIcon fontSize='medium' className='arrow'/>
                <div className='block-list icon'>
                    <Avatar className='avatarColorHelpdesk'>
                        <Icon className='avatarSizeHelpdesk'>{
                            ICONS[request.category as keyof typeof ICONS] ?? ICONS['default']
                        }</Icon>
                    </Avatar>
                </div>
                <div className='block-list date'>
                    <Typography
                        style={{fontWeight: "bold"}}
                        paragraph={true}>
                        {DateFormatter.formatFrDate(request.createdAt)}
                    </Typography>
                </div>
                <div className='block-list category'>
                    <Typography paragraph={true}>{t('common.customer_services.' + request.category)} <ChevronRightIcon
                        fontSize='small'/> {t('common.customer_services.' + request.subcategory)}</Typography>
                </div>
                <div className='fixFloat'></div>
            </div>
            <Collapse in={openCollapseStatus.includes(index)}>
                <div className='card-list-body'>
                    <List className={Status}>
                        <ListItem dense={true} className='py-0'>
                            <ListItemText
                                primary={<Box><span className='label'>{t('common.number')} :</span> {request.id} <span
                                    className='label_card'>{t('common.' + request.status.replaceAll('_', '.'))}</span></Box>}/>
                        </ListItem>
                    </List>
                    {request.comment !== null && (
                        <div className='block-message'>
                            <div className='block-message-content'>
                                {request.comment}
                            </div>
                        </div>
                    )}
                </div>
            </Collapse>
        </div>
    )
}

export default HelpdeskListItemComponent
