export enum BookingSelectedMonthEnum {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
  ALLMONTHS = 12,
}

export enum ServiceTypeEnum {
  FULL_DESKTOP = 1,
  FULL_COWORKING = 2,
  COWORKING = 3,
  MEETING = 4,
  OPEN_DESKTOP = 5,
  DOMICILIATION = 6,
  ADMINISTRATIVE = 7,
  IT = 8,
  FOOD = 9,
  CUSTOM = 10,
  PARKING = 11,
  WORKS_EQUIPMENT = 12,
  STORAGE = 13,
}

export enum StatusEnum {
  CREATED = 1,
  CONFIRMED = 2,
  CANCELLED = 3
}