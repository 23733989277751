import React, { ChangeEvent, Fragment, useCallback, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import 'modules/login/css/login.scss'
import { useOnLogin } from 'app/providers/onlogin.provider'
import {IMAGES} from "app/constants/images";

const Login = (props: { isDarkMode: boolean }): JSX.Element => {
  const { t } = useTranslation()
  const { connectionUseMutation } = useOnLogin()
  const [login, setLogin] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const onConnect = useCallback(async () => {
    let params = new URLSearchParams()
    params.append('login', login)
    params.append('password', password)
    connectionUseMutation?.mutateAsync(params.toString())
  }, [login, password])

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="xs" className={props.isDarkMode ? "bkContainerLoginDark" : "bkContainerLogin"} >
        <img src={props.isDarkMode ? IMAGES.LOGO_LOGIN_WHITE : IMAGES.LOGO_LOGIN} className="logo" alt="" />
        <form className="login-form">
          <Typography component="h1" variant="h5" style={{ fontWeight: '600' }} align="center">
            {t('common.my_client_space')}
          </Typography>
          <TextField
            variant="standard"
            margin="normal"
            type="email"
            required
            fullWidth
            id="email"
            label="E-mail"
            autoComplete="email"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setLogin(event.target.value)}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
          />
          <Box mt={4} mb={2}>
            <Button
              fullWidth
              className="btnLogin"
              disableElevation
              variant="contained"
              type="button"
              color={'primary'}
              disabled={login === '' || password === ''}
              onClick={onConnect}>
              {connectionUseMutation?.isLoading && <CircularProgress color="primary" size={25} />}
              {!connectionUseMutation?.isLoading && t('common.connect_me')}
            </Button>
          </Box>
          <Box className="text-center">
            <Link to="/forgotten_password" className="textColorPrimary">
              {t('common.credentials.forgotten')}
            </Link>
          </Box>
        </form>
      </Container>
    </Fragment>
  )
}

export default Login
