import React, {Fragment, useCallback, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import {Link} from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import {ListItem, ListItemAvatar, ListItemText} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import GetAppIcon from '@material-ui/icons/GetApp'
import EuroIcon from '@material-ui/icons/Euro'
import EventIcon from '@material-ui/icons/Event'
import Divider from '@material-ui/core/Divider'
import {useApp} from 'app/providers/app.provider'
import {useTranslation} from 'react-i18next'
import {useOnView} from 'app/providers/onview.provider'
import {useEffect} from 'react'
import { ICredit} from 'app/models/credits.models'
import DocumentHelper from 'app/helpers/document.helper'
import { Viewer } from '@react-pdf-viewer/core'
import CurrencyFormatter from 'app/formatters/currency.formatter'
import DateFormatter from 'app/formatters/date.formatter'
import CreditStatus from 'app/components/CreditStatus.view'
import PDFWorker from '../../pdf/pdfWorker.view'

const Credit = (): JSX.Element => {
  const { t } = useTranslation()
  const { isConnected } = useApp()
  const { creditGetUseMutation } = useOnView()
  const [credit, setCredit] = useState<ICredit | undefined>()
  const [url, setUrl] = useState<string>('')
  const dateNow = new Date()

  const initCredit = useCallback(async (creditId: number) => {
    const _credit = await creditGetUseMutation?.mutateAsync(creditId)
    if(_credit !== undefined) {
      setCredit(_credit)
    }

    if(_credit !== undefined && _credit.file) {
      const _blob = DocumentHelper.base64toBlob(_credit.file)
      let _url = URL.createObjectURL(_blob)
      setUrl(_url)
    }
  }, [creditGetUseMutation])

  useEffect(() => {
    let url = window.location.pathname
    const matches = url.match(/\/([0-9]+)$/)
    if(matches) {
      initCredit(Number(matches[1])).then()
    }
  }, [])

  return (
    <>
      {credit !== undefined &&
      <Grid container spacing={3} className='quotationscard-container'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <Box p={2}>
                <div className='myCard payment_details'>
                  <div className='card-header list-header'>
                    {isConnected && (
                      <Link to='/enterprise/accountingdocuments/list' color='primary' className='icon-button'>
                        <ArrowBackIcon fontSize='small' />
                      </Link>
                    )}
                    <Typography variant='h2' color='primary'>
                      {t('common.my_credit')} {credit.creditReference}
                    </Typography>
                  </div>
                  <div className='card-content'>
                    <Grid container spacing={3}>
                      <Grid item xl={3} md={4} xs={12}>
                        <ListItem className='default'>
                          <ListItemText
                            primary={
                              <div className='listItem-header-payment'>
                                {credit.centerName}
                                <a
                                  className=''
                                  href={url}
                                  download={`${credit.creditReference}.pdf`}
                                  title={t('common.download')}
                                >
                                  <Avatar className='avatarColor small primary'><GetAppIcon
                                    fontSize='small' /></Avatar>
                                </a>
                              </div>
                            }
                          />
                        </ListItem>
                        <Grid container spacing={3}>
                          <Grid item xs={6} className='pb-0'>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar variant='rounded' className='avatarColor default outline'>
                                  <EuroIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Fragment>
                                    <span className='textColorMuted text-small'>{t('common.amount')}</span>
                                  </Fragment>
                                }
                                secondary={
                                  <Fragment>
                                      <span
                                        className='textColorDark text-normal'><strong>{CurrencyFormatter.formatFr(credit.dueAmount)}</strong></span>
                                  </Fragment>
                                }
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={6} className='pb-0'>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar variant='rounded' className='avatarColor default outline'>
                                  <EventIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <>
                                    <span className='textColorMuted text-small'>{t('common.state')}</span>
                                  </>
                                }
                                secondary={
                                  <Fragment>
                                   <span className='textColorDark text-normal'><strong><CreditStatus state={credit.creditStatus} /></strong></span>
                                    {credit.spentAt != null && dateNow < new Date(credit.spentAt) && (
                                      <>
                                        <br />
                                        {t('common.payment_on')} : <strong>{DateFormatter.formatFrDateFromString(credit.spentAt)}</strong>
                                      </>
                                    )}
                                    {credit.spentAt != null && dateNow >= new Date(credit.spentAt) && (
                                      <>
                                        <br />
                                        {t('common.payment_payed_on')} : <strong>{DateFormatter.formatFrDateFromString(credit.spentAt)}</strong>
                                      </>
                                    )}
                                  </Fragment>
                                }
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={12} className='pt-0'>
                            <Divider></Divider>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xl={9} md={8} xs={12}>
                        <PDFWorker>
                          <div
                            style={{
                              height: 'auto',
                              width: 'auto'
                            }}
                          >
                            <Viewer fileUrl={url} />
                          </div>
                        </PDFWorker>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      }
    </>
  )
}

export default Credit