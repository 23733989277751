import {useOnLogin} from '../../../app/providers/onlogin.provider'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import React, {useCallback, useEffect, useState} from 'react'
import {IcenterSelect} from '../../../app/models/centers.model'
import {useTranslation} from 'react-i18next'

interface Props  {
  setCurrentCenter: (centerReference: string) => void
}

const HelpdeskListCenterComponent = ({setCurrentCenter}: Props): JSX.Element => {
  const { t } = useTranslation()
  const {centers, userCenterId} = useOnLogin()
  const [currentCenterReference, setCurrentCenterReference] = useState<string>('')

  useEffect(() => {
    const currentCenter = centers?.filter(({id}) => id === userCenterId)
    if(currentCenter !== undefined) {
      const centerReference = currentCenter[0].reference
      setCurrentCenterReference(centerReference)
      setCurrentCenter(centerReference)
    }
  }, [centers])

  const handleCenterChange = useCallback((event: any) => {
    const newCenter = event.target.value
    setCurrentCenter(newCenter)
    setCurrentCenterReference(newCenter)
  }, [currentCenterReference])

  return (
      <FormControl margin="normal" variant="standard" className="labelFormControl" fullWidth>
        <InputLabel>
          {t('common.helpdesk_center')}
        </InputLabel>
        <Select
          native
          value={currentCenterReference}
          onChange={handleCenterChange}
        >
          {centers?.map((center: IcenterSelect, key: number) => (
            <option key={key} value={center.reference}>
              {center.name}
            </option>
          ))}
        </Select>
      </FormControl>
  )

}

export default HelpdeskListCenterComponent