import React from 'react'
import { IQuotationListFilter } from 'app/types/quotations'
import { Select } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export interface IProps {
  filters: IQuotationListFilter
  onChangeFilters: (filters: IQuotationListFilter) => void
  states: Array<{value: string, label: string}>
}

const QuotationListFiltersComponent = ({ filters, onChangeFilters, states }: IProps): JSX.Element => {

  const { t } = useTranslation()

  return (
    <div className="pt-30">
      <Select
        className="filter mr-xs mb-xs"
        native
        variant="standard"
        value={filters.state}
        onChange={(e) => onChangeFilters({ state: String(e.target.value) })}>
        <option value="">{t('common.all_states')}</option>
        {states.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          )
        })}
      </Select>
    </div>
  )
}

export default QuotationListFiltersComponent
