import React from 'react'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'

import { IMember } from 'app/models/members.model'

export interface IProps {
  member: IMember
}

const MemberListItemComponent = ({ member }: IProps): JSX.Element => (
  <ListItem>
    <ListItemAvatar>
      <Avatar variant="rounded" className="avatarColor primary-light">
        <PersonIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={`${member.firstname} ${member.lastname}`}
      secondary={
        <>
          <div>{member.job}</div>
          <div>{member.email}</div>
          <div>{member.phone}</div>
        </>
      }
    />
  </ListItem>
)

export default MemberListItemComponent
