import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'

import { useOnLogin } from 'app/providers/onlogin.provider'

const PendingContractButtonComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { pendingContracts } = useOnLogin()

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box pb={2} className='flex-align-center'>
          <Link
            to='/enterprise/contracts/list'
            color='primary'
            className='button bg-secondary align-center'
          >
            {t('common.pendingContract.button.label', { count: pendingContracts?.length })}
          </Link>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PendingContractButtonComponent
