import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '@material-ui/core'
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export interface IProps {
  redirectUrlGc: string
}

const ContractPendingStep4Component = ({ redirectUrlGc }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className='card'>
      <div className='card-content mb-20'>
        <CheckCircleIcon className='statusIcon textColorSuccess' fontSize='large' />
        <h2>{t('common.pendingContract.validatedContract')}</h2>
        <Divider className="mt-20 mb-20"></Divider>
        {redirectUrlGc !== '' && (
          <span
            dangerouslySetInnerHTML={{
              __html: t('common.sepa_text')
            }}
          />
        )}
      </div>

      <div className='card-actions'>
        {redirectUrlGc !== '' && (
          <>
            <button
              type='button'
              className='button mb-xs bg-secondary large'
              onClick={() => window.location.href = redirectUrlGc}
            >
              {t('common.sepa_add')}
            </button>
            <br />
          </>
        )}
        <a href={'/enterprise/contracts/list'}>{t('common.pendingContract.continue')}</a>
      </div>
    </div>
  )
}

export default ContractPendingStep4Component
