import React, { Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Lens from '@material-ui/icons/Lens'

import {CreditStatusEnum} from 'app/enums/credit.enum'

interface Props extends WithTranslation {
  state: CreditStatusEnum;
  t: (key: string) => string;
}

class CreditStatus extends React.Component<Props> {
  public render(): JSX.Element {
    switch (this.props.state) {
      case CreditStatusEnum.DRAFT:
        return (<Fragment><Lens className='smallIcon' color='secondary' /> {this.props.t('common.draft')}</Fragment>)
      case CreditStatusEnum.TO_BALANCE:
        return (<Fragment>{this.props.t('common.stored')}</Fragment>)
      case CreditStatusEnum.PARTIAL_BALANCED:
        return (<Fragment>{this.props.t('common.partial_spend')}</Fragment>)
      case CreditStatusEnum.BALANCED:
        return (<Fragment>{this.props.t('common.spent')}</Fragment>)
      case CreditStatusEnum.CANCELLED:
        return (<Fragment><Lens className='smallIcon textColorSecondaryDark' /> {this.props.t('common.cancelled')}</Fragment>)
      default:
        return (<Fragment />)
    }
  }
}

export default withTranslation()(CreditStatus)