import i18next from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'

i18next
  .use(resourcesToBackend((language, namespace, callback) => {
    import(`./locales/fr/common.json`)
      .then((resources) => {
        callback(null, resources)
      })
      .catch((error) => {
        callback(error, null)
      })
  }))
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'fr',
    react: {
      useSuspense: false
    }
  }).then()

export default i18next