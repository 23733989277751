import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { ISolution } from 'app/models/quotations.models'
import QuotationNomadeDetailsComponent from 'modules/quotations/components/nomade/quotationNomadeDetails.component'

export interface IProps {
  solution: ISolution
  solutionKey: number
  isMobile: boolean
  isRecap: boolean
}

const QuotationNomadeAccordionComponent = ({
  solution,
  solutionKey,
  isMobile,
  isRecap
}: IProps): JSX.Element => (
  <Grid item xs={12} sm={6} md={3} key={solutionKey}>
    <Accordion className="accordion-solution-block" key={solutionKey} defaultExpanded={!isMobile}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={`accordion-solution-header ${isRecap ? 'yellowLight' : 'primary'}`}>
        <Typography className="title-accordion-solution-block">
          <span>
            {solution.reductionPrice > 0
              ? solution.reductionPrice.toFixed(2)
              : solution.price.toFixed(2)}{' '}
            €
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="accordion-solution-body">
        <QuotationNomadeDetailsComponent solution={solution} />
      </AccordionDetails>
    </Accordion>
  </Grid>
)

export default QuotationNomadeAccordionComponent
