import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'

import IconServicesInclusCharges from 'images/quotations/icon/services/icon-services-inclus-charges.jpg'
import IconServicesInclusDomiciliation from 'images/quotations/icon/services/icon-services-inclus-domiciliation.jpg'
import IconServicesInclusSecurite from 'images/quotations/icon/services/icon-services-inclus-securite.jpg'
import IconServicesInclusOtherService from 'images/quotations/icon/services/icon-services-autre-service.jpg'
import IconServicesInclusMeetingRoom from 'images/quotations/icon/services/icon-services-salle-de-reunion.jpg'
import IconServicesInclusSpaceShare from 'images/quotations/icon/services/icon-services-inclus-espace-partage.jpg'
import IconServicesInclusConnexion from 'images/quotations/icon/services/icon-services-inclus-connection.jpg'
import IconServicesInclusReception from 'images/quotations/icon/services/icon-services-inclus-service-de-reception.jpg'
import IconServicesInclusFurniture from 'images/quotations/icon/services/icon-services-inclus-mobiliers.jpg'

export interface IProps {
  hidden: boolean
}

const data = [
  { image: IconServicesInclusCharges, title: 'common.quotationView.included.charges' },
  { image: IconServicesInclusDomiciliation, title: 'common.quotationView.included.domiciliation' },
  { image: IconServicesInclusSecurite, title: 'common.quotationView.included.secureAccess' },
  { image: IconServicesInclusOtherService, title: 'common.quotationView.included.concierge' },
  { image: IconServicesInclusMeetingRoom, title: 'common.quotationView.included.community' },
  { image: IconServicesInclusSpaceShare, title: 'common.quotationView.included.access' },
  { image: IconServicesInclusConnexion, title: 'common.quotationView.included.network' },
  { image: IconServicesInclusReception, title: 'common.quotationView.included.reception' },
  { image: IconServicesInclusFurniture, title: 'common.quotationView.included.furniture' }
]

const QuotationCoworkingIncludedComponent = ({ hidden }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div role="tabpanel" hidden={hidden} id="quotations-tabpanel-2">
      <Grid container spacing={4}>
        {data.map((d: { image: any; title: string }, key: number) => {
          return (
            <Grid item md={4} xs={6} key={key}>
              <img src={d.image} className="IconInclusives" alt={t(d.title)} />
              <Box mt={1} fontSize={14} component="p" color="text.primary" className="text-center">
                {t(d.title)}
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default QuotationCoworkingIncludedComponent
