import React, {ChangeEvent,useState} from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'

import Logo from 'images/common/logo_login.png'
import Logo_white from 'images/common/logo_white.png'
import {useApp} from 'app/providers/app.provider'
import { IMAGES } from '../../../app/constants/images'

const ForgottenPasswordView = (props: { isDarkMode: boolean }): JSX.Element => {
  const {t} = useTranslation()
  const {forgottenPasswordUseMutation} = useApp()
  const [login, setLogin] = useState<string>('')

  return (
    <>
      <CssBaseline/>
      <Container maxWidth='xs' className={"bkContainerLogin"}>
        <img src={props.isDarkMode ? IMAGES.LOGO_LOGIN_WHITE : IMAGES.LOGO_LOGIN} className="logo" alt="" />
        <form className='login-form'>
          <Typography component="h1" variant="h5" style={{ fontWeight: '600' }} align="center">{t('common.credentials.forgotten')}</Typography>
          {
            (
              !forgottenPasswordUseMutation?.isSuccess &&
              !forgottenPasswordUseMutation?.isError
            ) && (
              <>
                <Typography
                  component='p'
                  variant='subtitle2'
                  align='center'
                  className='mt-20'
                >
                  {t('common.credentials.description')}
                </Typography>
                <TextField
                  variant='standard'
                  margin='normal'
                  type='email'
                  required
                  fullWidth
                  id='email'
                  label='E-mail'
                  autoComplete='email'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setLogin(event.target.value)}
                />
              </>
            )
          }
          <Typography
            component='p'
            variant='subtitle2'
            align='center'
            className='mt-20'
          >
            {
              (
                forgottenPasswordUseMutation?.isSuccess ||
                forgottenPasswordUseMutation?.isError
              ) &&
              t('common.credentials.message', {email: login})
            }
          </Typography>
          <Box mt={4} mb={2}>
            {
              (
                !forgottenPasswordUseMutation?.isSuccess &&
                !forgottenPasswordUseMutation?.isError
              ) &&
                <Button
                    fullWidth
                    className='btnLogin'
                    disableElevation
                    variant='contained'
                    type='button'
                    color='primary'
                    disabled={login === '' || forgottenPasswordUseMutation?.isLoading}
                    onClick={() => forgottenPasswordUseMutation?.mutateAsync(login)}>
                  {forgottenPasswordUseMutation?.isLoading && <CircularProgress color='primary' size={25}/>}
                  {!forgottenPasswordUseMutation?.isLoading && t('common.credentials.send')}
                </Button>
            }

          </Box>
          <Box className='text-center'>
            <Link to='/login' className='textColorPrimary'>{t('common.credentials.back')}</Link>
          </Box>
        </form>
      </Container>
    </>
  )
}

export default ForgottenPasswordView
