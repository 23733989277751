import { useTranslation } from 'react-i18next'
import React from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import TableBody from '@material-ui/core/TableBody'

export interface IProps {
  hidden: boolean
  index: number
}

const data = [
  {
    section: 'common.quotationView.options.it.title',
    subsection: 'common.quotationView.options.it.subtitle',
    items: [
      {
        title: 'common.quotationView.options.it.items.devices',
        price: 29,
        fees: 1,
        border: true,
        background: undefined,
        trad: 'priceByDevice'
      },{
        title: 'common.quotationView.options.it.items.vlan',
        price: 29,
        fees: 1,
        border: false,
        background: 'bgGrey',
        trad: 'price'
      },
      {
        title: 'common.quotationView.options.it.items.wifi',
        price: 29,
        fees: 1,
        border: true,
        background: 'bgGrey',
        trad: 'priceStartTo'
      },
      {
        title: 'common.quotationView.options.it.items.vpn',
        price: 49,
        fees: 3,
        border: false,
        background: undefined,
        trad: 'price'
      },
      {
        title: 'common.quotationView.options.it.items.ipPublic',
        price: 19,
        fees: 1,
        border: false,
        background: undefined,
        trad: 'price'
      },
      {
        title: 'common.quotationView.options.it.items.urack',
        price: 49,
        fees: 4,
        border: true,
        background: undefined,
        trad: 'price'
      },
      {
        title: 'common.quotationView.options.it.items.engineering',
        price: 25,
        fees: undefined,
        border: false,
        background: 'bgGrey',
        trad: 'price15NoFees'
      }
    ]
  },
  {
    section: 'common.quotationView.options.bandwidth.title',
    subsection: 'common.quotationView.options.bandwidth.subtitle',
    items: [
      {
        title: 'common.quotationView.options.bandwidth.items.10',
        price: 298,
        fees: 2,
        border: false,
        background: undefined,
        trad: 'price'
      },
      {
        title: 'common.quotationView.options.bandwidth.items.20',
        price: 447,
        fees: 2,
        border: false,
        background: undefined,
        trad: 'price'
      },
      {
        title: 'common.quotationView.options.bandwidth.items.30',
        price: 549,
        fees: 2,
        border: false,
        background: undefined,
        trad: 'price'
      },
      {
        title: 'common.quotationView.options.bandwidth.items.50',
        price: 669,
        fees: 2,
        border: false,
        background: undefined,
        trad: 'price'
      },
      {
        title: 'common.quotationView.options.bandwidth.items.100',
        price: 900,
        fees: 2,
        border: false,
        background: undefined,
        trad: 'price'
      },
      {
        title: 'common.quotationView.options.bandwidth.items.more100',
        border: false,
        background: undefined,
        trad: 'priceOnQuotation'
      }
    ]
  },
  {
    section: 'common.quotationView.options.phone.title',
    subsection: 'common.quotationView.options.phone.subtitle',
    items: [
      {
        title: 'common.quotationView.options.phone.items.country',
        price: 35,
        fees: 1,
        border: false,
        background: undefined,
        trad: 'price'
      }
    ]
  }
]

const QuotationOptionsComponent = ({ hidden, index }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div role="tabpanel" hidden={hidden} id={`quotations-tabpanel-${index}`}>
      {data.map((d, key: number) => {
        return (
          <TableContainer key={key}>
            <Table className="myTableQuotationsOption">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="left">
                    <TrendingFlatIcon />
                    <span>{t(d.section)}</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} align="left">
                    <strong>{t(d.subsection)}</strong>
                  </TableCell>
                </TableRow>
                {d.items.map((i, subKey: number) => {
                  return (
                    <TableRow key={subKey}>
                      <TableCell
                        align="left"
                        className={`${undefined !== i.background ? i.background : ''} ${
                          i.border ? 'border-bottom-yellow' : ''
                        }`}>
                        {t(i.title)}
                      </TableCell>
                      <TableCell
                        className={`textColorPrimary ${
                          undefined !== i.background ? i.background : ''
                        } ${i.border ? 'border-bottom-yellow' : ''}`}
                        align="right">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t(`common.quotationView.options.${i.trad}`, {
                              price: i.price,
                              fees: i.fees
                            })
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )
      })}
      <TableContainer>
        <Table className="myTableQuotationsOption">
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} align="left">
                <span
                  dangerouslySetInnerHTML={{ __html: t('common.quotationView.options.legend') }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default QuotationOptionsComponent
