import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Select from '@material-ui/core/Select'

import { IMemberListFilter } from 'app/types/members.type'

export interface IProps {
  filters?: IMemberListFilter
  onChangeFilters: (filters: IMemberListFilter) => void
}

const MemberListFiltersComponent = ({ filters, onChangeFilters }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="members-list-filters pt-30">
      <Select
        className="filter mb-xs"
        native
        variant="standard"
        defaultValue={filters?.job}
        onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
          onChangeFilters({
            ...filters,
            job: event.target.value === '' ? undefined : String(event.target.value)
          })
        }>
        <option value="">{t('common.members.filters.allJobs')}</option>
        {filters?.jobs?.map((job: string, index: number) => {
          return (
            <option key={index} value={job}>
              {job}
            </option>
          )
        })}
      </Select>
    </div>
  )
}

export default MemberListFiltersComponent
