import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import EuroIcon from '@material-ui/icons/Euro'
import EventIcon from '@material-ui/icons/Event'

import { IContractDetail } from 'app/models/contracts.model'
import DateFormatter from 'app/formatters/date.formatter'
import CurrencyFormatter from 'app/formatters/currency.formatter'

export interface IProps {
  contract?: IContractDetail
}

const ContractDetailsInfoComponent = ({ contract }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Grid item xs={6} className="pb-0">
        <ListItem>
          <ListItemAvatar>
            <Avatar variant="rounded" className="avatarColor default outline">
              <EuroIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<span className="textColorMuted text-small">{t('common.monthly_price')}</span>}
            secondary={
              <span className="textColorDark text-normal">
                {contract?.monthlyPrice !== undefined && (
                  <strong>{CurrencyFormatter.formatFr(contract?.monthlyPrice)}</strong>
                )}
              </span>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <ListItem>
          <ListItemAvatar>
            <Avatar variant="rounded" className="avatarColor default outline">
              <EventIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<span className="textColorMuted text-small">{t('common.start_date')}</span>}
            secondary={
              <span className="textColorDark text-normal">
                <strong>
                  {undefined !== contract?.begin
                    ? DateFormatter.formatFrDate(new Date(contract?.begin))
                    : ''}
                </strong>
              </span>
            }
          />
        </ListItem>
      </Grid>
    </>
  )
}

export default ContractDetailsInfoComponent
