import { checkResponse, getRequestInit, getUrl, postRequestInit } from 'app/api/api'
import { IRequest } from 'app/models/requests.model'
import {HelpdeskCategories} from "app/models/helpdesk.models";

export const ClientRequestListQuery = async (
  enterprise: number,
  individual: number
): Promise<IRequest[]> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/individuals/${individual}/client_request`),
      getRequestInit(true)
    )
  )
}

export const ClientRequestCreateQuery = async (
  enterprise: number,
  individual: number,
  subcategory?: string,
  center?: string,
  comment?: string
): Promise<boolean> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/individuals/${individual}/client_request`),
      postRequestInit(
        true,
        JSON.stringify({
          subcategory: subcategory,
          center: center,
          comment: comment
        })
      )
    )
  )
}

export const ClientRequestCategoriesQuery = async (
    enterprise: number,
    individual: number
): Promise<HelpdeskCategories> => {
    return checkResponse(
        await fetch(
            getUrl(`enterprises/${enterprise}/individuals/${individual}/client_request/categories`),
            getRequestInit(true)
        )
    )
}
