import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, CardMedia } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import FoodSuggestion from 'images/home/image-3.jpg'

const FoodCardComponent = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Card className="MyCardHomeroot">
      <CardMedia
        component="img"
        alt={t('common.homeView.food.imageAlt')}
        height="150"
        image={FoodSuggestion}
      />
      <CardContent>
        <div className="card-header">
          <Typography variant="h2" color="primary">
            {t('common.homeView.food.title')}
          </Typography>
        </div>
        <div className="pt-15">
          <Box component="p" className="mb-0">
            {t('common.homeView.food.description')}
          </Box>
        </div>
        <div className="wrapButton mt-15">
          <a href="/foodcard" className="button">
            {t('common.homeView.food.discover')}
          </a>
        </div>
      </CardContent>
    </Card>
  )
}

export default FoodCardComponent
