import React from 'react'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'

import IconAvantages from 'images/quotations/icon/titre/icon-avantages.jpg'

import { IQuotation } from 'app/models/quotations.models'

export interface IProps {
  hidden: boolean
  quotation: IQuotation
  index: number
}

const QuotationCenterComponent = ({ hidden, quotation, index }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div role="tabpanel" hidden={hidden} id={`quotations-tabpanel-${index}`}>
      <Grid container spacing={4}>
        <Grid item md={7}>
          <img src={quotation.centerImage} className="ImgCenter" alt={t('common.global.center')} />
        </Grid>
        <Grid item md={5}>
          <TableContainer>
            <Table size="small" className="myTableQuotationsCenter">
              <TableHead>
                <TableRow>
                  <TableCell align="right" className="pr10">
                    <img
                      src={IconAvantages}
                      className="IconAvantages"
                      alt={t('common.quotationView.center.more')}
                    />
                  </TableCell>
                  <TableCell className="textColorPrimary" align="left">
                    {t('common.quotationView.center.more')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left" colSpan={2} className="pl25">
                    <Table size="small" className="myTableQuotationsCenter">
                      <TableBody>
                        {quotation.centerConveniences.map((convenience: string, key: number) => {
                          return (
                            <TableRow key={key}>
                              <TableCell align="right" className="pr10">
                                <TrendingFlatIcon />
                              </TableCell>
                              <TableCell align="left">{convenience}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={12}>
          <Box
            fontWeight="fontWeightBold"
            mt={1}
            mb={2}
            textAlign="center"
            component="div"
            className="textColorInfo">
            {quotation.visitLink !== null && quotation.visitLink !== '' && (
              <a
                className="cursor-pointer button"
                rel="noreferrer"
                href={quotation.visitLink}
                target="_blank">
                {t('common.quotationView.center.visit')}
              </a>
            )}
          </Box>
          <Box pt={2} mb={1} component="p" color="text.main">
            <div dangerouslySetInnerHTML={{ __html: quotation.centerDescription }} />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default QuotationCenterComponent
