import {AccountingDocumentStateEnum, AccountingDocumentTypeEnum} from '../enums/accountingDocument.enum'
import i18next from 'i18next'

export default class AccountingDocumentHelper {
  static getLabel(state: AccountingDocumentStateEnum): string {
    switch (state) {
      case AccountingDocumentStateEnum.UNKNOWN:
        return i18next.t('common.unknown')
      case AccountingDocumentStateEnum.DRAFT:
        return i18next.t('common.draft')
      case AccountingDocumentStateEnum.DUE:
        return i18next.t('common.due')
      case AccountingDocumentStateEnum.PARTIALPAYMENT:
        return i18next.t('common.partial_payment')
      case AccountingDocumentStateEnum.PAID:
        return i18next.t('common.paid')
      case AccountingDocumentStateEnum.LATE:
        return i18next.t('common.late')
      case AccountingDocumentStateEnum.CANCELLED:
        return i18next.t('common.cancelled')
      case AccountingDocumentStateEnum.STORED:
        return i18next.t('common.stored')
      case AccountingDocumentStateEnum.PARTIAL_SPEND:
        return i18next.t('common.partial_spend')
      case AccountingDocumentStateEnum.SPENT:
        return i18next.t('common.spent')
      case AccountingDocumentStateEnum.BLOCKED:
        return i18next.t('common.blocked')
    }
  }

  static getLabelType(type: AccountingDocumentTypeEnum): string {
    switch (type) {
      case AccountingDocumentTypeEnum.UNKNOWN:
        return i18next.t('common.unknown')
      case AccountingDocumentTypeEnum.INVOICE:
        return i18next.t('common.invoices')
      case AccountingDocumentTypeEnum.CREDIT:
        return i18next.t('common.credit')
    }
  }
}
