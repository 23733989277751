import { checkResponse, getRequestInit, getUrl, patchRequestInit } from 'app/api/api'
import { IUser } from 'app/models/user.model'
import {IChangePassword} from "../models/account.model";
import { encrypt } from "../libraries/security";

export const ConnectionQuery = async (params: string): Promise<IUser> => {
  return checkResponse(await fetch(getUrl(`v2/connection?${params}`), getRequestInit(false)))
}

export const ForgottenPasswordQuery = async (login: string): Promise<void> => {
  try {
    const response = await fetch(
      getUrl('forgotten_password'),
      patchRequestInit(
        false,
        JSON.stringify({ login: login, token: 'Lw87LEhb4jFZs8WkvW8sXEVbCzvubRMM' })
      )
    )
    return checkResponse(response)
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject()
    })
  }
}

export const ChangePasswordQuery = async (changePassword: IChangePassword): Promise<void> => {
  // I want to crypt the password before sending it to the server
  const key = process.env.REACT_APP_CRYPT_SECRET;
  if (key !== undefined) {
    const encryptedPassword = encrypt(changePassword.password, key);
    return checkResponse(
      await fetch(
        getUrl('password'),
        patchRequestInit(
          false,
          JSON.stringify({ jwt: changePassword.jwt, password: encryptedPassword })
        )
      )
    )
  }
}
