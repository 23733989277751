import React, { useState } from 'react'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import fileDownload from 'js-file-download'

import { ContractValidationStepEnum } from 'app/enums/contract.enum'
import DocumentHelper from 'app/helpers/document.helper'
import { useOnView } from 'app/providers/onview.provider'

export interface IProps {
  setStep: (step: ContractValidationStepEnum) => void
}

const ContractPendingStep1Component = ({ setStep }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { termsOfSalesDownloadUseMutation } = useOnView()

  const [checkCgv, setCheckCgv] = useState<boolean>(false)

  const downloadTermsOfSales = async (): Promise<void> => {
    let file = await termsOfSalesDownloadUseMutation?.mutateAsync(undefined)
    if (undefined !== file) {
      let blob = DocumentHelper.base64toBlob(file.content, file.mimetype)
      fileDownload(blob, file.filename, file.mimetype)
    }
  }

  return (
    <>
      <FormGroup className="inputInline">
        <FormControlLabel
          control={<Checkbox checked={checkCgv} onClick={() => setCheckCgv(!checkCgv)} />}
          label={t('common.contract_pending_cgv_check')} />
      </FormGroup>
      <div className='mb-20'>
        <a className='underline textColorPrimary'
           href='/reglement-interieur.pdf'
           target='_blank'
        >
          {t('common.rule_procedure')}
        </a>
        &nbsp;|&nbsp;
        <a className='underline textColorPrimary cursor-pointer' onClick={async () => downloadTermsOfSales()}>
          {t('common.cg')}
        </a>
      </div>
      <button
        type='button'
        className='button'
        disabled={!checkCgv}
        onClick={() => setStep(ContractValidationStepEnum.CODE_TO_REQUEST)}
      >
        {t('common.pendingContract.validate')}
      </button>
    </>
  )
}

export default ContractPendingStep1Component
