import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { CircularProgress } from '@material-ui/core'

import { useApp } from 'app/providers/app.provider'
import { useOnLogin } from 'app/providers/onlogin.provider'
import { useOnView } from 'app/providers/onview.provider'
import { IRequest } from 'app/models/requests.model'

import HelpdeskListItemComponent from 'modules/helpdesk/components/helpdeskListItem.component'

import 'modules/helpdesk/css/helpdesk.scss'

const HelpdeskList = (): JSX.Element => {
  const { t } = useTranslation()

  const { fromApp } = useApp()
  const { currentEnterprise } = useOnLogin()
  const { listClientRequestUseMutation } = useOnView()

  const [openCollapseStatus, setOpenCollapseStatus] = useState<number[]>([])
  const [requests, setRequests] = useState<IRequest[]>([])

  const helpdeskLink = useMemo(() => (fromApp ? '/helpdesk?fromApp=1' : '/helpdesk'), [fromApp])

  useEffect(() => {
    loadClientRequests().then()
  }, [currentEnterprise])

  const loadClientRequests = useCallback(async () => {
    let _requests = await listClientRequestUseMutation?.mutateAsync(currentEnterprise)
    setRequests(undefined !== _requests ? _requests : [])
  }, [currentEnterprise])

  const setOpen = useCallback(
    (index: number): void => {
      if (openCollapseStatus.includes(index)) {
        setOpenCollapseStatus(openCollapseStatus.filter((i: number) => i !== index))
      } else {
        setOpenCollapseStatus((openCollapseStatus) => [...openCollapseStatus, index])
      }
    },
    [openCollapseStatus]
  )

  return (
    <Paper elevation={1} className="paperHelpdesk">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} className="bkColHelpdesk pb-0">
          <Box component="div" px={2} py={4} className="small-y">
            <Typography variant="h2" gutterBottom={true}>
              Service client
            </Typography>
            <Divider />
            <Box component="div" mt={4} className="mt-0">
              <Link to={helpdeskLink} className="button bg-white">
                {t('common.helpdesk_new_ask')}
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={9} className="rightColHelpdesk">
          <Box component="div" p={2} className="boxCategoryHelpdesk show">
            <div className="myCard">
              <div className="card-header mb-20">
                <Typography variant="body1" color="primary">
                  <strong>{t('common.helpdesk_my_ask')}</strong>
                </Typography>
              </div>
              {!listClientRequestUseMutation?.isLoading && (
                <div className="card-content card-List-block">
                  {requests.map((request: IRequest, index: number) => {
                    return (
                      <HelpdeskListItemComponent
                        key={index}
                        request={request}
                        index={index}
                        openCollapseStatus={openCollapseStatus}
                        setOpen={setOpen}
                      />
                    )
                  })}
                  <div className="fixFloat"></div>
                </div>
              )}
              {listClientRequestUseMutation?.isLoading && (
                <div className="myCardLoader">
                  <CircularProgress color="secondary" />
                </div>
              )}
              <div className="fixFloat"></div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default HelpdeskList
