import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'

import { useOnLogin } from 'app/providers/onlogin.provider'
import Session from 'app/libraries/session.lib'

import 'modules/profile/css/profile.scss'

const PasswordFormComponent = (): JSX.Element => {
  const { t } = useTranslation()

  const { passwordUpdateUseMutation } = useOnLogin()

  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const validatedPasswordForm = useMemo<boolean>(
    () =>
      undefined !== password &&
      password.length > 0 &&
      password.match('(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)^[a-zA-Z\\d\\S]{8,32}$') !== null &&
      password === confirmPassword,
    [password, confirmPassword]
  )

  const onPasswordSubmit = useCallback(async () => {
    if (validatedPasswordForm) {
      passwordUpdateUseMutation?.mutateAsync({ id: Session.getUserId(), password: password })
    }
  }, [validatedPasswordForm])

  return (
    <div className="myCard myPassword">
      <div className="card-header">
        <Typography variant="h2" color="primary">
          {t('common.profileEdit.passwordForm.change')}
        </Typography>
      </div>
      <div className="card-content">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {t('common.profileEdit.passwordForm.rule')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              required
              type="password"
              onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
              label={t('common.profileEdit.passwordForm.new')}
              value={password}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              required
              type="password"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setConfirmPassword(event.target.value)
              }
              label={t('common.profileEdit.passwordForm.confirm')}
              value={confirmPassword}
            />
          </Grid>
        </Grid>
      </div>
      {passwordUpdateUseMutation?.isLoading && (
        <div className="myCardLoader">
          <CircularProgress color="secondary" />
        </div>
      )}
      {!passwordUpdateUseMutation?.isLoading && (
        <div className="card-actions">
          <button
            type="button"
            disabled={!validatedPasswordForm}
            className="button"
            onClick={onPasswordSubmit}>
            {t('common.save')}
          </button>
        </div>
      )}
    </div>
  )
}

export default PasswordFormComponent
