import {
    Box,
    CircularProgress, Typography,
} from "@material-ui/core";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useOnView} from "app/providers/onview.provider";
import {useParams} from "react-router-dom";
import {BookingParams} from "app/types/booking.type";
import {Card, Grid} from "@material-ui/core";
import {ICommon, MeetingSchema} from "app/models/booking.model";
import BookingReadLeftComponent from "modules/booking/components/booking/bookingReadLeft.component";
import BookingReadRightComponent from "modules/booking/components/booking/bookingReadRight.component";
import {ServiceTypeEnum} from "app/enums/booking.enum";
import {useOnLogin} from "app/providers/onlogin.provider";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {useApp} from "app/providers/app.provider";
import IconButton from "@material-ui/core/IconButton";
import {ChevronLeftRounded} from "@material-ui/icons";
import BookingHeaderComponent from "modules/booking/components/booking/bookingHeader.component";
import { useNomadeCounter } from 'app/hooks/useNomadeCounter';
import Session from "app/libraries/session.lib";
import DateFormatter from "app/formatters/date.formatter";

const BookingRead = ({mode = ServiceTypeEnum.MEETING} : {mode? : number}): JSX.Element => {
    const {meetingRoomUseMutation, commonMeetingServiceUseMutation, getAssets} = useOnView()
    const {centers, userCenterId, currentEnterprise, currentRole} = useOnLogin()
    const {fromApp, bookingDate} = useApp()
    const {t} = useTranslation();
    const {id} = useParams<BookingParams>()
    const [meetingRoom, setMeetingRoom] = useState<MeetingSchema | undefined>({} as MeetingSchema)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [commonServices, setCommonServices] = useState<ICommon[]>()
    const centerIds = useMemo(() => centers !== undefined ? centers.map(center => center.id) : [], [centers])
    const [targetCenter, setTargetCenter] = useState<string>(String(userCenterId))
    const { nomadeCounter } = useNomadeCounter(userCenterId, currentEnterprise, bookingDate);
    const bookingLink = useMemo<string>(
        () => (fromApp ? '/booking?fromApp=1' : '/booking'),
        [fromApp]
    )

    const getMeetingRoom = useCallback(async () => {
        if (id !== undefined && mode === ServiceTypeEnum.MEETING) {
            setIsLoading(true)
            const results = await Promise.all([
                meetingRoomUseMutation?.mutateAsync({id}),
                commonMeetingServiceUseMutation?.mutateAsync({})
            ])
            setIsLoading(false)
            setMeetingRoom(results[0])
            setCommonServices(results[1])
        }else if(mode === ServiceTypeEnum.OPEN_DESKTOP){
            setIsLoading(false)
            setMeetingRoom({
                is_community_room: false,
                label : 'common.booking_open_desktop',
                serviceType : String(ServiceTypeEnum.OPEN_DESKTOP),
                id : '',
                images: await getAssets?.mutateAsync({type : 'service-types', id : ServiceTypeEnum.FULL_DESKTOP}) ?? []
            })
        }
    }, [id, meetingRoomUseMutation, commonMeetingServiceUseMutation, t])


    useEffect(() => {
        getMeetingRoom().then();
    }, [t])

    const bookingListLink = useMemo(
        () => (fromApp ? '/booking_list?fromApp=1' : '/booking_list'),
        [fromApp]
    )

    return (<>
        {fromApp && <Grid item xs={12} md={12} style={{marginBottom : 12}}>
            <Card >
                <BookingHeaderComponent bookingListLink={bookingListLink}/>
            </Card>
        </Grid>}
        {mode === ServiceTypeEnum.OPEN_DESKTOP  ?
            <Typography variant={'caption'} color={'textPrimary'} gutterBottom>{t('common.booking_meeting_instead')}
                <Button size={'small'} variant={'text'} color={'primary'} style={{fontSize : 'inherit', textTransform :'initial'}} href={bookingLink}>{t('common.click_here_maj')}</Button>
            </Typography> : <IconButton className='mobile-only' size={'small'} color={'primary'} href={bookingLink}><ChevronLeftRounded /></IconButton>
        }
        <Card>
            <Grid container>
               {isLoading || meetingRoom === undefined || Object.keys(meetingRoom).length === 0 ? (
                    <Box width={'100%'} textAlign={'center'} p={2}>
                        <CircularProgress color="primary"/>
                    </Box>
                ) : (
                    <>
                        <BookingReadLeftComponent
                          meetingRoom={meetingRoom as MeetingSchema}
                          centers={centers}
                          targetCenter={targetCenter}
                        />
                        <BookingReadRightComponent
                          nomadeCounter={nomadeCounter}
                          meetingRoom={meetingRoom as MeetingSchema}
                          commonServices={commonServices as ICommon[]}
                          centerIds={centerIds}
                          targetCenter={targetCenter}
                          setTargetCenter={setTargetCenter}
                        />
                    </>
                )}
            </Grid>
        </Card>
    </>)
}

export default BookingRead
