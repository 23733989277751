import { checkResponse, getRequestInit, getUrl } from 'app/api/api'
import { IAccountingDocument } from 'app/models/accountingDocuments.model'

export const AccountingDocumentListQuery = async (
  enterprise: number
): Promise<IAccountingDocument[]> => {
  return checkResponse(
    await fetch(getUrl(`v3/enterprises/${enterprise}/accountingdocuments`), getRequestInit(true))
  )
}

export const AccountingDocumentIndividualListQuery = async (
  individual: number
): Promise<IAccountingDocument[]> => {
  return checkResponse(
    await fetch(getUrl(`v3/individuals/${individual}/accountingdocuments`), getRequestInit(true))
  )
}
