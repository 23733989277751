import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@material-ui/core'

import IconServicesInclusCharges from 'images/quotations/icon/services/icon-services-inclus-charges.jpg'
import IconServicesInclusDomiciliation from 'images/quotations/icon/services/icon-services-inclus-domiciliation.jpg'
import IconServicesInclusSecurite from 'images/quotations/icon/services/icon-services-inclus-securite.jpg'
import IconServicesInclusOtherService from 'images/quotations/icon/services/icon-services-autre-service.jpg'
import IconServicesInclusPostal from 'images/quotations/icon/services/icon-services-gestion-postale.jpg'
import IconServicesInclusReception from 'images/quotations/icon/services/icon-services-inclus-service-de-reception.jpg'

const QuotationDomiciliationIncludedComponent = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={4}>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusCharges}
          className="IconInclusives"
          alt={t('common.quotationView.included.charges')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.charges')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusDomiciliation}
          className="IconInclusives"
          alt={t('common.quotationView.included.domiciliationLegal')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.domiciliationLegal')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusSecurite}
          className="IconInclusives"
          alt={t('common.quotationView.included.secureAccess')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.secureAccess')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusOtherService}
          className="IconInclusives"
          alt={t('common.quotationView.included.concierge')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.concierge')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusReception}
          className="IconInclusives"
          alt={t('common.quotationView.included.reception')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.reception')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusPostal}
          className="IconInclusives"
          alt={t('common.quotationView.included.box')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          {t('common.quotationView.included.box')}
        </Box>
      </Grid>
    </Grid>
  )
}

export default QuotationDomiciliationIncludedComponent
