import {useTranslation} from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {HELPDESK_COLORS, HELPDESK_ICONS} from "modules/helpdesk/views/helpdesk.view";
import {HelpdeskCategory} from "app/models/helpdesk.models";
import {Grow} from "@material-ui/core";

interface CategoryListProps {
    displayForm: (category: any) => void,
    categories: HelpdeskCategory[]
}

export const HelpdeskCategoryList = ({displayForm, categories}: CategoryListProps): JSX.Element => {
    const {t} = useTranslation()
    return (
        <>
            <List className="gridIconHepldesk">
                {categories.map((item: any) => {
                    return <Grow in={true} key={item.id}>
                        <ListItem alignItems="center"
                                  className={
                                      HELPDESK_COLORS[item.label as keyof typeof HELPDESK_COLORS] ??
                                      HELPDESK_COLORS['default']
                                  }
                                  onClick={() => displayForm(item)}>
                            <ListItemAvatar>
                                <Avatar className="avatarColorHelpdesk">
                                    {
                                        HELPDESK_ICONS[item.label as keyof typeof HELPDESK_ICONS] ??
                                        HELPDESK_ICONS['default']
                                    }
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={t('common.customer_services.' + item.label)}/>
                        </ListItem></Grow>
                })}
            </List>
            <div className="fix-float"/>
        </>
    )
}