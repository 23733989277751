import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import {useApp} from 'app/providers/app.provider'
import {useOnView} from 'app/providers/onview.provider'
import {useOnLogin} from 'app/providers/onlogin.provider'
import {ICustomerReservation} from 'app/models/booking.model'
import {BookingSelectedMonthEnum} from 'app/enums/booking.enum'
import DateFormatter from 'app/formatters/date.formatter'

import BookingListItem from 'modules/booking/components/bookingList/bookingListItem'

import 'modules/booking/css/booking.scss'
import {CircularProgress} from '@material-ui/core'
import { useNomadeCounter } from 'app/hooks/useNomadeCounter'

const months: string[] = [
  'common.month.january',
  'common.month.february',
  'common.month.march',
  'common.month.april',
  'common.month.may',
  'common.month.june',
  'common.month.july',
  'common.month.august',
  'common.month.september',
  'common.month.october',
  'common.month.november',
  'common.month.december',
  'common.month.all_months'
]

const BookingList = (): JSX.Element => {
  const {t} = useTranslation()
  const {fromApp} = useApp()
  const {currentEnterprise, currentRole, isFinishedChangeEnterprise, userCenterId} = useOnLogin()
  const {listCustomerReservationUseMutation} = useOnView()

  const [openCollapseStatus, setOpenCollapseStatus] = useState<number[]>([])
  const [customerReservations, setCustomerReservations] = useState<ICustomerReservation[]>([])
  const [years, setYears] = useState<number[]>([])
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined)
  const [selectedMonth, setSelectedMonth] = useState<number>(BookingSelectedMonthEnum.ALLMONTHS)
  const [filterCustomerReservations, setFilterCustomerReservations] = useState<
    ICustomerReservation[]
  >([])
  const { nomadeCounter } = useNomadeCounter(userCenterId, currentEnterprise, undefined);
  const [isNomadeCounterValid, setIsNomadeCounterValid] = useState(false);

  const bookingLink = useMemo<string>(
    () => (fromApp ? '/booking?fromApp=1' : '/booking'),
    [fromApp]
  )

  const bookingLinkOffice = useMemo<string>(
      () => (fromApp ? '/booking-office?fromApp=1' : '/booking-office'),
      [fromApp]
  )

  const className = useMemo<string>(
    () =>
      `card-content card-List-block ${
        filterCustomerReservations.length > 0 ? ' card-list-scroll' : ''
      }`,
    [filterCustomerReservations]
  )

  useEffect(() => {
    if (isFinishedChangeEnterprise) {
      loadCustomerReservations().then()
    }
  }, [isFinishedChangeEnterprise])

  useEffect(() => {
    let yearsFromCr = getYearsFromCustomerReservation()
    setYears(yearsFromCr)
    setSelectedYear(yearsFromCr[0])
  }, [customerReservations])

  useEffect(() => {
    if (nomadeCounter !== null && nomadeCounter?.countReservation !== undefined) {
      setIsNomadeCounterValid(true);
    }
    if (years.length > 0 && selectedYear !== undefined) {
      onFilterCustomerReservations();
    }
  }, [years, selectedYear, selectedMonth, nomadeCounter]);

  const loadCustomerReservations = useCallback(async () => {
    const _customerReservations = await listCustomerReservationUseMutation?.mutateAsync(
      currentEnterprise
    )
    setCustomerReservations(undefined !== _customerReservations ? _customerReservations : [])
  }, [currentEnterprise, currentRole])

  const getYearsFromCustomerReservation = useCallback((): number[] => {
    let yearList: number[] = [new Date().getFullYear()]

    customerReservations.map((cr: ICustomerReservation) => {
      if (cr.beginDate !== undefined && cr.beginHour !== undefined) {
        let year = Number(
          DateFormatter.formatYearFromString(cr.beginDate + 'T' + cr.beginHour + ':00')
        )
        if (!yearList.includes(year)) {
          yearList.push(year)
        }
      }
    })
    return yearList
  }, [customerReservations])

  const onFilterCustomerReservations = useCallback((): void => {
    let customerReservationList = customerReservations.filter((cr: ICustomerReservation) => {
      if (cr.beginDate === undefined || cr.beginHour === undefined) {
        return false
      }
      let date = new Date(cr.beginDate + 'T' + cr.beginHour + ':00')

      return (
        selectedYear === date.getFullYear() &&
        (selectedMonth === BookingSelectedMonthEnum.ALLMONTHS || selectedMonth === date.getMonth())
      )
    })
    setFilterCustomerReservations(customerReservationList)
  }, [customerReservations, selectedYear, selectedMonth])

  const setOpen = useCallback(
    (index: number): void => {
      if (openCollapseStatus.includes(index)) {
        setOpenCollapseStatus(openCollapseStatus.filter((i: number) => i !== index))
      } else {
        setOpenCollapseStatus((openCollapseStatus) => [...openCollapseStatus, index])
      }
    },
    [openCollapseStatus]
  )

  return (
    <Paper elevation={1} className="paperHelpdesk">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} className="bkColImgBk">
          <Box component="div" px={2} py={4} className="small-y">
            {!fromApp && (
              <>
                <Typography variant="h2" gutterBottom={true}>
                  {t('common.my_booking')}
                </Typography>
                <Divider/>
              </>
            )}
            <Box display={'flex'} flexDirection={'column'}>
              {!(currentRole === 'ROLE_USER' && (!isNomadeCounterValid || (isNomadeCounterValid && nomadeCounter?.nomadeLabel === '100% Nomade France'))) && (
                <Link to={bookingLinkOffice} style={{marginBottom : '12px'}}  className={`button bg-white ${fromApp ? 'center' : ''}`}>
                  {t('common.booking_new_office')}
              </Link>)}
               <Link to={bookingLink} className={`button bg-white ${fromApp ? 'center' : ''}`}>
                {t('common.booking_new_meeting')}
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={9} className="rightColHelpdesk">
          <Box component="div" p={2} className="pt-0">
            <div className="myCard">
              <Grid container spacing={2} className="formListBooking mt-0">
                <Grid item xs={12} md={3}>
                  <FormControl variant="standard" className="labelFormControl" fullWidth>
                    <Select
                      className="filter mr-xs mb-xs"
                      native
                      variant="standard"
                      value={selectedYear}
                      onChange={(
                        event: ChangeEvent<{ name?: string | undefined; value: unknown }>
                      ) => setSelectedYear(Number(event.target.value))}>
                      {years.map((year: number, index: number) => {
                        return (
                          <option key={index} value={year}>
                            {year}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl variant="standard" className="labelFormControl" fullWidth>
                    <Select
                      className="filter mr-xs mb-xs"
                      native
                      variant="standard"
                      value={selectedMonth}
                      onChange={(
                        event: ChangeEvent<{ name?: string | undefined; value: unknown }>
                      ) => setSelectedMonth(Number(event.target.value))}>
                      {months.map((month: string, index: number) => {
                        return (
                          <option key={index} value={index}>
                            {t(month)}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {!listCustomerReservationUseMutation?.isLoading && (
                <div className={className}>
                  {filterCustomerReservations.map(
                    (customerReservation: ICustomerReservation, index: number) => {
                      return (
                        <BookingListItem
                          customerReservation={customerReservation}
                          key={index}
                          index={index}
                          openCollapseStatus={openCollapseStatus}
                          setOpen={setOpen}
                        />
                      )
                    }
                  )}
                </div>
              )}
              {listCustomerReservationUseMutation?.isLoading && (
                <div className="myCardLoader">
                  <CircularProgress color="secondary"/>
                </div>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default BookingList
