import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import PasswordFormComponent from 'modules/profile/components/passwordForm.component'
import ProfileFormComponent from 'modules/profile/components/profileForm.component'

const ProfileEdit = (): JSX.Element => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Paper elevation={1}>
        <Box p={2}>
          <ProfileFormComponent />
        </Box>
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <Paper elevation={1}>
        <Box p={2}>
          <PasswordFormComponent />
        </Box>
      </Paper>
    </Grid>
  </Grid>
)

export default ProfileEdit
