import React, { Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Lens from '@material-ui/icons/Lens'

import { AccountingDocumentStateEnum } from 'app/enums/accountingDocument.enum'

interface Props extends WithTranslation {
  state: AccountingDocumentStateEnum
}

interface State {
}

class AccountingDocumentState extends React.Component<Props, State> {
  public render(): JSX.Element {
    switch (this.props.state) {
      case AccountingDocumentStateEnum.UNKNOWN:
        return (<Fragment><Lens className='smallIcon textColorWarning' /> {this.props.t('common.unknown')}</Fragment>)
      case AccountingDocumentStateEnum.DRAFT:
        return (<Fragment><Lens className='smallIcon' color='secondary' /> {this.props.t('common.draft')}</Fragment>)
      case AccountingDocumentStateEnum.DUE:
        return (<Fragment><Lens className='smallIcon textColorInfo' /> {this.props.t('common.due')}</Fragment>)
      case AccountingDocumentStateEnum.PARTIALPAYMENT:
        return (
          <Fragment><Lens className='smallIcon' color='disabled' />{this.props.t('common.partial_payment')}</Fragment>)
      case AccountingDocumentStateEnum.PAID:
        return (<Fragment><Lens className='smallIcon textColorSuccess' /> {this.props.t('common.paid')}</Fragment>)
      case AccountingDocumentStateEnum.LATE:
        return (<Fragment><Lens className='smallIcon' color='error' /> {this.props.t('common.late')}</Fragment>)
      case AccountingDocumentStateEnum.CANCELLED:
        return (
          <Fragment><Lens className='smallIcon textColorSecondaryDark' /> {this.props.t('common.cancelled')}</Fragment>)
      case AccountingDocumentStateEnum.STORED:
        return (<Fragment>{this.props.t('common.stored')}</Fragment>)
      case AccountingDocumentStateEnum.PARTIAL_SPEND:
        return (<Fragment>{this.props.t('common.partial_spend')}</Fragment>)
      case AccountingDocumentStateEnum.SPENT:
        return (<Fragment>{this.props.t('common.spent')}</Fragment>)
      case AccountingDocumentStateEnum.BLOCKED:
        return (<Fragment><Lens className='smallIcon textColorWarning' /> {this.props.t('common.blocked')}</Fragment>)
      default:
        return (<Fragment />)
    }
  }
}

export default withTranslation()(AccountingDocumentState)