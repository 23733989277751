import React, { useEffect, useState } from 'react'
import { Viewer } from '@react-pdf-viewer/core'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import DocumentHelper from 'app/helpers/document.helper'
import { IContractDetail } from 'app/models/contracts.model'
import PDFWorker from '../../pdf/pdfWorker.view'

export interface IProps {
  contract?: IContractDetail
}

const ContractPendingPdfComponent = ({ contract }: IProps): JSX.Element => {
  const { t } = useTranslation()

  const [url, setUrl] = useState<string>('')

  useEffect(() => {
    (async function() {
      let _url = ''
      if (contract?.file) {
        let blob = DocumentHelper.base64toBlob(contract.file)
        _url = URL.createObjectURL(blob)
      }
      setUrl(_url)
    })()
  }, [contract])

  return (
    <>
      <Grid container spacing={3} justifyContent='center' alignItems='center'>
        <Grid item xs={12} md={8}>
          <PDFWorker>
            <div
              style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: 'auto'
              }}
            >
              <Viewer fileUrl={url} />
            </div>
          </PDFWorker>
        </Grid>
      </Grid>
      <div className='pending-contract-step'>
        <a
          href={url}
          className='button'
          download={`contract_${contract?.id}.pdf`}
        >
          {t('common.download_contract')}
        </a>
      </div>
    </>
  )
}

export default ContractPendingPdfComponent
