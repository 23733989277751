import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import Logo from 'images/common/logo.png'
import { useApp } from 'app/providers/app.provider'
import PaymentResultMessageSuccess from 'modules/payment/views/payment_result_message_success'
import 'modules/payment/css/payment.scss'

const PaymentResult = (): JSX.Element => {
  const { t } = useTranslation()
  const { isConnected } = useApp()
  const [result, setResult] = useState<string>('error')
  const [type, setType] = useState<string>('sepa')

  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    setResult(String(params.get('result')))
    setType(String(params.get('type')))
  }, [])

  return (
    <Grid container spacing={3} className='quotationscard-container'>
      {!isConnected && (
        <Grid item xs={12} className='logoQuotation'>
          <img src={Logo} className='logo' alt='' />
        </Grid>
      )}
      <Grid container justify='center' alignItems='center' spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={1}>
            <Box p={2}>
              <div className='myCard'>
                <div className='card-header list-header'>
                  <Typography variant='h2' color='primary'>
                    {t('common.payment')}
                  </Typography>
                </div>
                {result === 'success' && type === 'sepa' && (
                  <Box textAlign='center' component='div' className='card-content text-center' mt={2}>
                    <CheckCircleOutlineIcon color='primary' fontSize='large' /><br />
                    {t('common.message_result_success_gc')}
                    {isConnected && (
                      <PaymentResultMessageSuccess />
                    )}
                  </Box>
                )}
                {result === 'success' && type === 'cb' && (
                  <Box textAlign='center' component='div' className='card-content text-center' mt={2}>
                    <CheckCircleOutlineIcon color='primary' fontSize='large' /><br />
                    {t('common.message_result_success_stripe')}
                    {isConnected && (
                      <PaymentResultMessageSuccess />
                    )}
                  </Box>
                )}
                {result !== 'success' && (
                  <Box textAlign='center' component='div' className='card-content text-center' mt={2}>
                    <ErrorOutlineIcon className='text-error' fontSize='large' /><br />
                    {t('common.message_result_error_gc')}
                  </Box>
                )}
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PaymentResult
