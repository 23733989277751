import React, { Fragment } from 'react'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useApp } from 'app/providers/app.provider'
import Meal from 'images/foodcard/plateau-repas.jpg'
import Salad from 'images/foodcard/salad.svg'
import IsotopeGreen from 'images/common/background-icon-card.png'
import 'modules/foodcard/css/foodcard.scss'

const Foodcard = (): JSX.Element => {
  const { t } = useTranslation()
  const { fromApp } = useApp()

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {!fromApp && (
            <Typography component="h2" variant="h2" color="primary">
              {t('common.foodcard.title')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="MyCardroot">
            <div className="MyCarddetails">
              <CardContent className="MyCardcontent">
                <Typography component="p" className="title">
                  {t('common.foodcard.meal_trays')}
                </Typography>
                <Typography component="p" color="primary" className="price">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('common.foodcard.meal_trays_price') }}
                  />
                </Typography>
                <Typography
                  component="p"
                  variant="subtitle1"
                  color="textSecondary"
                  className="small-text">
                  {t('common.foodcard.meal_trays_description')}
                </Typography>
                {!fromApp && (
                  <Typography
                    component="p"
                    variant="subtitle1"
                    color="textSecondary"
                    className="Link ">
                    <span>
                      {t('common.foodcard.discover')}
                      <NavigateNextIcon />
                    </span>
                  </Typography>
                )}
              </CardContent>
            </div>
            <CardMedia
              className="MyCardcover"
              image={Meal}
              title="{t('common.foodcard.meal_trays')}"
            />
          </Card>
        </Grid>
        <Grid item xs={6} md={4} className="cardGreen">
          <Card className="MyCardroot">
            <div className="MyCarddetails">
              <CardContent className="MyCardcontent">
                <Typography component="p" className="title">
                  {t('common.foodcard.salad_menu')}
                </Typography>
                <Typography component="p" className="price">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('common.foodcard.salad_menu_price') }}
                  />
                </Typography>
                <Typography component="p" variant="subtitle1" className="small-text">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('common.foodcard.salad_menu_description')
                    }}
                  />
                </Typography>
              </CardContent>
            </div>
            <CardMedia
              className="MyCardcover"
              image={Salad}
              title="{t('common.foodcard.salad_menu')}"
            />
            {!fromApp && (
              <Typography component="p" variant="subtitle1" color="textSecondary" className="Link ">
                <span>
                  {t('common.foodcard.discover')} <NavigateNextIcon />
                </span>
              </Typography>
            )}
          </Card>
        </Grid>
        <Grid item xs={6} md={4}>
          <div className="cardGray">
            <Card className="MyCardroot">
              <div className="MyCarddetails">
                <CardContent className="MyCardcontent">
                  <Typography component="p" className="title">
                    {t('common.foodcard.sandwich_menu')}
                    <br />
                    <span
                      dangerouslySetInnerHTML={{ __html: t('common.foodcard.sandwich_menu_price') }}
                    />
                  </Typography>
                  <Typography component="p" variant="subtitle1" className="small-text">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('common.foodcard.sandwich_menu_description')
                      }}
                    />
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </div>
          <div className="cardGrayLight">
            <Card className="MyCardroot">
              <div className="MyCarddetails">
                <CardContent className="MyCardcontent">
                  <Typography component="p" className="title">
                    {t('common.foodcard.desserts')}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="cardWhite">
            <Card className="MyCardroot">
              <div className="MyCarddetails">
                <CardContent className="MyCardcontent">
                  <Typography component="p" className="title">
                    {t('common.foodcard.drinks')}
                  </Typography>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    color="textSecondary"
                    className="small-text">
                    <span
                      dangerouslySetInnerHTML={{ __html: t('common.foodcard.drinks_description') }}
                    />
                  </Typography>
                </CardContent>
              </div>
              <CardMedia
                className="MyCardcover"
                image={IsotopeGreen}
                title="{t('common.foodcard.drinks')}"
              />
            </Card>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Foodcard
