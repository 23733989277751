import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { IQuotation } from 'app/models/quotations.models'
import QuotationMeetingRoomIncludedComponent from 'modules/quotations/components/meetingRoom/quotationMeetingRoomIncluded.component'

export interface IProps {
  hidden: boolean
  quotation: IQuotation
  isMobile: boolean
  index: number
}

const QuotationMeetingRoomComponent = ({
  hidden,
  quotation,
  isMobile,
  index
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div role="tabpanel" hidden={hidden} id={`quotations-tabpanel-${index}`}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={9} md={10} className="display-proposed-solutions">
          <Box fontWeight="fontWeightBold" mt={0} mb={2} component="p">
            {t('common.quotationView.meetingRoom.title')}
          </Box>
          <Box mt={0} mb={2} component="p">
            {t('common.quotationView.meetingRoom.more')}
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} md={2} className="mb-20">
          {quotation.visitLink !== '' && quotation.visitLink !== null && (
            <Box textAlign="center">
              <a
                className="cursor-pointer button visitLink"
                onClick={() => window.open(quotation.visitLink, '_blank')}>
                {t('common.quotationView.center.visit')}
              </a>
            </Box>
          )}
        </Grid>
        <div className="clear" />
      </Grid>
      <Accordion className="accordion-solution-block" elevation={0} defaultExpanded={!isMobile}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="include-domiciliation-content"
          id="produit-header"
          className="accordion-solution-header grey">
          <Typography className="text-uppercase">
            <strong>{t('common.quotationView.inclusiveServices')}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-solution-body pt-20">
          <QuotationMeetingRoomIncludedComponent />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default QuotationMeetingRoomComponent
