import React, { useCallback, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'

import { ContractValidationStepEnum } from 'app/enums/contract.enum'
import { useOnView } from 'app/providers/onview.provider'
import { useOnLogin } from 'app/providers/onlogin.provider'

export interface IProps {
  id?: number
  setStep: (step: ContractValidationStepEnum) => void
}

const ContractPendingStep2Component = ({ id, setStep }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { currentEnterprise } = useOnLogin()
  const { requestSmsPendingContractUseMutation } = useOnView()
  const [onCodeRequestMessage, setOnCodeRequestMessage] = useState<string>(
    'Vous allez recevoir un code par sms.'
  )

  const onRequestedSms = useCallback(async (): Promise<void> => {
    await requestSmsPendingContractUseMutation?.mutateAsync({
      enterpriseId: currentEnterprise,
      id: id!
    })
    setStep(ContractValidationStepEnum.CODE_REQUESTED)
  }, [currentEnterprise])

  return (
    <div className="card">
      <div className="card-content mb-xs">{onCodeRequestMessage}</div>
      <div className="card-actions">
        {requestSmsPendingContractUseMutation?.isLoading && (
          <div className="myCardLoader">
            <CircularProgress color="secondary" />
          </div>
        )}
        {!requestSmsPendingContractUseMutation?.isLoading && (
          <button type="button" className="button" onClick={async () => await onRequestedSms()}>
            {t('common.pendingContract.continue')}
          </button>
        )}
      </div>
    </div>
  )
}

export default ContractPendingStep2Component
