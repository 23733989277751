export enum AccountingDocumentStateEnum {
  UNKNOWN = 'unknown',
  DRAFT = 'draft',
  DUE = 'due',
  PARTIALPAYMENT = 'partialpayment',
  PAID = 'paid',
  LATE = 'late',
  CANCELLED = 'cancelled',
  STORED = 'stored',
  PARTIAL_SPEND = 'partialspend',
  SPENT = 'spent',
  BLOCKED = 'blocked'
}

export enum AccountingDocumentTypeEnum {
  UNKNOWN = 'unknown',
  INVOICE = 'invoice',
  CREDIT = 'credit',
}