import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useOnLogin } from 'app/providers/onlogin.provider'
import { IAccount } from 'app/models/account.model'
import { CivilityEnum } from 'app/enums/app.enum'

import 'modules/profile/css/profile.scss'

const ProfileFormComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { account, accountUpdateUseMutation } = useOnLogin()

  const [editedAccount, setEditedAccount] = useState<IAccount | undefined>(account)

  const validatedForm = useMemo<boolean>(
    () =>
      undefined !== editedAccount?.email &&
      editedAccount?.email.length > 0 &&
      undefined !== editedAccount?.lastname &&
      editedAccount?.lastname.length > 0,
    [editedAccount]
  )

  const onSubmit = useCallback(async () => {
    if (validatedForm) {
      accountUpdateUseMutation?.mutateAsync(editedAccount!)
    }
  }, [editedAccount])

  return (
    <div className="myCard myProfile">
      <div className="card-header">
        <Typography variant="h2" color="primary">
          {t('common.personal_information')}
        </Typography>
      </div>
      <div className="card-content">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="labelFormControl" fullWidth>
              <InputLabel htmlFor="filled-civility-native-simple">
                {t('common.civility')}
              </InputLabel>
              <Select
                native
                value={
                  editedAccount?.civility !== undefined ? editedAccount?.civility : CivilityEnum.Man
                }
                inputProps={{
                  name: 'civility',
                  id: 'filled-civility-native-simple'
                }}
                onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                  setEditedAccount({
                    ...editedAccount,
                    civility: Number(event.target.value)
                  })
                }>
                <option value={CivilityEnum.Man}>{t('common.man')}</option>
                <option value={CivilityEnum.Woman}>{t('common.woman')}</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="standard"
              fullWidth
              label={t('common.firstname')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEditedAccount({ ...editedAccount, firstname: event.target.value })
              }
              value={editedAccount?.firstname !== undefined ? editedAccount?.firstname : ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="standard"
              fullWidth
              label={t('common.lastname')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEditedAccount({ ...editedAccount, lastname: event.target.value })
              }
              className={
                undefined === editedAccount?.lastname || editedAccount?.lastname.length === 0
                  ? 'input-invalid'
                  : ''
              }
              value={editedAccount?.lastname !== undefined ? editedAccount?.lastname : ''}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              variant="standard"
              fullWidth
              label={t('common.email')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEditedAccount({ ...editedAccount, email: event.target.value })
              }
              className={
                undefined === editedAccount?.email || editedAccount?.email.length === 0
                  ? 'input-invalid'
                  : ''
              }
              value={editedAccount?.email !== undefined ? editedAccount?.email : ''}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              label={t('common.landline_phone')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEditedAccount({ ...editedAccount, phone: event.target.value })
              }
              value={editedAccount?.phone !== undefined ? editedAccount?.phone : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              label={t('common.mobile_phone')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEditedAccount({ ...editedAccount, mobile: event.target.value })
              }
              value={editedAccount?.mobile !== undefined ? editedAccount?.mobile : ''}
            />
          </Grid>
        </Grid>
      </div>
      {accountUpdateUseMutation?.isLoading && (
        <div className="myCardLoader">
          <CircularProgress color="secondary" />
        </div>
      )}
      {!accountUpdateUseMutation?.isLoading && (
        <div className="card-actions">
          <button type="button" disabled={!validatedForm} className="button" onClick={onSubmit}>
            {t('common.save')}
          </button>
        </div>
      )}
    </div>
  )
}

export default ProfileFormComponent
