import {checkResponse, getRequestInit, getUrl} from './api'
import {ICredit} from 'app/models/credits.models'

export const CreditGetQuery = async (enterpriseId: number, creditId: number): Promise<ICredit> => {
  return checkResponse(
    await fetch(
      getUrl(`v2/enterprises/${enterpriseId}/credits/${creditId}`),
      getRequestInit(true)
    )
  )
}