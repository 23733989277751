import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { IQuotation } from 'app/models/quotations.models'
import QuotationDetailsComponent from 'modules/quotations/components/header/quotationDetails.component'
import Avatar from '@material-ui/core/Avatar'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useOnView } from 'app/providers/onview.provider'
import { useOnLogin } from 'app/providers/onlogin.provider'
import fileDownload from 'js-file-download'
import { Skeleton } from '@material-ui/lab'

export interface IProps {
  quotation?: IQuotation
}

const QuotationDetailsCardComponent = ({ quotation }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { currentEnterprise } = useOnLogin()
  const { quotationDownloadUseMutation } = useOnView()
  const [isLoading, setIsLoading] = useState(false)

  const download = useCallback(async (): Promise<void> => {
    let params = new URLSearchParams(window.location.search)
    let token = params.get('token') ?? '';

    if(!quotation) return
    setIsLoading(true)
    try {
      let _file = await quotationDownloadUseMutation?.mutateAsync(token);
      if (_file) {
        let blob = new Blob([_file.content], { type: _file.mimetype });
        fileDownload(blob, _file.filename, _file.mimetype);
      }
    }
    catch (e) {
      console.error(e)
    }
    finally {
      setIsLoading(false)
    }
  }, [currentEnterprise, quotationDownloadUseMutation, quotation])

  return (
    <div className="myCard">
      <div className="card-header">
        <Typography variant="h2" color="primary">
          {t('common.your_quotation_flexo')} {quotation!.center}
        </Typography>
        {isLoading ? (
          <Skeleton width={'36px'} height={'36px'} variant="circle" />
        ) : (
          <Avatar className="avatarColor small primary pointer" onClick={download}>
            <GetAppIcon fontSize='small' />
          </Avatar>
        )}
      </div>
      <div className="card-content">
        <QuotationDetailsComponent quotation={quotation!} />
      </div>
    </div>
  )
}

export default QuotationDetailsCardComponent
