import { Box, Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import IconServicesInclusMeetingRoom from 'images/quotations/icon/services/icon-services-salle-de-reunion.jpg'
import IconServicesInclusSpaceShare from 'images/quotations/icon/services/icon-services-inclus-espace-partage.jpg'

const QuotationDomiciliationOptionsComponent = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={4}>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusSpaceShare}
          className="IconInclusives"
          alt={t('common.quotationView.options.domiciliation.nomade.title')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          <strong>
            <div
              className="textColorPrimary"
              dangerouslySetInnerHTML={{
                __html: t('common.quotationView.options.domiciliation.nomade.title')
              }}
            />
          </strong>
          {t('common.quotationView.options.domiciliation.nomade.description')}
        </Box>
      </Grid>
      <Grid item md={4} xs={6}>
        <img
          src={IconServicesInclusMeetingRoom}
          className="IconInclusives"
          alt={t('common.quotationView.options.domiciliation.booking.title')}
        />
        <Box mt={1} fontSize={14} component="p" className="text-center">
          <span className="textColorPrimary">
            <strong>{t('common.quotationView.options.domiciliation.booking.title')}</strong>
          </span>
          <br />
          {t('common.quotationView.options.domiciliation.booking.description')}
        </Box>
      </Grid>
    </Grid>
  )
}

export default QuotationDomiciliationOptionsComponent
