import React from 'react'

import CenterFilter from 'app/views/CenterFilter'
import { IContractListFilter } from 'app/types/contracts.type'
import { useOnLogin } from 'app/providers/onlogin.provider'

export interface IProps {
  filters?: IContractListFilter
  onChangeFilters: (filters: IContractListFilter) => void
}

const ContractListFiltersComponent = ({ filters, onChangeFilters }: IProps): JSX.Element => {
  const { userCenter, centerList } = useOnLogin()

  return (
    <div className="documents-list-filters pt-30">
      <CenterFilter
        allCentersOption={centerList.length > 1}
        centers={centerList}
        defaultCenter={centerList.length > 1 ? '' : userCenter!}
        onCenterChange={(center: string) => onChangeFilters({ ...filters, center: center })}
      />
    </div>
  )
}

export default ContractListFiltersComponent
