import React from 'react'
import { DialogContentText } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import { useTranslation } from 'react-i18next'

export interface IProps {
  open: boolean
  onClose: () => void
  onValidate: () => Promise<void>
  isDelete: boolean
  message: string
}

const BookingListModalDeleteComponent = ({
  open,
  onClose,
  onValidate,
  isDelete,
  message
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog fullWidth={true} onClose={onClose} open={open} className="ModalDelete">
      <DialogContentText className="text-center pt-30 pb-30">{message}</DialogContentText>
      {(!isDelete && (
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <button type="button" className="button block bg-secondary" onClick={onClose}>
              {t('common.global.close')}
            </button>
          </Grid>
          <Grid item xs={4}>
            <button type="button" className="button block" onClick={onValidate}>
              {t('common.global.confirm')}
            </button>
          </Grid>
        </Grid>
      )) || (
        <button
          type="button"
          className="button block"
          onClick={() => (window.location.href = '/booking_list')}>
          {t('common.global.close')}
        </button>
      )}
    </Dialog>
  )
}

export default BookingListModalDeleteComponent
