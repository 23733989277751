import React, {useCallback, useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import {useTranslation} from 'react-i18next'
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import WifiIcon from '@material-ui/icons/Wifi'
import FaceIcon from '@material-ui/icons/Face'
import WebIcon from '@material-ui/icons/Web'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import PrintIcon from '@material-ui/icons/Print'
import WcIcon from '@material-ui/icons/Wc'
import ApartmentIcon from '@material-ui/icons/Apartment'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import {Link} from 'react-router-dom'

import {useApp} from 'app/providers/app.provider'
import {useOnView} from 'app/providers/onview.provider'
import {useOnLogin} from 'app/providers/onlogin.provider'

import 'modules/helpdesk/css/helpdesk.scss'
import {HelpdeskForm} from "modules/helpdesk/components/helpdeskForm.component";
import {HelpdeskCategoryList} from "modules/helpdesk/components/helpdeskCategoryList.component";
import {HelpdeskCategory, HelpdeskSubcategory} from "app/models/helpdesk.models";
import {Grow} from "@material-ui/core";

export const HELPDESK_ICONS = {
    'internet': <WifiIcon className="avatarSizeHelpdesk"/>,
    'heating_air_conditioning': <WbSunnyIcon className="avatarSizeHelpdesk"/>,
    'cleaning': <FaceIcon className="avatarSizeHelpdesk"/>,
    'building': <ApartmentIcon className="avatarSizeHelpdesk"/>,
    'sanitary': <WcIcon className="avatarSizeHelpdesk"/>,
    'supplies': <PrintIcon className="avatarSizeHelpdesk"/>,
    'access_control': <MeetingRoomIcon className="avatarSizeHelpdesk"/>,
    'client_space': <WebIcon className="avatarSizeHelpdesk"/>,
    'default': <DynamicFeedIcon className="avatarSizeHelpdesk"/>,
}

export const HELPDESK_COLORS = {
    'internet': 'primary',
    'heating_air_conditioning': 'primary',
    'cleaning': 'primary',
    'building': 'info',
    'sanitary': 'info',
    'supplies': 'secondary',
    'access_control': 'secondary',
    'client_space': 'secondary',
    'default': 'info',
}

interface MessageProps {
    status: number
}

const Message = ({status}: MessageProps): JSX.Element => {
    const {t} = useTranslation()
    const content = status === 1 ?
        t('common.message_result_success_helpdesk') :
        status === 2 ?
            t('common.message_result_error_helpdesk') : ''
    return (
        <Box p={3} width="100%">
            <span dangerouslySetInnerHTML={{__html: content}}/>
        </Box>
    )
}

const HelpDesk = (): JSX.Element => {
    const {t} = useTranslation()
    const {fromApp} = useApp()
    const {currentEnterprise} = useOnLogin()
    const {clientRequestUseMutation} = useOnView()
    const helpdeskListLink = fromApp ? '/helpdesk_list?fromApp=1' : '/helpdesk_list'
    const [step, setStep] = useState<number>(1)
    const [category, setCategory] = useState<HelpdeskCategory | undefined>(undefined)
    const [status, setStatus] = useState<number>(1)
    const {clientRequestCategoriesUseMutation} = useOnView()
    const [categories, setCategories] = useState<HelpdeskCategory[]>([]);
    const [subcategories, setSubCategories] = useState<HelpdeskSubcategory[]>([]);
    const [selectedSubcategories, setSelectedSubcategories] = useState<HelpdeskSubcategory[]>([]);

    useEffect(() => {
        clientRequestCategoriesUseMutation
            ?.mutateAsync({enterpriseId: currentEnterprise})
            .then((data: any) => {
                setCategories(data.categories);
                setSubCategories(data.subcategories);
            })
    }, []);

    const displayForm = useCallback((category: HelpdeskCategory): void => {
        setCategory(category)
        setSelectedSubcategories(subcategories?.filter(
            (sub) => sub.category === category.label)
        )
        setStep(2)
    }, [subcategories, category]);

    const validateForm = useCallback(
        async (subcategory?: string, center?: string, comment?: string): Promise<void> => {
            try {
                await clientRequestUseMutation?.mutateAsync({
                    enterpriseId: currentEnterprise,
                    subcategory: subcategory,
                    center: center,
                    comment: comment
                })
            } catch (error) {
                setStatus(2)
            }
            setStep(3)
        },
        [currentEnterprise]
    )

    return (
        <Paper elevation={1} className="paperHelpdesk">
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} className="bkColHelpdesk">
                    <Box component="div" px={2} py={4} className="small-y">
                        <Typography variant="h2" gutterBottom={true}>
                            {t('common.helpdesk')}
                        </Typography>
                        {step != 1 && (
                            <Box component="div" className="backCategory" onClick={() => setStep(1)}>
                                <ChevronLeftIcon/>
                            </Box>
                        )}
                        <Divider/>
                        <Box component="p" mt={4} className="pt-0">
                            {t('common.helpdesk_content')}
                        </Box>
                        <Box component="div" mt={4} className="mt-0">
                            <Link to={helpdeskListLink} className="button bg-white">
                                {t('common.helpdesk_my_ask')}
                            </Link>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={9} className="rightColHelpdesk">
                    {step == 3 && (
                        <Box component="div" py={4} className="boxMessageHelpdesk" width="89%">
                            <Message status={status}/>
                        </Box>
                    )}
                    {step == 2 && (<Grow in={step == 2}>
                            <Box component="div" py={4} className="boxFormHelpdesk pt-0" width="89%">
                                <HelpdeskForm validateForm={validateForm} category={category}
                                              subcategories={selectedSubcategories}/>
                            </Box>
                        </Grow>
                    )}
                    {step == 1 && (
                        <Box component="div" py={4} className="boxCategoryHelpdesk">
                            <HelpdeskCategoryList categories={categories} displayForm={displayForm}/>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Paper>
    )
}

export default HelpDesk
