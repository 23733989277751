import React, {useEffect, useState} from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import '../css/CbForm.scss'
import {useTranslation } from 'react-i18next'
import {useApp} from "../../../app/providers/app.provider";
import {useOnLogin} from "../../../app/providers/onlogin.provider";
import {useOnView} from "../../../app/providers/onview.provider";

const PaymentCbForm = () => {
  const { t } = useTranslation()
  const { isConnected } = useApp()
  const { account } = useOnLogin()
  const { createPaymentIntentUseMutation, completePaymentGcUseMutation } = useOnView()
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [email, setEmail] = useState('')
  const stripe = useStripe()
  const elements = useElements()

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    let tempEmail = isConnected && account !== undefined && account.email !== undefined ? account.email : ''
    setEmail(tempEmail)
  }, [])

  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : '')
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    setProcessing(true)

    let params = new URLSearchParams(window.location.search)
    let token = String(params.get('token'))
    let paymentIntent = await createPaymentIntentUseMutation.mutateAsync({ token: token, paymentMethod: 'card', email: email})
    const payload = await stripe.confirmCardPayment(
      paymentIntent.client_secret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      }
    )

    if (payload.error) {
      setError(`${payload.error.message}`)
      setSucceeded(false)
      setProcessing(false)
    } else {
      setError(null)
      setSucceeded(true)
      setProcessing(false)
      await completePaymentGcUseMutation.mutateAsync({token: token, paymentIntent: payload.paymentIntent})
      window.location = "/payment_result?result=success&type=cb"
    }
  }

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <input
        type='email'
        id='customer-email'
        value={email}
        onChange={handleChangeEmail}
        placeholder='email'
      />
      <CardElement
        id='card-element'
        options={cardStyle}
        onChange={handleChange}
      />
      <button
        className='StripeButtons'
        disabled={processing || disabled || succeeded}
        id='submit'
      >
        <span id='button-text'>
          {processing ? (
            <div className='spinner' id='spinner'/>
          ) : (
            <span>{t("common.payments.validate")}</span>
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className='card-error' role='alert'>
          {error}
        </div>
      )}
    </form>
  )
}
export default PaymentCbForm
