export enum RoleEnum {
  ADMIN = 'ROLE_ENTERPRISE_ADMIN',
  MANAGER = 'ROLE_ENTERPRISE_MANAGER',
  USER = 'ROLE_USER',
}

export enum SeverityEnum {
  SUCCESS,
  INFO,
  WARNING,
  ERROR,
}

export enum SectionEnum {
  Login,
  Enterprise,
  Concierge,
  Booking,
  Profile,
  ProfileEdit,
  AccountingDocumentList,
  MemberList,
  DocumentList,
  ServiceList,
  PendingContract,
  Quotation,
  Home
}

export enum CivilityEnum {
  Man = 1,
  Woman,
}

export enum ContractTypeEnum {
  FULL_DESKTOP = 1,
  FULL_COWORKING,
  COWORKING,
  DOMICILIATION,
  CONSUMPTION
}

export enum ContractStateEnum {
  CREATED = 1,
  AGREED,
  PAID,
  TERMINATED,
  CANCELLED,
  DIRECTION_AGREEMENT,
  IN_PROGRESS
}
