import React, {useCallback, useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {List} from '@material-ui/core'
import {useOnLogin} from 'app/providers/onlogin.provider'
import {IContractListItem} from 'app/models/contracts.model'
import {IContractListFilter} from 'app/types/contracts.type'
import ContractListFiltersComponent from 'modules/contracts/components/contractListFilters.component'
import ContractListItemComponent from 'modules/contracts/components/contractListItem.component'
import 'modules/documents/css/documents_list.scss'
import 'css/list.scss'

const ContractsList = (): JSX.Element => {
  const { t } = useTranslation()
  const { contracts } = useOnLogin()

  const [filteredContracts, setFilteredContracts] = useState<IContractListItem[] | undefined>(
    contracts
  )
  const [filters, setFilters] = useState<IContractListFilter>()
  const onLoading = false

  useEffect(() => {
    refreshList()
  }, [filters, contracts])

  const refreshList = useCallback((): void => {
    let _list = contracts
    if (filters?.center !== undefined && filters?.center !== '') {
      _list = _list?.filter((c: IContractListItem) => c.center === filters.center)
    }
    setFilteredContracts(_list)
  }, [filters, contracts])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <Box p={2}>
            <div className="myCard">
              <div className="card-header list-header">
                <Link to="/home" color="primary" className="icon-button">
                  <ArrowBackIcon fontSize="small" />
                </Link>
                <Typography variant="h2" color="primary">
                  {t('common.my_contracts')}
                </Typography>
              </div>
              <ContractListFiltersComponent
                filters={filters}
                onChangeFilters={(_filters: IContractListFilter) => setFilters(_filters)}
              />
              <div className="card-content">
                {filteredContracts !== undefined && filteredContracts.length > 0 && !onLoading && (
                  <List className="myListItem">
                    {filteredContracts.map((c: IContractListItem, index: number) => (
                      <ContractListItemComponent key={index} contract={c} />
                    ))}
                  </List>
                )}
                {(filteredContracts === undefined || filteredContracts.length === 0) && (
                  <Typography variant="body2" color="textSecondary">
                    Aucun document
                  </Typography>
                )}
                {(filteredContracts === undefined || onLoading) && (
                  <div className="myCardLoader">
                    <CircularProgress color="secondary" />
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ContractsList
