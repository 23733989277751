import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

import { ISolution } from 'app/models/quotations.models'

export interface IProps {
  solution: ISolution
}

const QuotationNomadeDetailsComponent = ({ solution }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={1} className="myGridQuotations">
      <Grid item md={12} xs={12}>
        {solution.offices}
      </Grid>
      <Grid item md={12} xs={12} className="bgGrey">
        <Typography variant="caption" component="p" color="textSecondary">
          <small>{t('common.quotationView.nomade.access')}*</small>
        </Typography>
        {t('common.quotationView.nomade.unlimited')}
      </Grid>
      <Grid item md={7} xs={7}>
        <Typography variant="caption" component="p" color="textSecondary">
          <small>{t('common.quotationView.nomade.centers')}</small>
        </Typography>
        {t('common.quotationView.nomade.networkCenters')}
      </Grid>
      <Grid item md={5} xs={5}>
        <Typography variant="caption" component="p" color="textSecondary">
          <small>{t('common.commitment_length')}</small>
        </Typography>
        {solution.commitment}
      </Grid>
    </Grid>
  )
}

export default QuotationNomadeDetailsComponent
